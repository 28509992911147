import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { iBisAuditComponent, iBisAuthService, iBisLoginComponent, iBisUserComponent } from '@dohu/ibis-auth';
import { ConfigurationComponent } from './admin/configuration/configuration.component';
import { EvaluationMatrixComponent } from './admin/evaluation-matrix/evaluation-matrix.component';
import { MaterialComponent } from './admin/material/material.component';
import { PartyComponent } from './admin/party/party.component';
import { SapMapComponent } from './admin/sap-map/sap-map.component';
import { UserComponent } from './admin/user/user.component';
import { AgreementComponent } from './agreement/agreement.component';
import { BugetComponent } from './buget/buget.component';
import { NotifySupplierTemplateComponent } from './admin/notify-supplier-template/notify-supplier-template.component';
import { OrderEditComponent } from './order/order-edit/order-edit.component';
import { OrderComponent } from './order/order.component';
import { ProfileComponent } from './profile/profile.component';
import { QuotationComponent } from './quotation/quotation.component';
import { ReportAuditComponent } from './report/report-audit/report-audit.component';
import { ReportBuyerComponent } from './report/report-buyer/report-buyer.component';
import { ReportContractComponent } from './report/report-contract/report-contract.component';
import { ReportOrderComponent } from './report/report-order/report-order.component';
import { RfqEditComponent } from './rfq/rfq-edit/rfq-edit.component';
import { NavRfqItemListComponent } from './rfq/rfq-item-list/nav-rfq-item-list/nav-rfq-item-list.component';
import { RfqItemListComponent } from './rfq/rfq-item-list/rfq-item-list.component';
import { NavRfqListComponent } from './rfq/rfq-list/nav-rfq-list/nav-rfq-list.component';
import { RfqListComponent } from './rfq/rfq-list/rfq-list.component';
import { TechnicalApproveComponent } from './technical/technical-approve/technical-approve.component';
import { TechnicalListComponent } from './technical/technical-list/technical-list.component';
import { TechnicalClarificationComponent } from './technical/technical-clarification/technical-clarification.component';

const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'quotation/:id', component: QuotationComponent },
  {
    path: 'rfq-item', canActivate: [iBisAuthService], children: [
      { path: '', component: RfqItemListComponent, outlet: 'primary' },
      { path: '', component: NavRfqItemListComponent, outlet: 'undernav' }
    ]
  },
  { path: 'rfq-edit/:id', component: RfqEditComponent, canActivate: [iBisAuthService] },
  { path: 'technical-clarification', component: TechnicalClarificationComponent, canActivate: [iBisAuthService] },
  { path: 'technical-new', component: TechnicalListComponent, canActivate: [iBisAuthService] },
  { path: 'technical-history', component: TechnicalListComponent, canActivate: [iBisAuthService] },
  { path: 'technical-approve/:id', component: TechnicalApproveComponent, canActivate: [iBisAuthService] },
  {
    path: 'rfquotation', canActivate: [iBisAuthService], children: [
      { path: '', component: RfqListComponent, outlet: 'primary' },
      { path: '', component: NavRfqListComponent, outlet: 'undernav' }]
  },
  { path: 'order', component: OrderComponent, canActivate: [iBisAuthService] },
  { path: 'order/:id', component: OrderEditComponent, canActivate: [iBisAuthService] },
  { path: 'agreement', component: AgreementComponent, canActivate: [iBisAuthService] },
  { path: 'buget', component: BugetComponent, canActivate: [iBisAuthService] },
  {
    path: 'report', canActivate: [iBisAuthService],
    children: [
      { path: 'report-contract', component: ReportContractComponent },
      { path: 'report-order', component: ReportOrderComponent },
      { path: 'report-audit', component: ReportAuditComponent },
      { path: 'report-buyer', component: ReportBuyerComponent }
    ]
  },
  {
    path: 'admin', canActivate: [iBisAuthService],
    children: [
      { path: 'sap-map', component: SapMapComponent },
      { path: 'party', component: PartyComponent },
      { path: 'material', component: MaterialComponent },
      { path: 'evaluation-matrix', component: EvaluationMatrixComponent },
      { path: 'config', component: ConfigurationComponent },
      { path: 'notify-supplier-template', component: NotifySupplierTemplateComponent },
      { path: 'user', component: UserComponent },
      { path: 'audit', component: iBisAuditComponent, data: { height: "calc(100vh - 140px)" } }
    ]
  },
  { path: 'profile', component: ProfileComponent, canActivate: [iBisAuthService] },
  { path: 'login', component: iBisLoginComponent, data: [{ returnUrl: '/rfq-item' }] },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
