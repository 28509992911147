<div class="rfq-container">
    <div class="logo-box">
        <img class="logo" src="assets/img/logo.png">
    </div>
    <div class="data-box">
        <div class="loading-message" *ngIf="!qs.rfqModel.refNumber">
            <p>{{L('LOADING_DATA')}}</p>
        </div>
        <div class="paragraph-box" *ngIf="qs.rfqModel.refNumber">
            <h2>{{L('REQUEST_FOR_OFFER')}}: {{qs.rfqModel.refNumber}}</h2>
            <div class="txt-center" *ngIf="qs.rfqModel.statusId < 5 || qs.rfqModel.statusId >= 20 || 
                expiredDueDate(qs.rfqModel); else openedBlocks">
                <p *ngIf="qs.rfqModel.statusId >= 20 && qs.hasWinnerQuotation; else closeBlock">
                    {{L('RFQ_WINNER_MESSAGE')}}
                </p>
                <ng-template #closeBlock>
                    <p *ngIf="qs.rfqModel.statusId >= 20">{{L('RFO_STATUS_MESSAGE_CLOSED')}}</p>
                    <p *ngIf="qs.rfqModel.statusId < 0">{{L('RFQ_MESSAGE_INVALID')}}</p>
                    <p *ngIf="expiredDueDate(qs.rfqModel)">{{L('RFQ_EXPIRED')}}</p>
                </ng-template>
            </div>
            <ng-template #openedBlocks>
                <p *ngIf=" qs.rfqModel.statusId == 5">
                    <span *ngIf="qs.rfqModel.flowType == 1">{{L('RFO_STATUS_MESSAGE_OPENED')}}</span>
                    <span *ngIf="qs.rfqModel.flowType == 2">{{L('RFO_STATUS_MESSAGE_OPENED_TECHNIC')}}</span>
                </p>
                <p *ngIf=" qs.rfqModel.statusId == 12 && qs.rfqModel.flowType == 2">
                    <span *ngIf="qs.partyRfq.techStatus == 0">{{L('RFO_STATUS_MESSAGE_TECHNICAL_REJECTION')}}</span>
                    <span *ngIf="qs.partyRfq.techStatus != 0">{{L('RFO_STATUS_MESSAGE_OPENED_QUOTATIONS')}}</span>
                </p>
                <p *ngIf="qs.rfqModel.statusId == 10 && qs.partyRfq.techStatus == 2">
                    <span>{{L('RFQ_NEED_CLARIFICATION')}}</span>
                </p>
                <p *ngIf="[10, 15, 18].includes(qs.rfqModel.statusId) && qs.partyRfq.techStatus != 2">
                    <span *ngIf="qs.partyRfq.discountRequested">{{L('RFO_STATUS_MESSAGE_DISCOUNT')}}</span>
                    <span *ngIf="!qs.partyRfq.discountRequested">{{L('RFO_STATUS_MESSAGE_ANALYZE')}}</span>
                </p>
            </ng-template>
        </div>
        <div class="button-box" *ngIf="qs.partyRfq && qs.rfqModel">
            <dx-button class="details-button"
                [visible]="(qs.rfqModel.statusId == 5 || (qs.rfqModel.statusId == 12 && qs.partyRfq.techStatus != 0)) && !expiredDueDate(qs.rfqModel)"
                [text]="qs.partyRfq.register ? L('CONTINUE_OFFERS') : L('START_OFFERS')" (onClick)='openDetails()'>
            </dx-button>
            <dx-button class="details-button"
                [visible]="(qs.partyRfq.discountRequested || qs.partyRfq.techStatus == 2) && [10, 15, 18].includes(qs.rfqModel.statusId)"
                [text]="L('UPDATE_OFFER')" (onClick)='openDetails()'>
            </dx-button>
            <dx-button class="details-button" [visible]="qs.hasWinnerQuotation" [text]="L('VIEW_OFFER')"
                (onClick)='openDetails()'>
            </dx-button>

            <dx-button type='normal' [text]="L('DISCOUNT_DENY')"
                [visible]="qs.partyRfq.discountRequested && qs.partyRfq.discountGranted != false && [5, 10, 15, 18].includes(qs.rfqModel.statusId)"
                (onClick)="discountDeniedEv($event)"></dx-button>
        </div>
    </div>
</div>