<dx-data-grid #gridItemList [dataSource]="red.rfqItems" keyExpr="ri_id" [height]="red.gridHeight"
    (onToolbarPreparing)="toolbarPreparing($event, false)" [remoteOperations]='true'
    (onRowPrepared)="onRFQItemPrepared($event)" (onExporting)="onExporting($event)">
    <dxo-master-detail [enabled]="red.model.typeId != 1" template="details"></dxo-master-detail>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <!-- <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="red.model.statusId < 15"></dxo-editing> -->

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right" width="120">
        <dxi-button icon="plus" hint="Adaugă cotație" [onClick]="onAddQuotationClick"
            [visible]="addQuoteVisible"></dxi-button>
        <dxi-button icon="edit" hint="Editează material" [onClick]="onEditMaterialClick" [visible]="red.model.statusId <= 5"></dxi-button>
        <dxi-button icon="trash" hint="Sterge rând" [onClick]="onDeleteClick" [visible]="deleteVisible"></dxi-button>
        <dxi-button icon="fa-solid fa-clock-rotate-left" hint="Istoric preturi"
            [onClick]="onPriceHistoryClick"></dxi-button>
        <dxi-button icon="fa-solid fa-file-invoice" hint="Comanda aprovizionare" [onClick]="openOrder"
            [visible]="onOrderVisible"></dxi-button>
        <dxi-button icon="fa-solid fa-hammer" hint="Comisie tehnica" [onClick]="openTechnicalSelection"
            [visible]="red.model.statusId < 15"></dxi-button>
    </dxi-column>

    <dxi-column [caption]='L("MATERIAL")' [isBand]="true" calculateSortValue='ri_compusNo'>
        <!-- <dxi-column [caption]='L("HAS_WINNER")' dataField='hasWinner' dataType='boolean' [width]="80"
        [visible]='red.model.statusId >= 18'></dxi-column> 
        <dxi-column [caption]="L('CURRENT_NUMBER')" [width]="100" cellTemplate="currentNumberTemplate">
            <div *dxTemplate="let row of 'currentNumberTemplate'" class="no-margin">
                {{row.rowIndex + 1}}
            </div>
        </dxi-column>-->
        <dxi-column [caption]='L("RN_NO")' cellTemplate="rnNoTemplate">
            <div *dxTemplate="let row of 'rnNoTemplate'" class="no-margin">
                <p>{{L("RN_NO")}}: <b>{{row.data.ri_compusNo}}</b></p>
                <p>{{L("INTERNAL_REF_NUMBER")}}: <b>{{row.data.oh_refNumber}}</b></p>
                <p>{{L("SAP_REF_NUMBER")}}: <b>{{row.data.oh_sapRefNumber}}</b></p>
            </div>
        </dxi-column>
        <dxi-column [caption]='L("NAME")' dataField="fullName" [allowEditing]='false' [encodeHtml]='false'
            [allowHeaderFiltering]="false" [allowSorting]="false" [allowSearch]="false"
            [calculateFilterExpression]='materialFilter'></dxi-column>
        <!-- <dxi-column [caption]='L("NAME")' cellTemplate="materialNameTemplate" [allowHeaderFiltering]="false">
            <div *dxTemplate="let row of 'materialNameTemplate'" class="no-margin">
                <h3>{{row.data.m_name}}</h3>
                <p>{{L("CODE")}}: <b>{{ds.stringZero({value: row.data.m_code} )}}</b></p>
            </div>
        </dxi-column> -->

        <dxi-column [caption]='L("CODE")' dataField="m_code" dataType="string" [visible]="false"></dxi-column>
        <dxi-column [caption]='L("TYPE")' cellTemplate="priceTemplate">
            <div *dxTemplate="let row of 'priceTemplate'" class="no-margin">
                <p>{{L("UOM")}}: <b>{{row.data.uom_name}}</b></p>
                <p>{{L("REQUESTED_QUANTITY")}}: <b>{{row.data.ri_quantity}}</b></p>
                <p>{{L("REFERENCE_PRICE")}}: <b>{{row.data.ri_price}}</b></p>
                <p>{{L("CURRENCY")}}: <b>{{getValue(row.data.ri_currencyId, rqs.currency, 'id', 'name') }}</b></p>
            </div>
        </dxi-column>
        <dxi-column [caption]='L("UOM")' dataField="uom_name" dataType="string" [visible]="false"></dxi-column>
        <dxi-column [caption]='L("REQUESTED_QUANTITY")' dataField="ri_quantity" dataType="number" [visible]="false">
        </dxi-column>
        <dxi-column [caption]='L("REFERENCE_PRICE")' dataField="ri_price" dataType="number" [visible]="false">
        </dxi-column>
        <dxi-column [caption]='L("CURRENCY")' dataField="ri_currencyId" dataType="string" [visible]="false"
            [allowSearch]="false"></dxi-column>


    </dxi-column>
    <dxi-column [caption]='L("LOGISTICS")' [isBand]="true">
        <dxi-column [caption]="L('DELIVERY')" cellTemplate="logisticTemplate"
            [calculateFilterExpression]='logisticFilter'>
            <div *dxTemplate="let row of 'logisticTemplate'" class="no-margin">
                <p>{{L("DELIVERY_DATE")}}: <b>{{row.data.ri_deliveryDate | date:'dd.MM.yyyy' }}</b></p>
                <!-- <p>{{L("LOGISTIC_UNIT")}}: <b>{{row.data.ri_logisticUnit}}</p></b> -->

            </div>
        </dxi-column>
        <dxi-column [caption]='L("DELIVERY_DATE")' dataField="ri_deliveryDate" dataType="date" [visible]="false">
        </dxi-column>


        <dxi-column [caption]='L("REQUESTER")' cellTemplate="requesterTemplate">
            <div *dxTemplate="let row of 'requesterTemplate'" class="no-margin">
                <p>{{L("REQUESTER")}}: <b>{{row.data.ri_requesterName}}</b></p>
                <p>{{L("REQUEST_REASON")}}: <b>{{row.data.ri_reason}}</b></p>
                <p>{{L("DOC_TYPE")}}: <b>{{row.data.doc_description}}</b></p>
            </div>
        </dxi-column>

        <dxi-column [caption]='L("REQUEST_REASON")' dataField="ri_reason" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column [caption]='L("DOC_TYPE")' dataField="doc_description" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column [caption]='L("SUPPLIER")' cellTemplate="supplierTemplate">
            <div *dxTemplate="let row of 'supplierTemplate'" class="no-margin">
                <p>{{L("PARTY_WANTED")}}: <b>{{row.data.pw_name}}</b></p>
                <p>{{L("PARTY_FIXED")}}: <b>{{row.data.pf_name}}</b></p>
                <p>{{L("CONTRACT_NO")}}: <b>{{row.data.ri_contractNo}}</b></p>
                <p>{{L("CONTRACT_POSITION_NO")}}: <b>{{row.data.ri_contractPosNo}}</b></p>
            </div>
        </dxi-column>

        <dxi-column [caption]='L("PARTY_WANTED")' dataField="pw_name" dataType="string" [visible]="false"></dxi-column>
        <dxi-column [caption]='L("PARTY_FIXED")' dataField="pf_name" dataType="string" [visible]="false"></dxi-column>
        <dxi-column [caption]='L("CONTRACT_NO")' dataField="ri_contractNo" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column [caption]='L("CONTRACT_POSITION_NO")' dataField="ri_contractPosNo" dataType="number"
            [visible]="false"></dxi-column>

    </dxi-column>
    <dxi-column [caption]='L("BENEFICIARY")' [isBand]="true">
        <dxi-column [caption]="L('SAP')" cellTemplate="sapRefTemplate">
            <div *dxTemplate="let row of 'sapRefTemplate'" class="no-margin">
                <p>{{L("DEPARTMENT")}}: <b>{{row.data.dep_description}}</b></p>
                <p>{{L("CREATED")}}: <b>{{row.data.ri_created | date:'dd.MM.yyyy'}}</b></p>
            </div>
        </dxi-column>
        <dxi-column [caption]='L("DEPARTMENT")' dataField="dep_description" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column [caption]='L("CREATED")' dataField="ri_created" dataType="date" [visible]="false"></dxi-column>
        <dxi-column [caption]="L('TECHNICAL')" dataField="techuser" dataType="string" [width]="200"
            [allowSearch]="false"></dxi-column>
    </dxi-column>

    <div *dxTemplate='let data of "details"'>
        <dx-tab-panel>
            <dxi-item [title]="L('SUPPLIERS')" [visible]="red.model.typeId != 1">
                <dx-data-grid (onContentReady)='onSupplierIncludeInitialized($event, data.data)' keyExpr="rp_id"
                    [height]="ui.detailsfullHeight" (onCellPrepared)="onSupplierPrepared($event)"
                    [columnAutoWidth]="true" [remoteOperations]="true">

                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>

                    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar"
                        [useNative]="ui.useNative" [renderAsync]='ui.scrollingRenderAsync'
                        [rowRenderingMode]='ui.scrollRenderingMode'>
                    </dxo-scrolling>
                    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage"
                        storageKey="supplierExcludeGrid">
                    </dxo-state-storing>

                    <dxi-column [caption]='L("CODE")' dataField='p_code' dataType='string'></dxi-column>
                    <dxi-column [caption]='L("NAME")' dataField='p_name' dataType='string'></dxi-column>
                    <dxi-column [caption]='L("CATEGORY")' dataField='pg_name' dataType="string"></dxi-column>
                    <dxi-column [caption]='L("CIF")' dataField='p_cif' dataType='string'></dxi-column>
                    <dxi-column [caption]='L("EMAIL")' dataField='p_emailAddress' dataType='string'></dxi-column>

                    <dxi-column type="buttons" [fixed]="true" fixedPosition="right" width="50">
                        <dxi-button icon="remove" [onClick]="onExcludeSupplier" [visible]="visibleExclude"></dxi-button>
                        <dxi-button icon="return" [onClick]="onIncludeSupplier" [visible]="visibleInclude"></dxi-button>
                    </dxi-column>
                </dx-data-grid>
            </dxi-item>
            <!-- <dxi-item [title]="L('COMMITTEE')">
                <dx-data-grid [dataSource]="red.committee" [height]="300" keyExpr="u_id">
                    <dxi-column [caption]='L("FULLNAME")' dataField='u_fullName' dataType='string'></dxi-column>
                </dx-data-grid>
            </dxi-item> -->
        </dx-tab-panel>
    </div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false" [height]="'90vh'">
    <app-rfq-item-list [add]="true" [fullHeight]="'calc(90vh - 122px)'"></app-rfq-item-list>
</ibis-edit-popup>

<ibis-edit-popup [edit]="mq" [showAdd]="false">
    <app-rfq-edit-add-manual-quotation [rfqItems]="red.rfqItemSum"
        [currency]="rqs.currency"></app-rfq-edit-add-manual-quotation>
</ibis-edit-popup>

<ibis-edit-popup [edit]="material" [showAdd]="false">
    <app-material-edit></app-material-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="rph" [showAdd]="false">
    <app-rfq-edit-item-list-price-history></app-rfq-edit-item-list-price-history>
</ibis-edit-popup>

<ibis-edit-popup [edit]="tus" [showAdd]="false">
    <app-rfq-edit-item-list-technical-selection></app-rfq-edit-item-list-technical-selection>
</ibis-edit-popup>

<ibis-edit-popup [edit]="order" [showAdd]="false" [width]="'90vw'" [height]="'90vh'">
    <app-order-edit></app-order-edit>
</ibis-edit-popup>