import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisEditUserService } from '@dohu/ibis-auth';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';

@Component({
  templateUrl: 'user.component.html'
})
export class UserComponent extends iBisBaseComponent {

  constructor(public override edit: iBisEditUserService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,) {
    super(edit, lg, iui, route);
  }
}
