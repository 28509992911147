import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisFileService, iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { QuotationItemEditService } from 'src/app/common/quotation-item-edit/quotation-item-edit.service';
import { AddClarificationService } from './add-clarification/add-clarification.service';
import { QuotationService } from '../quotation.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clarification-list',
  templateUrl: './clarification-list.component.html',
  styleUrls: ['./clarification-list.component.scss']
})
export class ClarificationListComponent extends iBisViewComponent {

  apiUrl: string;

  clarification: any[] = [];
  clarificationResponseFiles: any;
  clarificationFiles: any;
  @ViewChild('clarificationGrid') override grid!: DxDataGridComponent;

  constructor(public override edit: AddClarificationService, lg: iBisLanguageService,
    iui: iBisUIService, route: ActivatedRoute, private entity: iBisEntityService,
    public qs: QuotationService, private file: iBisFileService) {
    super(edit, lg, iui, route);
    this.apiUrl = environment.defaultUrlServer + 'Download/';

    this.initClarifications();
  }

  initClarifications() {
    this.entity.execute('GetClarificationParty', { rfqp: this.qs.uniqueId }).then(resp => {
      this.clarification = resp[0];
      this.clarificationFiles = resp[1];
      this.clarificationResponseFiles = resp[2];

    })
  }

  override toolbarPreparing(ev: any, add?: boolean | undefined): void {
    super.toolbarPreparing(ev, add);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: this.L('CLARIFICATION_REQUEST'),
        onClick: (eb: any) => {
          this.edit.showPopup({ rfqId: this.qs.rfqModel.id, partyId: this.qs.party.id }).then((resp) => {
            if (resp) {
              notify(this.L('ADD_SUCCESSFULL'), 'success', 3000);
              this.initClarifications();
            }
          })
        }
      },
      location: 'after'
    });
  }

  getFiles(files: any[], id: string) {
    return files.filter((x: any) => x.entityId == id);
  }

  openFile(fileId: string, fileName: string) {
    var isView = fileName.includes('png') || fileName.includes('pdf') || fileName.includes('jpg');
    this.file.downloadByUrl(this.apiUrl + fileId, isView).then(
      () => { notify(this.L('FILES_DOWNLOADED'), 'success', 2000); },
      () => { notify(this.L('DOWNLOAD_ERROR'), 'error', 3000); });
    return false;
  }


}
