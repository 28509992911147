import { Injectable } from "@angular/core";
import { iBisAuthService, iBisSecureEditService } from "@dohu/ibis-auth";
import { iBisLanguageService, iBisServerConfig } from "@dohu/ibis-common";
import { EntityQuery, iBisEntityService } from "@dohu/ibis-entity";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { DataService } from "../services";


@Injectable()

export class RfqService extends iBisSecureEditService {

    currency: any[] = [];
    buyer: any[] = [];
    approver: any[] = [];
    supplier!: DataSource;
    current: number = 1;
    currentItem: number = 0;
    navTabs: { text: string }[] = [];
    data!: DataSource;
    rfqItem!: DataSource;
    material: any;
    evMatrix!: any;
    priority!: DataSource;
    technical!: CustomStore;
    paymentTerm!: DataSource;
    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig,
        lg: iBisLanguageService, public ds: DataService) {
        super(auth, entity, config, lg);
    }
    protected load(): void {
        this.evMatrix = this.entity.store(new EntityQuery('EvaluationMatrix'), false, 'id');
        this.material = new DataSource(this.entity.store(new EntityQuery('Material').eq('isActiv', true).addOrderBy(['name']), false, 'id'));
        this.supplier = new DataSource(this.entity.store(new EntityQuery('Party').eq('isActiv', true).addOrderBy(['name']), false, 'id'));
        this.priority = new DataSource(this.entity.store(new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'code', 'PRIORITY').addOrderBy(['orderIndex']), false, 'id'));
        this.paymentTerm = new DataSource(this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'PAYMENT_TERM').addOrderBy(['orderIndex']), false, 'name'));
        this.entity.load(new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).like('roles', '%approver%').addOrderBy(['u.fullName']), this.config.saasServer).then(data => {
            this.approver = data;
        });
        this.entity.load(new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).like('roles', '%buyer%').addOrderBy(['u.fullName']), this.config.saasServer).then(data => {
            this.buyer = data;
        });
        this.entity.load(new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'name', 'CURRENCY').addOrderBy(['orderIndex']), this.config.saasServer).then(data => {
            this.currency = data;
        })
        this.loadData(true);
        this.loadData(false);
    }

    public get indexList(): number {
        return this.current;
    }

    public get indexItem(): number {
        return this.currentItem;
    }

    public set indexList(no: number) {
        this.current = no;
        this.loadData(false);
    }
    public set indexItem(no: number) {
        this.currentItem = no;
        this.loadData(true);
    }

    private loadData(isItem: boolean) {
        if (!isItem) {
            let statusId = 0;
            switch (this.current) {
                case 0: statusId = 0; break;
                case 1: statusId = 5; break;
                case 2: statusId = 10; break;
                case 3: statusId = 15; break;
                case 4: statusId = 18; break;
                case 5: statusId = 20; break;
                case 6: statusId = -1; break;
            }
            const q = new EntityQuery('RFQView').addOrderByDesc(['r.created']);
            if (this.current == 1) {
                q.in('r.statusId', [5, 12]);
            } else if (this.current == 5) {
                q.in('r.statusId', [20, 25]);
            } else {
                q.eq('r.statusId', statusId);
            }
            this.data = new DataSource(this.entity.store(q, false, 'r_id', undefined, (item) => {
                if (item.r_dueDate) {
                    var dd = (new Date(item.r_dueDate).getTime() - new Date().getTime()) / (1000 * 60 * 60);
                    item.publishMinute = item.r_dueDate && dd > 0 ? dd : 0;
                    item.overdueTime = item.r_dueDate && dd < 0 ? (dd * -1) : 0;
                }
            }));
        } else {
            var status = this.currentItem == 4 ? -1 : this.currentItem; 
            const rfq = new EntityQuery('RFQItemView').eq('ri.statusId', status || 0).addOrderByDesc(['ri.compusNo']);
            this.rfqItem = new DataSource(this.entity.store(rfq, false, 'ri_id'));       
        }
    }

    reloadRfq(rfqId: string) {
        return this.entity.save('RFQ', rfqId, { statusId: 0 });
    }

    createDefault() {
        return {};
    }
    getById(id: string, serverUrl?: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    onRemove(id: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    onSaveEv(serverUrl?: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    reset(): void {
        this.model = this.createDefault();
    }

}