import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import { RfqService } from '../rfq.service';
import { SupplierSelectService } from '../supplier-select/supplier-select.service';
import { RfqNewService } from './rfq-new.service';
import { DxAccordionComponent } from 'devextreme-angular';

@Component({
  selector: 'app-rfq-new',
  templateUrl: './rfq-new.component.html',
  styles: ['::ng-deep .dx-accordion .dx-accordion-item:first-child .dx-accordion-item-body {padding:10px 0}']
})
export class RfqNewComponent extends iBisBaseComponent {

  currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
  constructor(public override edit: RfqNewService, lg: iBisLanguageService, iui: iBisUIService,
    public ds: DataService, public rfq: RfqService, private ss: SupplierSelectService) {
    super(<any>null, lg, iui, <any>null);
  }

  onInitAccordion(ev: any) {
    this.edit.accordionInst = ev.component;
  }

  onTypeChange = (ev: any) => {
    this.edit.model.flowType = ev.value == 1 ? 2 : 1;
    this.ss.selectedSupplierKeys = [];
  }

}
