import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportContractComponent } from './report-contract/report-contract.component';
import { ReportOrderComponent } from './report-order/report-order.component';
import { ReportAuditComponent } from './report-audit/report-audit.component';
import { ReportBuyerComponent } from './report-buyer/report-buyer.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { ReportContractEditService } from './report-contract/report-contract-edit.service';



@NgModule({
  declarations: [
    ReportContractComponent,
    ReportOrderComponent,
    ReportAuditComponent,
    ReportBuyerComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule
  ],
  providers: [
    ReportContractEditService
  ]
})
export class ReportModule { }
