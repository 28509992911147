import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { InterestConflictService } from './interest-conflict.service';

@Component({
  selector: 'app-interest-conflict',
  templateUrl: './interest-conflict.component.html'
})
export class InterestConflictComponent extends iBisBaseComponent implements OnInit {

  constructor(public ic: InterestConflictService, lg: iBisLanguageService,
    iui: iBisUIService, activeRoute: ActivatedRoute) {
    super((<any>null), lg, iui, activeRoute);
  }

  ngOnInit(): void {
  }

}
