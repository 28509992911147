import { Component } from '@angular/core';

import TextBox from 'devextreme/ui/text_box';
import NumberBox from 'devextreme/ui/number_box';
import TagBox from 'devextreme/ui/tag_box';
import DateBox from 'devextreme/ui/date_box';
import SelectBox from 'devextreme/ui/select_box';
import TextArea from 'devextreme/ui/text_area';
import Form from 'devextreme/ui/form';
import LoadPanel from 'devextreme/ui/load_panel';
import DataGrid from 'devextreme/ui/data_grid';
import TreeView from 'devextreme/ui/tree_view';
import DropDownBox from 'devextreme/ui/drop_down_box';
import { DataService, ProxyService } from './services/index';
import { iBisAuthService } from '@dohu/ibis-auth';
import { NotificationsService } from './common/notifications/notifications.service';

declare let require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: ['.actions-bar {background-color: #f7f7f7; }']
})
export class AppComponent {
  version: any;
  public menuOpened = false;

  constructor(public proxy: ProxyService, public auth: iBisAuthService, public ds: DataService, public ns: NotificationsService) {
    this.version = require('../git-version.json');

    TextBox.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    });
    TagBox.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    });
    DateBox.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    });
    SelectBox.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    });
    TextArea.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    });
    TreeView.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    })
    NumberBox.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    });
    DropDownBox.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { stylingMode: 'underlined' }
    })
    Form.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { labelMode: 'static' }
    });
    LoadPanel.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { indicatorSrc: 'assets/img/logo.png' }
    });
    DataGrid.defaultOptions({
      device: { deviceType: 'desktop' },
      options: { loadPanel: { indicatorSrc: 'assets/img/logo.png' } }
    });
  }

  deleteNewNotifications() {
    var notifyIds: string[] = [];
    this.proxy.notify.dataNew.load().then((resp: any) => {
      resp.forEach((el: any) => {
        if (el.na_notifyId) {
          notifyIds.push(el.na_notifyId);
        }
      })
      if (notifyIds.length) {
        this.ns.seeAllNotifications(notifyIds, this.auth.user.id).then((resp) => {
          this.proxy.notify.refreshNotify();
        });
      }
    })
  }

  onLoginFinished(): void {
    this.auth.user.isAuth = true;
    this.ds.isLoginModalVisible = false;
  }

  seeAllNotifications() {
    this.ns.showPopup({});
  }
}
