<dx-data-grid #gridTechReq class="grid-container box-shadow" [dataSource]='tech.data' keyExpr="id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true' [filterSyncEnabled]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column [caption]='L("NO")' cellTemplate="refTemplate">
        <div *dxTemplate="let row of 'refTemplate'">
            <a class="nr-col" class="blue-txt" href="#/technical-approve/{{row.data.id}}"
                title="Deschide oferta">{{row.data.refNumber}}</a>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("TITLE")' dataField='title' dataType='string' [width]="200"></dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField='typeId' dataType='number'>
        <dxo-lookup [dataSource]="ds.static.rfqType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("FLOW_TYPE")' dataField='flowType' dataType='number'>
        <dxo-lookup [dataSource]="ds.static.flowType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLY_GROUP")' dataField='userId' dataType='string'>
        <dxo-lookup [dataSource]="proxy.rfq.buyer" valueExpr='u_id' displayExpr='u_fullName'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("CREATED")' dataField='created' dataType='date'></dxi-column>
    <dxi-column [caption]='L("PUBLISH_DATE")' dataField='publishedDate' dataType='datetime'></dxi-column>
    <dxi-column [caption]='L("RESPONSE_DATE")' dataField='dueDate' dataType='datetime'></dxi-column>
    <!-- <dxi-column [caption]='L("NOTES")' dataField='internalNotes' dataType='string'></dxi-column> -->
    <dxi-column [caption]='L("NOTES")' dataField='partyNotes' dataType='string'></dxi-column>

    <dxi-column type='buttons' [fixed]="true" fixedPosition="right">
        <dxi-button [hint]='L("OPEN_REQUEST")' icon='fas fa-arrow-right' [onClick]='onOpenRfq'></dxi-button>
    </dxi-column>
</dx-data-grid>