import { Component, Input, ViewChild } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { OrderEditService } from 'src/app/order/order-edit/order-edit.service';
import { DataService } from '../../services';
import { RfqNewService } from '../rfq-new/rfq-new.service';
import { RfqService } from '../rfq.service';
import { RfqItemListService } from './rfq-item-list.service';

@Component({
  selector: 'app-rfq-item-list',
  templateUrl: './rfq-item-list.component.html',
  styleUrls: ['./rfq-item-list.component.scss'],
})
export class RfqItemListComponent extends iBisViewComponent {
  private lastOrder: number = 0;
  private alternate: boolean = false;
  dsFilter: string[] = [];

  @Input() add = false;
  @Input() fullHeight = this.ui.fullHeight;
  @ViewChild('rfqItemGrid') override grid!: DxDataGridComponent;
  constructor(public override edit: RfqItemListService, public editOrder: OrderEditService, public rfq: RfqService, public rfqNew: RfqNewService, public auth: iBisAuthService,
    lg: iBisLanguageService, iui: iBisUIService, public ds: DataService) {
    super(<any>null, lg, iui, <any>null);
    this.key = 'rfqItemGrid';

    if (this.auth.hasRole('buyer')) {
      this.dsFilter = ['ri_userId', '=', this.auth.user.id];
      this.processState = (state) => { state.filterValue = this.dsFilter; return state; };
    }
  }

  onGridInit(ev: any) {
    this.edit.gridInst = ev.component;
    ev.component.clearSelection();
  }

  orderClicked(item: any) {
    this.editOrder.showPopup(item.data.oh_id, true);
    return false;
  }

  itemRowPrep(rowInfo: any) {
    if (!this.lastOrder && rowInfo.data && rowInfo.rowType !== 'header') {
      this.lastOrder = rowInfo.data.ri_rnNo;
      this.alternate = false
    }

    if (rowInfo.rowType !== 'header' && rowInfo.data && this.lastOrder != rowInfo.data.ri_rnNo) {
      this.lastOrder = rowInfo.data.ri_rnNo;
      this.alternate = !this.alternate;
    }
    rowInfo.rowElement.className = rowInfo.rowElement.className.replace('dx-row-alt', '');
    const cls = this.alternate ? 'gray-bg' : 'white-bg';
    if (rowInfo.rowType !== 'header' && rowInfo.data && rowInfo.data.ri_rnNo == this.lastOrder) {
      if (rowInfo.rowElement.className.indexOf(cls) < 0) {
        rowInfo.rowElement.className += ' ' + cls;
      }
    }
  }

  onToolbarPreparing(ev: any) {
    super.toolbarPreparing(ev, false);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'before',
      options: {
        icon: 'key',
        text: this.L('CLOSE'),
        visible: this.auth.hasRole('admin'),
        onClick: (e: any) => {
          var selectedKeys = this.grid?.instance.getSelectedRowKeys();
          if (selectedKeys.length == 0) {
            notify(this.L("SELECT_RFQ_ITEM_REQUIRED"), 'error', 3000);
            return;
          }
          confirm(this.L("ARE_YOU_SURE"), this.L("ALERT")).then(val => {
            if (val) {
              this.edit.sendTo(selectedKeys, 3).then(() => {
                this.grid.instance.refresh();
              });
            }
          })
        }
      }
    });
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'before',
      options: {
        icon: 'trash',
        text: this.L('CANCEL'),
        visible: this.auth.hasRole('admin'),
        onClick: (e: any) => {
          var selectedKeys = this.grid?.instance.getSelectedRowKeys();
          if (selectedKeys.length == 0) {
            notify(this.L("SELECT_RFQ_ITEM_REQUIRED"), 'error', 3000);
            return;
          }
          confirm(this.L("ARE_YOU_SURE"), this.L("ALERT")).then(val => {
            if (val) {
              this.edit.sendTo(selectedKeys, -1).then(() => {
                this.grid.instance.refresh();
              });
            }
          })
        }
      }
    });
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: this.L('CREATE_REQUEST'),
        visible: !this.add,
        onClick: (e: any) => {
          var srd = this.grid?.instance.getSelectedRowsData();
          if (!srd || srd.length == 0) {
            notify(this.L("SELECT_RFQ_ITEM_REQUIRED"), 'error', 3000);
            return;
          }
          var rnNoArr = srd?.map(x => parseInt(x.ri_rnNo).toString()).filter((item, pos, self) => { return self.indexOf(item) == pos; });
          this.rfqNew.showPopup({
            refNumber: rnNoArr?.join('_'),
            priority: 2,
            flowType: 1,
            automated: true,
            byMaterial: false,
            items: srd.map(x => x.ri_id),
            selectedArray: srd,
            party: []
          }, true).then(() => {
            this.grid?.instance.clearSelection();
            ev.component.refresh();
          })
        }
      },
      location: 'after'
    });
  }

}
