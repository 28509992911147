<dx-data-grid [dataSource]='emails' [height]='red.gridHeight' width='100%' [remoteOperations]='true'
(onToolbarPreparing)='toolbarPreparing($event, false)'>

    <dxo-export [enabled]='true' fileName='audit'></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-sorting mode='multiple'></dxo-sorting>

    
    <dxi-column [caption]="L('TO')" dataField='to' dataType='string' [width]="200"></dxi-column>
    <dxi-column [caption]="L('SUBJECT')" dataField='subject' dataType='string' [width]="250"></dxi-column>
    <dxi-column [caption]="L('MESSAGE')" dataField='body' [encodeHtml]="true" [width]="300" dataType='string'></dxi-column>
    <dxi-column [caption]="L('CREATED')" dataField='created' dataType='datetime' [width]="150"></dxi-column>
    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button [onClick]="openEmailDetails" icon="info"></dxi-button>
    </dxi-column>
    
</dx-data-grid>
<ibis-edit-popup [edit]="edit" [showAdd]="false">
   <app-email-details></app-email-details> 
</ibis-edit-popup>
