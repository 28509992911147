import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqEditStatusService } from './rfq-edit-status.service';

@Component({
  selector: 'app-rfq-edit-status',
  templateUrl: './rfq-edit-status.component.html',
  styleUrls: ['./rfq-edit-status.component.scss']
})
export class RfqEditStatusComponent extends iBisBaseComponent implements OnInit {

  currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
  constructor(edit: RfqEditStatusService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
  }

  get oldDueDate() {
    return this.edit.model && this.edit.model.oldDueDate ? this.iui.formatDateTime(new Date(this.edit.model.oldDueDate)) : '';
  }

  closeEarlier() {
    return this.edit.model && this.edit.model.statusId == 5 && 
    this.edit.model.oldDueDate && new Date(this.edit.model.oldDueDate) < new Date();
  }

}
