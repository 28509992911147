import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { RfqEditService } from '../rfq-edit.service';
import { ClarificationResponseService } from '../../../common/clarification-response/clarification-response.service';

@Component({
  selector: 'app-rfq-edit-clarification',
  templateUrl: './rfq-edit-clarification.component.html',
  styleUrls: ['./rfq-edit-clarification.component.scss']
})
export class RfqEditClarificationComponent extends iBisViewComponent {

  constructor(edit: ClarificationResponseService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public red: RfqEditService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
  }

  clarificationResponse = (ev: any) => {
    this.edit.showPopup(ev.row.data, true).then(resp => {
      ev.component.refresh();
    })
  }

}
