import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class ReportBuyerEditService extends iBisSecureEditService {
  buyer!: any;
  currency!: any;
  user!: any;

  constructor(auth: iBisAuthService, lg: iBisLanguageService, entity: iBisEntityService, config: iBisServerConfig) {
    super(auth, entity, config, lg);
  }

  protected load(): void {
    this.user = this.entity.store(new EntityQuery("UserLoginView").push('u.id', 'u.fullName').eq('appId', this.auth.companyId).like('roles', '%buyer%').addOrderBy(['u.fullName']), false, 'u_id', this.config.saasServer);
    this.buyer = this.entity.store(new EntityQuery('OrderHeaderAgreementView').addOrderByDesc(['oh.orderDate']), false, 'oh_id');
    this.currency = this.entity.store(new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'name', 'CURRENCY').addOrderBy(['orderIndex']), false, 'id', this.config.saasServer);
  }

  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    this.model = this.createDefault();
  }
}
