import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqEditSupplierAddService } from './rfq-edit-supplier-add.service';

@Component({
  selector: 'app-rfq-edit-supplier-add',
  templateUrl: './rfq-edit-supplier-add.component.html'
})
export class RfqEditSupplierAddComponent extends iBisBaseComponent {

  constructor(public override edit: RfqEditSupplierAddService, lg: iBisLanguageService,
      iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route)
  }

}
