<dx-data-grid class="grid-container box-shadow" [dataSource]='buget' keyExpr="id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true'>
    
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
    [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxo-editing mode="row" [allowAdding]="auth.tabAccess.edit" [allowUpdating]="auth.tabAccess.edit"
        [allowDeleting]="auth.tabAccess.delete"></dxo-editing>

    <dxi-column [caption]='L("COST_CENTER")' dataField='costCenter' dataType='string'></dxi-column>
    <dxi-column [caption]='L("AMMOUNT")' dataField='ammount' dataType='number'></dxi-column>
</dx-data-grid>