import { Component, OnInit } from '@angular/core';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Workbook } from 'exceljs';
import * as saveAs from 'file-saver';
import { RfqEditService } from '../rfq-edit.service';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';

@Component({
  selector: 'app-rfq-edit-summary',
  templateUrl: './rfq-edit-summary.component.html',
  styleUrls: ['./rfq-edit-summary.component.scss']
})
export class RfqEditSummaryComponent extends iBisBaseComponent implements OnInit {

  dsQuotationItem!: PivotGridDataSource;
  constructor(private entity: iBisEntityService, public red: RfqEditService,
    lg: iBisLanguageService, iui: iBisUIService) {
    super(<any>null, lg, iui, <any>null);
  }

  ngOnInit(): void {
    this.dsQuotationItem = this.getSummparyQuoteConfig(this.getQuouteData(this.red.model.id));
  }

  private getQuouteData(rfqId: string) {
    const q = new EntityQuery('RFQItemQuoteView').eq('ri.rfqId', rfqId);
    return this.entity.store(q, false, 'id', undefined, (item) => {
      if (item.riq_exchangeRate) {
        item.riq_itemPrice = item.riq_exchangeRate * item.riq_itemPrice;
      }
      item.riq_materialPrice = item.ri_quantity * item.riq_itemPrice;
      // item.m_name = item.ri_rnNo + ' - ' + item.ri_rnPosNo + ' - ' + item.m_name;
      // item.ri_quantity = 'Cantitate: ' + item.ri_quantity;
      // item.riq_alternateName = 'Alternativ:' + (item.riq_alternateName || '-');
      // item.riq_manufacturer = 'Producator:' + (item.riq_manufacturer || '-');
    });
  }

  onExportSummary(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportPivotGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 4, column: 1 },
      keepColumnWidths: true,
      exportRowFieldHeaders: true,
      exportDataFieldHeaders: true,
      exportColumnFieldHeaders: true,
      exportFilterFieldHeaders: true,
      customizeCell: ({ pivotCell, excelCell }) => {
        //   if (this.isDataCell(pivotCell) || this.isTotalCell(pivotCell)) {
        //     const appearance = this.getConditionalAppearance(pivotCell);
        //     Object.assign(excelCell, this.getExcelCellFormat(appearance));
        //   }

        const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
        excelCell.border = {
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle,
          top: borderStyle,
        };
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Centralizator_' + this.red.model.refNumber + '.xlsx');
      });
    });
    e.cancel = true;
  }

  getSummparyQuoteConfig(ds: any): PivotGridDataSource {
    return new PivotGridDataSource({
      fields: [{
        caption: this.L("RN_NO"),
        dataField: 'ri_rnNo',
        expanded: true,
        width: 100,
        area: 'row',
        showTotals: false
      }, {
        caption: this.L('RN_POS_NO'),
        dataField: 'ri_rnPosNo',
        expanded: true,
        width: 60,
        area: 'row',
        showTotals: false
      }, {
        caption: this.L('MATERIAL'),
        dataField: 'm_name',
        expanded: true,
        width: 200,
        area: 'row',
        showTotals: false
      }, {
        caption: this.L('QUANTITY'),
        dataField: 'ri_quantity',
        width: 50,
        expanded: true,
        area: 'row',
        showTotals: false
      }, {
        caption: this.L('MANUFACTURER'),
        dataField: 'riq_manufacturer',
        width: 120,
        expanded: true,
        area: 'row',
        showTotals: false
      }, {
        caption: this.L('ALTERNATE_MATERIAL_NAME'),
        dataField: 'riq_alternateName',
        width: 120,
        expanded: true,
        area: 'row',
        showTotals: false
      }, {
        caption: this.L('SUPPLIER'),
        dataField: 'p_name',
        expanded: true,
        area: 'column'
      }, {
        caption: this.L('UNIT_PRICE'),
        dataField: 'riq_itemPrice',
        dataType: 'number',
        width: 50,
        area: 'data',
        summaryType: 'sum',
        showTotals: true,
        format: { type: 'fixedPoint', precision: 2 }
      }, {
        caption: this.L('UNIT_PRICE_RON'),
        dataField: 'riq_materialPrice',
        width: 50,
        area: 'data',
        summaryType: 'sum',
        dataType: 'number',
        showTotals: true,
        format: { type: 'fixedPoint', precision: 2 }
      }, {
        caption: this.L('DELIVERY_DAYS'),
        dataField: 'riq_deliveryDays',
        width: 50,
        dataType: 'number',
        area: 'data',
        showTotals: true,
        summaryType: 'avg',
        format: { type: 'fixedPoint', precision: 0 }
      }],
      store: ds
    });

  }
}
