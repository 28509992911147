import { Injectable } from '@angular/core';
import { iBisEditService, iBisFileService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { DataService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { QuotationService } from '../../quotation.service';

@Injectable({
  providedIn: 'root'
})
export class AddClarificationService extends iBisEditService {
  files: any[] = [];
  constructor(lg: iBisLanguageService, private entity: iBisEntityService,
    private qs: QuotationService) {
    super(lg);
    this.title = this.L('CLARIFICATION');
    this.files = [];
  }
  createDefault() {
    this.files = [];
    return {};
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => { })
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.entity.execute('AddClarification', this.model).then(entityId => {
        notify(this.L('CLARIFICATION_ADDED'), 'success', 2000);
        for (const fileItem of this.files) {
          this.qs.uploadFile(entityId, fileItem.name, fileItem).subscribe(resp => {
            notify(this.L('FILE_UPLOADED'), 'success', 2000);
          }, err => {
            notify(this.L('UPLOAD_ERROR'), 'error', 2000);
          })
        }
        setTimeout(() => {
          resolve("OK");
        }, 1000);
      })
    })
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    const ti = event.component.option('toolbarItems');
    ti[0].options.icon = 'email';
    ti[0].options.text = this.L('SEND');
    ti.unshift({
      toolbar: 'bottom',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        type: 'default',
        text: this.L('UPLOAD_FILE'),
        onClick: (eb: any) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', '.*');
          input.addEventListener('change', (e) => {
            if (input && input.files) {
              const file: File = input.files[0];
              if (Math.floor(file.size / 1048576) >= 10) {
                alert("Dimensiunea maxima admisa este de 10MB.", "Alerta");
                return;
              }
              this.files.push(file);
            }
          });
          input.click();
        }
      },
      location: 'before'
    })
    event.component.option('toolbarItems', ti);
    event.component.endUpdate();
  }

}
