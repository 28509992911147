<dx-form [formData]="edit.model" [colCount]="6" (onFieldDataChanged)="onFormUpdate($event)"
    [validationGroup]="edit.validation" [readOnly]="readOnly"
    (onInitialized)="onInitializedFormEv($event)">
    <dxi-item [label]="{text: L('MATERIAL_SERVICE') }" [dataField]="byMaterial ? 'materialId' : 'rfqItemId'" editorType="dxSelectBox" [colSpan]="6"
        [editorOptions]="{ dataSource: rfqItems, valueExpr: materialValExpr, displayExpr: materialDispExpr, 
        onSelectionChanged: onItemChanged, readOnly: edit.model.id != undefined }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('MANUFACTURER') + '(max 256 char)' }" dataField="manufacturer" editorType="dxTextBox"
        [editorOptions]="{ maxLength : 256  }" [colSpan]="6" [visible]="edit.model.typeId == 1"></dxi-item>

    <dxi-item [label]="{visible: false}" dataField="isAlternate" editorType="dxCheckBox"
        [editorOptions]="{ text: L('IS_ALTERNATE') }" [visible]="edit.model.typeId == 1"></dxi-item>
    <dxi-item [label]="{ text: L('ALTERNATE_MATERIAL_NAME') + '(max 40 char)' }" dataField="alternateName"
        editorType="dxTextBox" [editorOptions]="{ disabled: !edit.model.isAlternate, maxLength: 40 }" [colSpan]="5"
        [visible]="edit.model.typeId == 1">
    </dxi-item>

    <dxi-item [label]="{text: L('REQUESTED_QUANTITY') }" dataField="quantity" editorType="dxNumberBox" [editorOptions]="{
        readOnly: true }" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule *ngIf="edit.model.minQuantity" type="range" [min]="edit.model.minQuantity">
        </dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('UOM') }" dataField="uom" editorType="dxTextBox" [editorOptions]="{ readOnly: true }" [colSpan]="2">
    </dxi-item>

    <dxi-item [label]="{text: L('CURRENCY') }" dataField="currencyId" editorType="dxSelectBox" [editorOptions]="{ dataSource: currency, valueExpr: 'id', displayExpr: 'name', 
        onSelectionChanged: onCurrencyChanged }" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [label]="{text: L('WARRANTY_MONTHS') }" dataField="warrantyMonths" editorType="dxNumberBox"
        [editorOptions]="{ min: 0, disabled: byMaterial ? !edit.model.materialId : !edit.model.rfqItemId }" [colSpan]="2">
    </dxi-item>
    <dxi-item [label]="{text: L('PAYMENT_METHOD') }" editorType="dxSelectBox" dataField="paymentMethod"
        [editorOptions]="{ dataSource: dsPaymentMethods, valueExpr: 'value', displayExpr: 'name'}">
    </dxi-item> 

    <dxi-item [label]="{text: L('PAYMENT_DAYS') }" dataField="paymentDays" editorType="dxNumberBox"
        [editorOptions]="{ min: 1, disabled: (byMaterial ? !edit.model.materialId : !edit.model.rfqItemId) || edit.model.paymentMethod == 0}">
        <!-- <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="range" [min]="1"></dxi-validation-rule> -->
    </dxi-item>

    <dxi-item [label]="{text: edit.model.typeId == 1 ? L('DELIVERY_DAYS'): L('EXECUTION_DAYS') }"
        dataField="deliveryDays" editorType="dxNumberBox"
        [editorOptions]="{ min: 1, disabled: byMaterial ? !edit.model.materialId : !edit.model.rfqItemId }" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="range" [min]="1"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [colSpan]="6">
        <div *dxTemplate="let data of 'template'">
            <dx-data-grid id="quoteGridId" [dataSource]="edit.quotePriceItem" [showColumnHeaders]="false"
                (onToolbarPreparing)="onQuoteItemPrepared($event)" (onRowUpdated)="onPriceChanged($event)"
                (onRowInserted)="onPriceChanged($event)" (onRowRemoved)="onPriceChanged($event)">
                <dxo-editing mode="cell" [allowAdding]="!readOnly" [allowUpdating]="!readOnly || edit.model.isDiscount"
                    [allowDeleting]="!readOnly">
                </dxo-editing>
                <dxi-column dataField="itemId" dataType="string">
                    <dxo-lookup [dataSource]="priceSource" valueExpr="id" displayExpr="description"></dxo-lookup>
                </dxi-column>
                <dxi-column dataField="value" dataType="number" [customizeText]="priceCustomize"></dxi-column>

                <dxo-summary>
                    <dxi-total-item column="value" summaryType="sum"
                        [displayFormat]="L('UNIT_PRICE') + ': {0}&nbsp;' + (edit.model.currencyName ?? '') ">
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
            <p class="total-price">
                <span>{{L('QUANTITY')}}: {{edit.model.quantity}}</span>
                <span class="total">{{L('TOTAL_PRICE')}}: {{calcTotal(edit.model.quantity * edit.model.itemPrice)}}&nbsp;{{edit.model.currencyName}}</span>
            </p>
            <p class="price-vat-info"><i>{{L('PRICE_NO_VTA_MESSAGE')}}</i></p>
        </div>
    </dxi-item>
    <dxi-item [label]="{text: L('OBSERVATIONS') }" dataField="observations" editorType="dxTextArea" [colSpan]="6"
        [editorOptions]="{  disabled: byMaterial ? !edit.model.materialId : !edit.model.rfqItemId }" [helpText]="L('PARTY_HELP_OBSERVATIONS')"></dxi-item>
</dx-form>