import { Injectable } from '@angular/core';
import { iBisEditService, iBisFileService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class ClarificationResponseService extends iBisEditService {
  responseFiles: any;
  clarificationFiles: any;
  constructor(lg: iBisLanguageService, private entity: iBisEntityService,
    private file: iBisFileService) {
    super(lg);
    this.title = this.L("CLARIFICATION_RESPONSE");
  }

  createDefault() {
    return {};
  }
  getById(data: any, serverUrl?: string | undefined): Promise<any> {
    this.responseFiles = this.file.getFileInfo(data.responseFileId);
    this.clarificationFiles = this.file.getFileInfo(data.id);
    return this.entity.getById('Clarification', data.id);
  }
  override onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    this.model.responseAt = new Date();
    return this.entity.save('Clarification', this.model.id, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(ev: any): void {
    super.popupInit(ev);
    ev.component.beginUpdate();
    let tbBtns = ev.component.option('toolbarItems');
    tbBtns.unshift({
      location: 'after',
      options: {
        text: this.L('UPLOAD_FILE'),
        icon: 'plus',
        type: 'default',
        onClick: (ev: any) => {
          this.file.upload(this.model.responseFileId).then(resp => {
            this.responseFiles = this.file.getFileInfo(this.model.responseFileId);
          })
        }
      },
      toolbar: 'bottom',
      widget: 'dxButton'
    });
    ev.component.option('toolbarItems', tbBtns);
    ev.component.endUpdate();
  }

}
