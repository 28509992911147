<dx-data-grid #qgrid [dataSource]="qs.quotationList" keyExpr="riq_id" [columnAutoWidth]="true"
    (onToolbarPreparing)="onToolbarQuotation($event)" [height]="qs.gridHeight"
    (onCellPrepared)="onQuoteCellPrepared($event)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-selection [mode]="isDiscountInputVisible ? 'multiple' : 'none'"></dxo-selection>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button icon="edit" [onClick]="onEditClick" [visible]="qs.commercialVisible"></dxi-button>
        <dxi-button icon="trash" [onClick]="onDeleteClick" [visible]="qs.commercialVisible"></dxi-button>
        <dxi-button icon="rename" [onClick]="onEditClick"
            [visible]="qs.discountVisible && !qs.commercialVisible"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('STATUS')" dataField="riq_isWinner" dataType="boolean" [width]="100">
        <dxo-lookup [dataSource]="qs.winnerQuote" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("MATERIAL")' cellTemplate="materialTemplate" [width]="300">
        <div *dxTemplate="let row of 'materialTemplate'" class="no-margin">
            <p *ngIf="row.data.ri_rnNo">
                <span class="position">{{row.data.ri_rnNo}}</span>
                <span class="position">{{row.data.ri_rnPosNo}}</span>
            </p>
            <h3>{{row.data.m_name}}</h3>
            <p>{{L("MANUFACTURER")}}: <b>{{row.data.riq_manufacturer}}</b></p>
            <p>{{L("IS_ALTERNATE")}}: <b>{{L(row.data.riq_isAlternate ? "YES": "NEGATIV")}}</b></p>
            <p *ngIf="row.data.riq_isAlternate">{{L("ALTERNATE_MATERIAL_NAME")}}: <b>{{row.data.riq_alternateName}}</b>
            </p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("QUANTITY")' cellTemplate="qtyTemplate">
        <div *dxTemplate="let row of 'qtyTemplate'" class="no-margin">
            <p>{{L("PROVIDED_QUANTITY")}}: <b>{{row.data.riq_quantity}}</b></p>
            <p>{{L("UOM")}}: <b>{{!qs.party.enLanguage ? row.data.uom_description : row.data.uom_descriptionEng}}</b>
            </p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("PRICE")' cellTemplate="priceTemplate">
        <div *dxTemplate="let row of 'priceTemplate'" class="no-margin">
            <p *ngIf="row.data.riq_originalPrice && row.data.riq_originalPrice != row.data.riq_itemPrice">
                {{L("INITIAL_PRICE")}}: <b class="line-thr">{{row.data.riq_originalPrice}}</b></p>
            <p>{{L("UNIT_PRICE")}}: <b>{{row.data.riq_itemPrice}}</b></p>
            <p>{{L("CURRENCY")}}: <b>{{getCurrency(row.data.riq_currencyId)}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("TOTAL_PRICE")' name="total_price" cssClass="gray-bg" [calculateCellValue]="calcTotaPrice"
        dataType="number">
    </dxi-column>
    <dxi-column [caption]='L("TERMS")' cellTemplate="termsTemplate">
        <div *dxTemplate="let row of 'termsTemplate'" class="no-margin">
            <p>{{L("PAYMENT_DAYS")}}: <b>{{row.data.riq_paymentDays}}</b></p>
            <p>{{L("DELIVERY_DAYS")}}: <b>{{row.data.riq_deliveryDays}}</b></p>
            <p>{{L("WARRANTY_MONTHS")}}: <b>{{row.data.riq_warrantyMonths}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("OBSERVATIONS")' dataField='riq_observations' dataType='string'></dxi-column>
    <dxo-summary>
        <dxi-total-item column='total_price' summaryType="sum"></dxi-total-item>
    </dxo-summary>
</dx-data-grid>