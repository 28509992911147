import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './order.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderEditService } from './order-edit/order-edit.service';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { iBisCommonModule } from '@dohu/ibis-common';
import { OrderHeaderEditComponent } from './order-edit/order-header-edit/order-header-edit.component';
import { OrderLineEditComponent } from './order-edit/order-line-edit/order-line-edit.component';



@NgModule({
  declarations: [
    OrderComponent,
    OrderEditComponent,
    OrderHeaderEditComponent,
    OrderLineEditComponent
  ],
  imports: [
    CommonModule,
    iBisCommonModule,

    DxDataGridModule,
    DxFormModule,
    DxAccordionModule,
    DxTabPanelModule
  ],
  exports: [
    OrderComponent,
    OrderEditComponent,
    OrderHeaderEditComponent,
    OrderLineEditComponent
  ],
  providers: [OrderEditService]
})
export class OrderModule { }
