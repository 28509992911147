<dx-tab-panel [animationEnabled]="true" [swipeEnabled]="false">
  <dxi-item [title]="L('REQUEST_DETAILS')">
    <dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="2">
      <dxi-item itemType="group" [caption]="L('REQUESTED')" [colCount]="2">
        <dxi-item [label]="{ text: L('RN_NO') }" dataField="ri_compusNo" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item [label]="{ text: L('DEPARTMENT') }" dataField="dep_description" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item [label]="{ text: L('REQUESTER') }" dataField="ri_requesterName" editorType="dxTextBox" [colSpan]="2"
          [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item [label]="{ text: L('MATERIAL') }" dataField="materialName" editorType="dxTextBox" [colSpan]="2"
          [editorOptions]="{readOnly: true}"></dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [caption]="L('INFO_SUPPLIER_OFFER')" [visible]="edit.model.flowType == 1">
        <dxi-item [label]="{ text: L('SUPPLIER') }" dataField="supplier" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item [label]="{ text: L('MANUFACTURER') }" dataField="manufacturer" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item [label]="{ text: L('ALTERNATE_MATERIAL_NAME')}" dataField="alternateName" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item [label]="{ text: L('SUPPLIER_OBSERVATIONS')}" dataField="observations" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [caption]="L('OFFER')" [visible]="edit.model.flowType == 2">
        <dxi-item [label]="{ text: L('SUPPLIER') }" dataField="supplier" editorType="dxTextBox"
          [editorOptions]="{readOnly: true}"></dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [colSpan]="2">
        <dxi-item [label]="{ text: L('WRITE_MESSAGE') }" dataField="rejectedReason" editorType="dxTextArea"
          [editorOptions]="{ maxLength : 256 }">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </dxi-item>
  <dxi-item [title]="L('SUPPLIER_FILES')">
    <ibis-file [entityId]="edit.model.id" [allowUpload]="false"></ibis-file>
  </dxi-item>
  <dxi-item [title]="L('CLARIFICATION_FILES')" [visible]="edit.model.techStatus == 2">
    <ibis-file #fileGrid [entityId]="edit.model.entityFileId" [allowDelete]='true'></ibis-file>
  </dxi-item>
</dx-tab-panel>