<div class='actions-bar'>
    <dx-toolbar [visible]="edit.model && edit.model.statusId != null">
        <dxi-item location="before">
            <p class="left-title">{{edit.leftTitle}}</p>
        </dxi-item>
        <dxi-item location="center" locateInMenu="auto">
        </dxi-item>
        <dxi-item location="after" locateInMenu="never">
            <dx-button class="headbtn" type="normal" icon="fas fa-arrow-left" hint="Back" (onClick)="goBack()">
            </dx-button>
            <dx-button class="headbtn" type="default" [text]="L('REOPEN')" (onClick)="updateRFQ(0)"
                [visible]="statusCheck([-1])"></dx-button>
            <!-- <dx-button class="headbtn" type="normal" [text]="L('SAVE')" (onClick)="edit.onSaveRFQ()"
                [visible]="edit.model && edit.model.statusId == 0"></dx-button> -->
            <dx-button class="headbtn" type="default" [text]="L('PUBLISH_REQUEST')" (onClick)="updateRFQ(5)"
                [visible]="statusCheck([0])"></dx-button>

            <dx-button class="headbtn" type="default" [text]="L('TECHNICAL_APPROVE')" (onClick)="updateRFQ(10)"
                [visible]="statusCheck([5])"></dx-button>

            <dx-button class="headbtn" type="normal" [text]="L('REPUBLISH_RFQ')" (onClick)="updateRFQ(5)"
                [visible]="statusCheck([10, 15, 18, 20])"></dx-button>
            <dx-button class="headbtn" type="default" [text]="L('COMMERCIAL_PUBLISH')" (onClick)="updateRFQ(12)"
                [visible]="statusCheck([], [{flowType: 2, statusId: 10 }])"></dx-button>

            <dx-button class="headbtn" type="default" [text]="L('COMMERCIAL_APPROVE')" (onClick)="updateRFQ(15)"
                [visible]="statusCheck([12], [{flowType: 1, statusId: 10}])">
            </dx-button>
            <dx-button class="headbtn" type="default" [text]="L('ANALYSE_WINNER')" (onClick)="updateRFQ(18)"
                [visible]="statusCheck([5, 10, 12, 15])"></dx-button>
            <dx-button class="headbtn" type="success" [text]="L('FINISH')" (onClick)="updateRFQ(20)"
                [visible]="statusCheck([18], [{flowType: 1, statusId: 0}])">
            </dx-button>
            <dx-button class="headbtn" type="success" [text]="L('PURCHASE_ORDER')" (onClick)="generateOrder()"
                [visible]="statusCheck([20])">
            </dx-button>

            <dx-button class="headbtn" icon="trash" [hint]="L('CANCEL')" (onClick)="updateRFQ(-1)"
                [visible]="edit.model && edit.model.statusId >= 0 && edit.model.statusId < 20"></dx-button>
        </dxi-item>
    </dx-toolbar>
</div>

<dx-drawer position="right" template="template" [opened]="!collapsed" [maxSize]='400' [minSize]='0'>
    <div *dxTemplate="let data of 'template'" class='issue-form-edit'>
        <div class="right-box dx-field">
            <div class="dx-field-label">
                <dx-button (onClick)='onCollapsed($event)' class="arrow-btn"
                    [icon]="collapsed == true ? 'chevrondoubleleft' : 'chevrondoubleright'">
                </dx-button>
            </div>
            <div class="title">{{L('RFQ_DETAILS')}}</div>
        </div>
        <app-rfq-edit-details [collapsed]="collapsed"></app-rfq-edit-details>
    </div>
    <div *ngIf="edit.model && edit.model.id; else loadingBlock">
        <dx-tab-panel [animationEnabled]="true" [swipeEnabled]="false">
            <dxi-item [title]="L('REQUEST_LIST')">
                <app-rfq-edit-item-list></app-rfq-edit-item-list>
            </dxi-item>
            <dxi-item [title]="L('QUOTATION_LIST')">
                <app-rfq-edit-quotation></app-rfq-edit-quotation>
            </dxi-item>
            <dxi-item [title]="L('SUMMARY_TABLE')">
                <app-rfq-edit-summary></app-rfq-edit-summary>
            </dxi-item>
            <dxi-item [title]="L('SUPPLIERS')">
                <app-rfq-edit-supplier></app-rfq-edit-supplier>
            </dxi-item>
            <dxi-item [title]="L('DOCUMENTATION')">
                <ibis-file [entityId]="edit.model.id" [height]="edit.gridHeight" [allowDelete]="true"></ibis-file>
            </dxi-item>
            <dxi-item [title]="L('CLARIFICATIONS')">
                <app-rfq-edit-clarification></app-rfq-edit-clarification>
            </dxi-item>
            <!-- <dxi-item [title]="L('GENERATED_ORDERS')">
                <app-order [dsOrder]="edit.dsOrder"></app-order>
            </dxi-item> -->
            <dxi-item [title]="L('NOTIFICATIONS')">
                <app-rfq-edit-email-notification [entityId]="edit.model.id"></app-rfq-edit-email-notification>
            </dxi-item>
            <dxi-item [title]="L('AUDIT')">
                <app-rfq-edit-audit [entityId]="edit.model.id"></app-rfq-edit-audit>
            </dxi-item>
        </dx-tab-panel>
        <div class="footer">
            Creat de: <b>{{buyerName}}</b> la data: <b>{{edit.model.created | date : 'dd.MM.YYYY' }}</b>
            <span *ngIf="edit.model.publishedDate"> si publicat in data: <b>{{edit.model.publishedDate | date :
                    'dd.MM.YYYY HH:mm' }}</b></span>
        </div>
    </div>
    <ng-template #loadingBlock>
        <div style="width: 100%">
            <img class='fade-in logo-loading' src='assets/img/loading_icon.gif' />
        </div>
    </ng-template>
</dx-drawer>

<ibis-edit-popup [edit]="redOrdSel" [showAdd]="false" [width]="'90vw'">
    <app-rfq-edit-order-select></app-rfq-edit-order-select>
</ibis-edit-popup>

<ibis-edit-popup [edit]="sns" [showAdd]="false">
    <app-send-notification></app-send-notification>
</ibis-edit-popup>

<ibis-edit-popup [edit]="sts" [showAdd]="false">
    <app-rfq-edit-status></app-rfq-edit-status>
</ibis-edit-popup>

<ibis-edit-popup [edit]="modalFile" [showAdd]="false">
    <app-modal-file></app-modal-file>
</ibis-edit-popup>

<ibis-edit-popup [edit]="order" [showAdd]="false" [width]="'90vw'" [height]="'90vh'">
    <app-order-edit></app-order-edit>
</ibis-edit-popup>