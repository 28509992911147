<dx-data-grid #rfqItemGrid class="grid-container box-shadow" [dataSource]='rfq.rfqItem' [height]="fullHeight"
    (onToolbarPreparing)='onToolbarPreparing($event)' [remoteOperations]='true' keyExpr="ri_id"
    (onInitialized)="onGridInit($event)" [filterSyncEnabled]='true' [filterValue]='dsFilter'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-selection [mode]="rfq.indexItem == 0 ? 'multiple': 'none'" [allowSelectAll]="true" showCheckBoxesMode="always">
    </dxo-selection>

    <dxi-column [caption]='L("RN_NO")' dataField='ri_rnNo' dataType='string' [groupIndex]="0"
        [customizeText]="ds.stringZero"></dxi-column>
    <dxi-column [caption]='L("RN_POS_NO")' dataField='ri_rnPosNo' dataType='string'
        [customizeText]="ds.stringZero"></dxi-column>
    <dxi-column [caption]='L("RFQ")' [isBand]="true" headerCellTemplate="rfqBand" [visible]="rfq.indexItem > 0">
        <dxi-column [caption]='L("RFQ_NO")' cellTemplate='refNumberTemplate' [width]="150">
            <div *dxTemplate="let item of 'refNumberTemplate'">
                <a class="nr-col blue-txt" href="#/rfq-edit/{{item.data.ri_rfqId}}"
                    title="Click pentru RFQ">{{item.data.r_refNumber}}</a>
            </div>
        </dxi-column>
        <dxi-column [caption]='L("TITLE")' dataField='r_title' dataType="string" [width]="200"></dxi-column>
        <dxi-column [caption]='L("STATUS")' dataField='r_statusId' dataType="number">
            <dxo-lookup [dataSource]="ds.static.rfqStatus" valueExpr="id" displayExpr="name"></dxo-lookup>
        </dxi-column>
    </dxi-column>
    <dxi-column [caption]='L("ORDER")' [isBand]="true" headerCellTemplate="orderBand" [visible]="rfq.indexItem > 1">
        <dxi-column [caption]='L("ORDER_REF_NUMBER")' cellTemplate='orderRefNumber'>
            <div *dxTemplate="let item of 'orderRefNumber'">
                <a class="nr-col blue-txt" href="#" (click)="orderClicked(item)"
                    title="Click pentru comanda">{{item.data.oh_refNumber}}</a>
            </div>
        </dxi-column>
        <dxi-column [caption]='L("SAP_REF_NUMBER")' dataField='oh_sapRefNumber' dataType='string'></dxi-column>
        <dxi-column [caption]='L("RN_POS_NO")' dataField='ol_posNo' dataType='number'></dxi-column>
    </dxi-column>
    <dxi-column [caption]='L("REQUESTER")' [isBand]="true" headerCellTemplate="reqBand">
        <dxi-column [caption]='L("REQUESTER")' dataField='ri_requesterName' dataType='string'></dxi-column>
        <dxi-column [caption]='L("REQUEST_REASON")' dataField='ri_reason' dataType='string'></dxi-column>
        <dxi-column [caption]='L("DOC_TYPE")' dataField='doc_description' dataType='string'></dxi-column>
        <dxi-column [caption]='L("ACCOUNT")' dataField='ri_account' dataType='string'></dxi-column>
        <dxi-column [caption]='L("DEPARTMENT")' dataField='dep_description' dataType='string'></dxi-column>
    </dxi-column>


    <dxi-column [caption]='L("MATERIAL")' [isBand]="true" headerCellTemplate="matBand">
        <dxi-column [caption]='L("CODE")' dataField='m_code' dataType='string' [allowSearch]="false"
            [customizeText]="ds.stringZero">
        </dxi-column>
        <dxi-column [caption]='L("NAME")' dataField='m_name' dataType='string'></dxi-column>
        <dxi-column [caption]='L("NAME_ENG")' dataField='m_nameEng' dataType='string'></dxi-column>
        <dxi-column [caption]='L("GROUP")' dataField='mg_description' dataType='string' [allowEditing]="false">
        </dxi-column>
        <dxi-column [caption]='L("TYPE")' dataField='m_typeId' dataType='number' [allowSearch]="false">
            <dxo-lookup [dataSource]="ds.static.materialType" valueExpr="id" displayExpr="name"></dxo-lookup>
        </dxi-column>
        <dxi-column [caption]='L("QUANTITY")' dataField='ri_quantity' dataType='number' [allowSearch]="false">
        </dxi-column>
        <dxi-column [caption]='L("UOM")' dataField='uom_name' dataType='string' [allowEditing]="false"></dxi-column>
        <dxi-column [caption]='L("REFERENCE_PRICE")' dataField='ri_price' dataType='number' [allowSearch]="false">
        </dxi-column>
        <dxi-column [caption]='L("CURRENCY")' dataField='ri_currencyId' dataType='string'>
            <dxo-lookup [dataSource]="rfq.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
        </dxi-column>
    </dxi-column>
    <dxi-column [caption]='L("LOGISTICS")' [isBand]="true" headerCellTemplate="logBand">
        <dxi-column [caption]='L("DELIVERY_DATE")' dataField='ri_deliveryDate' dataType='date'>
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <!-- <dxi-column [caption]='L("LOGISTIC_UNIT")' dataField='ri_logisticUnit' dataType='string'></dxi-column> -->
        <dxi-column [caption]='L("SUPPLY_GROUP")' dataField='ri_userId' dataType='string'>
            <dxo-lookup [dataSource]="rfq.buyer" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
        </dxi-column>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER")' [isBand]="true" headerCellTemplate="supBand">
        <dxi-column [caption]='L("PARTY_WANTED")' dataField='pw_name' dataType='string'></dxi-column>
        <dxi-column [caption]='L("PARTY_FIXED")' dataField='pf_name' dataType='string'></dxi-column>
        <dxi-column [caption]='L("CONTRACT_NO")' dataField='ri_contractNo' dataType='string'></dxi-column>
        <dxi-column [caption]='L("CONTRACT_POSITION_NO")' dataField='ri_contractPosNo' dataType='number'
            [allowSearch]="false">
        </dxi-column>
    </dxi-column>
    <dxi-column [caption]='L("BENEFICIARY")' [isBand]="true" headerCellTemplate="benBand">
        <dxi-column [caption]='L("SAP_REF_NUMBER")' dataField='ri_sapRefNumber' dataType='string'></dxi-column>
        <dxi-column [caption]='L("TECHNICAL")' dataField='ri_techUserId' dataType='string'>
            <dxo-lookup [dataSource]="rfq.approver" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
        </dxi-column>
        <dxi-column [caption]='L("COST_CENTER")' dataField='ri_costCenter' dataType='string'></dxi-column>
        <dxi-column [caption]='L("ORDER_REF_NO")' dataField='ri_orderRef' dataType='string'></dxi-column>
        <dxi-column [caption]='L("FIXED")' dataField='ri_fixedAsset' dataType='string'></dxi-column>
        <dxi-column [caption]='L("SDA")' dataField='ri_sda' dataType='string'></dxi-column>
        <dxi-column [caption]='L("CREATED")' dataField='ri_created' dataType='datetime'></dxi-column>
    </dxi-column>
    <div *dxTemplate="let r of 'rfqBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-business-time"></i>
        {{L("RFQ")}}
    </div>
    <div *dxTemplate="let r of 'orderBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-file-invoice"></i>
        {{L("ORDER")}}
    </div>
    <div *dxTemplate="let r of 'reqBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-code-pull-request"></i>
        {{L("REQUESTER")}}
    </div>
    <div *dxTemplate="let r of 'matBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-box"></i>
        {{L("MATERIAL")}}
    </div>
    <div *dxTemplate="let r of 'logBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-chart-line"></i>
        {{L("LOGISTICS")}}
    </div>
    <div *dxTemplate="let r of 'supBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-dolly"></i>
        {{L("SUPPLIER")}}
    </div>
    <div *dxTemplate="let r of 'benBand'" class="dx-datagrid-text-content" role="presentation">
        <i class="fa-solid fa-handshake"></i>
        {{L("BENEFICIARY")}}
    </div>
</dx-data-grid>

<ibis-edit-popup [edit]="editOrder" [showAdd]="false" [width]="'90vw'" [height]="'90vh'">
    <app-order-edit></app-order-edit>
</ibis-edit-popup>
<ibis-edit-popup [edit]="rfqNew" [showAdd]="false">
    <app-rfq-new></app-rfq-new>
</ibis-edit-popup>