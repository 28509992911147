import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisViewComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataService } from '../../../app/services';
import { OrderEditService } from './order-edit.service';
import { OrderLineEditService } from './order-line-edit/order-line-edit.service';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
})
export class OrderEditComponent extends iBisViewComponent implements OnInit {

  @ViewChild('orderEditGrid') override grid!:DxDataGridComponent;

  constructor(public override edit: OrderLineEditService, lg: iBisLanguageService, iui: iBisUIService, public auth: iBisAuthService,
    public ds: DataService, public oes: OrderEditService, private route: ActivatedRoute, private router: Router) {
    super(edit, lg, iui, route);
    this.key = 'orderLineGrid';
    this.id = 'ol_id';
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && this.router.url.indexOf('order') > -1) {
      this.oes.getById(id).then((data) => this.edit.model = data);
      // this.edit.initOrderEdit(id);
    }
  }

  onLinePrepared(ev: any) {
    if (ev.rowType == 'data') {
      if (!ev.data.ol_isActiv && !ev.rowElement.classList.hasOwnProperty('red-txt')) {
        ev.rowElement.classList.add('red-txt');
      }
    }
  }

  totalItem = (row: any) => {
    var val = row.ol_quantity && row.ol_netPrice ? (row.ol_quantity * row.ol_netPrice) : 0;
    return Math.round(val * 100) / 100;
  }

}
