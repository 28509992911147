import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
import * as Excel from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { QuotationItemEditService } from 'src/app/common/quotation-item-edit/quotation-item-edit.service';
import { DataService } from '../../../../app/services';
import { RfqService } from '../../rfq.service';
import { RfqEditService } from '../rfq-edit.service';
import * as saveAs from 'file-saver';
import { Subscription } from 'rxjs';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';
import { RfqEditTechUserService } from './rfq-edit-tech-user/rfq-edit-tech-user.service';

@Component({
  selector: 'app-rfq-edit-quotation',
  templateUrl: './rfq-edit-quotation.component.html',
  styles: ['.no-margin h3, .no-margin p { margin: 0 }']
})
export class RfqEditQuotationComponent extends iBisViewComponent implements OnInit, OnDestroy {

  @ViewChild('gridQuotation') override grid!: DxDataGridComponent;
  sbs!: Subscription;
  constructor(public override edit: QuotationItemEditService, lg: iBisLanguageService, iui: iBisUIService,
    route: ActivatedRoute, public rqs: RfqService, public red: RfqEditService, public ds: DataService,
    public modalFile: ModalFileService, public techUser: RfqEditTechUserService) {
    super(edit, lg, iui, route);
    this.key = 'quotationItemGrid';
    this.id = 'riq_id';
  }

  ngOnInit() {
    this.sbs = this.red.rfqSubject.subscribe(() => {
      this.red.reloadRFQItemQuote(this.red.model.id);
    })
  }

  ngOnDestroy() {
    if (this.sbs) {
      this.sbs.unsubscribe();
    }
  }

  override toolbarPreparing(ev: any, showAdd: boolean) {
    super.toolbarPreparing(ev, showAdd);
    const reloadBtn = ev.toolbarOptions.items.find((x: any) => x.options?.hint == 'Reload Data');
    if (reloadBtn) {
      reloadBtn.onClick = (er: any) => this.red.reloadRFQItemQuote(this.red.model.id)
    }
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        text: this.L('RECALCULATE_SCORE'),
        visible: this.red.model.statusId > 5 && this.red.model.statusId <= 20,
        onClick: (eb: any) => {
          this.red.recalculateScore(this.red.model.id).then(() => {
            ev.component.refresh();
            notify(this.L('SUCCESS'), 'success', 3000);
          })
        }
      },
      location: 'after'
    });
  }

  openTechUser(ev: any, quoteId: string, rfqItemId: string) {
    this.techUser.showPopup({ quoteId, rfqItemId }, true).then(resp => {

    })
    ev.preventDefault();
  }

  getBuyer(id: string) {
    var item = this.rqs.buyer.find(r => r.u_id == id)
    return item ? item.u_fullName : '-';
  }

  getIncoTermsValue = (rowInfo: any) => {
    return this.getIncoTerms(rowInfo.rp_incoTerm);
  }

  getIncoTerms(code: string) {
    var ts = this.ds.static.incoTerm.find((r: any) => r.code == code);
    return ts ? ts.name : '-';
  }

  getTransportStateValue = (rowInfo: any) => {
    return this.getTransportState(rowInfo.rp_transportState) + this.getCurrency(rowInfo.rp_transportCurrency);
  }

  getTransportState(state: number) {
    var ts = this.ds.static.transportState.find((r: any) => r.id == state);
    return ts ? ts.name : '-';
  }

  getCurrency(id: string) {
    var item = this.rqs.currency.find(r => r.id == id)
    return item ? item.name : '-';

  }

  onApproveQuotation = (ev: any) => {
    this.edit.updateComm(ev.row.data.riq_id, false).then(() => {
      notify(this.L('SUCCESS'), 'success', 3000);
      this.red.reloadRFQItemQuote(ev.row.data.ri_rfqId);
    }, err => { this.lg.showError(err); })
  }

  onRejectQuotation = (ev: any) => {
    this.edit.updateComm(ev.row.data.riq_id, true).then(() => {
      notify(this.L('SUCCESS'), 'success', 3000);
      this.red.reloadRFQItemQuote(ev.row.data.ri_rfqId);
    }, err => { this.lg.showError(err); })
  }

  onExporting(e: any) {

    e.component.beginUpdate();
    var cols = ["m_code", "riq_alternateName", "riq_manufacturer", "p_name", "p_code", "riq_techStatus",
      "riq_commRejected", "riq_rejectedReason", "ri_quantity", "uom_name", "riq_deliveryDays",
      "riq_warrantyMonths", "riq_currencyId", "riq_paymentDays", 'rp_transportValue',
      'rp_paymentDays', 'rp_executionDays', 'rp_transportState', 'rp_observations'];
    cols.forEach(col => {
      e.component.columnOption(col, 'visible', true);
    })

    var exCols = [this.L("SUPPLIER"), this.L("PRICE"), this.L("APPROVE_TEMPLATE"),
    this.L("TERMS"), this.L("TERMS_OFFER"), this.L("CREATED/MODIFIED")];
    exCols.forEach(col => {
      e.component.columnOption(col, 'visible', false);
    })

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const fileName = 'Cotatii_oferta_' + this.red.model.refNumber + '.xlsx';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell?.rowType == 'data') {
          if (gridCell.column?.caption === "Denumire") {
            excelCell.value = gridCell.data.m_name;
          }
          if (gridCell.column?.dataField === "riq_currencyId") {
            excelCell.value = this.getCurrency(gridCell.data.riq_currencyId);
          }
          if (gridCell.column?.dataField === "riq_techStatus") {
            if (gridCell.data.riq_techStatus == 0)
              excelCell.value = 'Respins';
            else if (gridCell.data.riq_techStatus == 1)
              excelCell.value = 'Aprobat';
            else
              excelCell.value = 'In asteptare';
          }
          if (gridCell.column?.dataField === "riq_commRejected") {
            if (gridCell.data.riq_commRejected == false)
              excelCell.value = 'Aprobat';
            else if (gridCell.data.riq_commRejected == null)
              excelCell.value = 'In asteptare';
            else
              excelCell.value = 'Respins';
          }

          if (gridCell.column?.dataField === 'rp_incoterm') {
            excelCell.value = this.getIncoTerms(gridCell.data.rp_incoterm);
          }
          if (gridCell.column?.dataField === 'rp_transportState') {
            excelCell.value = this.getTransportState(gridCell.data.rp_transportState);
            if (gridCell.data.rp_transportValue) {
              excelCell.value += '(' + gridCell.data.rp_transportValue + this.getCurrency(gridCell.data.rp_transportCurrency) + ')';
            }
          }
        }
      }

    }).then(() => {
      workbook.xlsx.writeBuffer()
        .then((buffer: BlobPart) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
        });
    }).then(() => {
      cols.forEach(col => {
        e.component.columnOption(col, 'visible', false);
      })
      exCols.forEach(col => {
        e.component.columnOption(col, 'visible', true);
      })
      e.component.endUpdate();
    });
    e.cancel = true;
  }

  onWinnerMark = (ev: any) => {
    this.red.markAsWinner(ev.row.data.riq_id, true).then(() => {
      notify(this.L('SUCCESS'), 'success', 3000);
      this.red.reloadRFQItemQuote(ev.row.data.ri_rfqId);
    }, err => { this.lg.showError(err); })
  }

  onUnwinnerMark = (ev: any) => {
    this.red.markAsWinner(ev.row.data.riq_id, false).then(() => {
      notify(this.L('SUCCESS'), 'success', 3000);
      this.red.reloadRFQItemQuote(ev.row.data.ri_rfqId);
    }, err => { this.lg.showError(err); })
  }

  openQuotationDetails = (ev: any) => {
    this.edit.showPopup(ev.row.data.riq_id, true).then(() => {
      this.red.reloadRFQItemQuote(ev.row.data.ri_rfqId);
    })
  }

  onAttachClick = (ev: any) => {
    this.modalFile.title = this.L("OFFER_ATTACHMENTS");
    this.modalFile.showPopup({ id: ev.row.data.rp_id })
  }

  onAttachClarificationClick = (ev: any) => {
    this.modalFile.title = this.L("CLARIFICATION_FILES");
    this.modalFile.showPopup({ id: ev.row.data.rp_entityFileId });
  }


  cellPrepared(ev: any) {
    if (ev.rowType === 'data' &&
      (['riq_score', 'm_name', 'p_name', 'riq_manufacturer'].indexOf(ev.column.dataField) > -1 && (ev.data.riq_techStatus == 0 || ev.data.riq_commRejected) ||
        (ev.column.dataField == 'riq_techStatus' && ev.data.riq_techStatus == 0) ||
        (ev.column.dataField == 'riq_commRejected' && ev.data.riq_commRejected))
      && !ev.cellElement.classList.hasOwnProperty('red-txt')) {
      ev.cellElement.classList.add('red-txt');
    }
    if (ev.rowType == 'data' && ev.column.dataField == 'riq_isWinner' && ev.data.riq_isWinner
      && !ev.cellElement.classList.hasOwnProperty('blue-txt')) {
      ev.cellElement.classList.add('blue-txt');
    }
  }

  totalItem = (row: any) => {
    var val = row.ri_quantity && row.riq_itemPrice ? (row.ri_quantity * row.riq_itemPrice) : 0;
    if (row.riq_exchangeRate) {
      val = val * row.riq_exchangeRate;
    }
    return Math.round(val * 100) / 100;
  }

  calcUnitPrice = (row: any) => {
    var val = row.riq_itemPrice || 0;
    if (row.riq_exchangeRate) {
      val = val * row.riq_exchangeRate;
    }
    return Math.round(val * 100) / 100;
  }

  markWinnerVisible = (ev: any) => {
    return ev.row.data && !ev.row.data.riq_isWinner &&
      [18, 20].includes(this.red.model.statusId) && this.red.model.typeId >= 2;
  }
  unmarkWinnerVisible = (ev: any) => {
    return ev.row.data && ev.row.data.riq_isWinner && [18].includes(this.red.model.statusId) && this.red.model.typeId >= 2;
  }

  approveVisible = (ev: any) => {
    return ev.row.data && ev.row.data.riq_commRejected != false && !ev.row.data.riq_unavailable &&
      this.red.model.statusId == 15;
  }

  rejectVisible = (ev: any) => {
    return ev.row.data && ev.row.data.riq_commRejected != true && !ev.row.data.riq_unavailable &&
      this.red.model.statusId == 15;
  }

  attachClarificationVisible = (ev: any) => {
    return ev.row.data && ev.row.data.riq_techStatus == 2;
  }

  attachVisible = (ev: any) => {
    return ev.row.data && ev.row.data.fc_fileNo;
  }

}
