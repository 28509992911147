<dx-data-grid #gridRequestItemList [dataSource]="qs.rfqItems" [keyExpr]="qs.rfqModel.byMaterial ? 'm_id' : 'ris_id'" [height]="qs.gridHeight"
    (onToolbarPreparing)="toolbarPreparing($event)" [remoteOperations]='true' (onRowPrepared)="onRowPrep($event)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right" [width]="200">
        <dxi-button cssClass="add-quotation" [text]="L('ADD_QUOTATION')" [onClick]="onAddQuotation"
            [visible]="qs.commercialVisible"></dxi-button>
        <dxi-button cssClass="unavailable" [text]="L('UNAVAILABLE')" [onClick]="onUnavailable"
            [visible]="qs.unavailableVisible"></dxi-button>
    </dxi-column>

    <dxi-column [caption]='L("RN_NO")' dataField='ris_compusNo' dataType='string'></dxi-column>
    <dxi-column [caption]='L("MATERIAL_NAME")' dataField='m_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField='m_typeId' dataType='string' [width]="100">
        <dxo-lookup [dataSource]="ds.static.materialType" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("GROUP")' dataField='mg_description' dataType='string' [width]="150">
    </dxi-column>
    <dxi-column [caption]='L("REQUESTED_QUANTITY")' dataField='ris_quantity' dataType='number'></dxi-column>
    <dxi-column [caption]='L("UOM")' dataField='uom_description' dataType='string' [width]="100"
        [visible]="!qs.party.enLanguage"></dxi-column>
    <dxi-column [caption]='L("UOM")' dataField='uom_descriptionEng' dataType='string' [width]="100"
        [visible]="qs.party.enLanguage"></dxi-column>

</dx-data-grid>