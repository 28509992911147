import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';
import { SupplierSelectService } from '../supplier-select/supplier-select.service';

@Injectable({
  providedIn: 'root'
})
export class RfqNewService extends iBisSecureEditService {
  dsSelectedItems: any;
  accordionInst: any;
  constructor(auth: iBisAuthService, lg: iBisLanguageService, entity: iBisEntityService,
    config: iBisServerConfig, private router: Router, private ss: SupplierSelectService) {
    super(auth, entity, config, lg);
    this.title = this.L('REQUEST_FOR_OFFER');
    this.validation = 'rfqValidation';
  }

  protected load(): void {
  }

  createDefault() {
    if (this && this.ss) {
      this.ss.selectedSupplierKeys = [];
    }
    return {};
  }
  getById(data: any, serverUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data.items && data.items.length) {
        if (this.accordionInst) {
          this.accordionInst.option('selectedIndex', 0);
        }
        this.entity.load(new EntityQuery('RFQItemView').in('ri_id', data.items).addOrderByDesc(['ri.compusNo'])).then(respData => {
          this.dsSelectedItems = respData;
        })
      }
      resolve(data)
    })
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.model.party = this.ss.selectedSupplierKeys;
      if (this.model.party.length == 0) {
        notify(this.L('SUPPLIER_UNDEFINED'), 'error', 3000);
        reject(this.L('SUPPLIER_UNDEFINED'));
        return;
      }
      if (this.model.dueDate) {
        this.model.dueDate = this.entity.toDateTimeFilter(new Date(this.model.dueDate));
      }
      this.entity.execute('SaveRFQ', this.model).then(resp => {
        resolve(resp);
        if (this.model.redirect) {
          this.router.navigateByUrl('/rfq-edit/' + resp);
        }
      }, err => {
        reject(err);
      })
    })
  }
  reset(): void {
    this.model = this.createDefault();
  }


  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    // const ti = event.component.option('toolbarItems');
    let ti = [{
      location: "before",
      options: {
        text: this.L('SAVE'),
        icon: 'save',
        type: 'normal',
        validationGroup: this.validation,
        onClick: (ev: any) => {
          this.model.redirect = false;
          this.onSaveClose(ev);
        }
      },
      toolbar: "bottom",
      widget: "dxButton"
    }, {
      location: "after",
      options: {
        text: this.L('SAVE_AND_OPEN'),
        icon: 'save',
        validationGroup: this.validation,
        onClick: (ev: any) => {
          this.model.redirect = true;
          this.onSaveClose(ev);
        }
      },
      toolbar: "bottom",
      widget: "dxButton"
    }];
    event.component.option('toolbarItems', ti);
    event.component.option('width', '90vw');
    event.component.endUpdate();
  }

}
