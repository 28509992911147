import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RfqListComponent } from './rfq-list/rfq-list.component';
import { RfqItemListComponent } from './rfq-item-list/rfq-item-list.component';
import { RfqEditComponent } from './rfq-edit/rfq-edit.component';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxFormModule  } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { RfqEditService } from './rfq-edit/rfq-edit.service';
import { BrowserModule } from '@angular/platform-browser';
import { RfqService } from './rfq.service';
import { RouterModule } from '@angular/router';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { iBisAuthModule } from '@dohu/ibis-auth';
import { iBisCommonModule } from '@dohu/ibis-common';
import { RfqNewComponent } from './rfq-new/rfq-new.component';
import { RfqNewService } from './rfq-new/rfq-new.service';
import { RfqEditItemListComponent } from './rfq-edit/rfq-edit-item-list/rfq-edit-item-list.component';
import { RfqEditSupplierComponent } from './rfq-edit/rfq-edit-supplier/rfq-edit-supplier.component';
import { RfqEditSupplierAddComponent } from './rfq-edit/rfq-edit-supplier/rfq-edit-supplier-add/rfq-edit-supplier-add.component';
import { RfqEditSupplierAddService } from './rfq-edit/rfq-edit-supplier/rfq-edit-supplier-add/rfq-edit-supplier-add.service';
import { SupplierSelectComponent } from './supplier-select/supplier-select.component';
import { SupplierSelectService } from './supplier-select/supplier-select.service';
import { NavRfqListComponent } from './rfq-list/nav-rfq-list/nav-rfq-list.component';
import { NavRfqItemListComponent } from './rfq-item-list/nav-rfq-item-list/nav-rfq-item-list.component';
import { AdminModule } from '../admin/admin.module';
import { CommonCompModule } from '../common/common.module';
import { RfqEditQuotationComponent } from './rfq-edit/rfq-edit-quotation/rfq-edit-quotation.component';
import { AgreementModule } from '../agreement/agreement.module';
import { RfqEditAuditComponent } from './rfq-edit/rfq-edit-audit/rfq-edit-audit.component';
import { RfqEditOrderSelectComponent } from './rfq-edit/rfq-edit-order-select/rfq-edit-order-select.component';
import { RfqItemListService } from './rfq-item-list/rfq-item-list.service';
import { RfqEditStatusComponent } from './rfq-edit/rfq-edit-status/rfq-edit-status.component';
import { DxLoadPanelModule, DxPivotGridModule, DxTagBoxModule } from 'devextreme-angular';
import { OrderModule } from '../order/order.module';
import { RfqEditDetailsComponent } from './rfq-edit/rfq-edit-details/rfq-edit-details.component';
import { RfqEditAddManualQuotationComponent } from './rfq-edit/rfq-edit-add-manual-quotation/rfq-edit-add-manual-quotation.component';
import { RfqDetailsComponent } from './rfq-list/rfq-details/rfq-details.component';
import { RfqEditEmailNotificationComponent } from './rfq-edit/rfq-edit-email-notification/rfq-edit-email-notification.component';
import { RfqEditItemListPriceHistoryComponent } from './rfq-edit/rfq-edit-item-list/rfq-edit-item-list-price-history/rfq-edit-item-list-price-history.component';
import { EmailDetailsComponent } from './rfq-edit/rfq-edit-email-notification/email-details/email-details.component';
import { RfqEditSummaryComponent } from './rfq-edit/rfq-edit-summary/rfq-edit-summary.component';
import { RfqEditClarificationComponent } from './rfq-edit/rfq-edit-clarification/rfq-edit-clarification.component';
import { RfqEditItemListTechnicalSelectionComponent } from './rfq-edit/rfq-edit-item-list/rfq-edit-item-list-technical-selection/rfq-edit-item-list-technical-selection.component';
import { RfqEditTechUserComponent } from './rfq-edit/rfq-edit-quotation/rfq-edit-tech-user/rfq-edit-tech-user.component';

@NgModule({
  declarations: [
    RfqListComponent,
    RfqItemListComponent,
    RfqEditComponent,
    RfqNewComponent,
    RfqEditItemListComponent,
    RfqEditSupplierComponent,
    RfqEditSupplierAddComponent,
    SupplierSelectComponent,
    NavRfqListComponent,
    NavRfqItemListComponent,
    RfqEditQuotationComponent,
    RfqEditAuditComponent,
    RfqEditOrderSelectComponent,
    RfqEditStatusComponent,
    RfqEditDetailsComponent,
    RfqEditAddManualQuotationComponent,
    RfqDetailsComponent,
    RfqEditEmailNotificationComponent,
    RfqEditItemListPriceHistoryComponent,
    EmailDetailsComponent,
    RfqEditSummaryComponent,
    RfqEditClarificationComponent,
    RfqEditItemListTechnicalSelectionComponent,
    RfqEditTechUserComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    iBisAuthModule,
    iBisCommonModule,
    iBisNavigationModule,
    
    AdminModule,
    CommonCompModule,
    AgreementModule,
    OrderModule,

    DxDataGridModule,
    DxTabsModule,
    DxTreeListModule,
    DxAccordionModule,
    DxProgressBarModule,
    DxFormModule,
    DxListModule,
    DxButtonModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxTreeListModule,
    DxDrawerModule,
    DxScrollViewModule,
    DxLoadPanelModule,
    DxTagBoxModule,
    DxPivotGridModule

  ],
  exports: [
    RfqListComponent,
    RfqItemListComponent,
    RfqEditComponent,
    RfqEditItemListComponent,
    RfqEditSupplierComponent,
    RfqEditSupplierAddComponent,
    SupplierSelectComponent,
    NavRfqListComponent,
    NavRfqItemListComponent,
    RfqEditDetailsComponent
  ],
  providers: [RfqService, RfqEditService, RfqNewService, RfqItemListService, 
        RfqEditSupplierAddService, SupplierSelectService]
})
export class RfqModule { }
