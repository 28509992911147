import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { NotifySupplierEditService } from 'src/app/common/notify-supplier/notify-supplier-edit.service';
import { SendNotificationService } from 'src/app/common/send-notification/send-notification.service';
import { DataService } from 'src/app/services';
import { RfqService } from '../../rfq.service';
import { RfqEditService } from '../rfq-edit.service';

@Component({
  selector: 'app-rfq-edit-details',
  templateUrl: './rfq-edit-details.component.html',
  styleUrls: ['./rfq-edit-details.component.scss']
})
export class RfqEditDetailsComponent extends iBisBaseComponent implements OnInit {

  @Input() collapsed: boolean = false;
  private _rfqModelClone: any = {};
  constructor(public override edit: RfqEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public rfq: RfqService, public ds: DataService, public auth: iBisAuthService, public sns: SendNotificationService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
  }

  formOptionChanged(ev: any) {
    if (ev.name == 'formData' && ev.value) {
      this._rfqModelClone = { ...ev.value };
    }
  }

  formFieldChanged(ev: any) {
    if (this._rfqModelClone[ev.dataField] != ev.value) {
      const obj: any = { id: this.edit.model.id };
      obj[ev.dataField] = ev.value;
      this.edit.onSaveRFQ(obj).then(() => {
        this._rfqModelClone[ev.dataField] = ev.value;
        notify(this.L('SUCCESS'), 'success', 3000);
      })
    }
  }

}
