import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { SupplierSelectService } from '../../../../../app/rfq/supplier-select/supplier-select.service';

@Injectable({
  providedIn: 'root'
})
export class RfqEditSupplierAddService extends iBisEditService {
  supplier!: CustomStore;
  constructor(lg: iBisLanguageService, private entity: iBisEntityService, private ss: SupplierSelectService) {
    super(lg);
    this.title = this.L('SUPPLIER_SYSTEM_LIST');
  }
  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.supplier = this.getPartyExcept(id);
      resolve({ id: id });
    });
  }
  onRemove(id: string): Promise<void> {
    return this.entity.remove("RFQParty", id);
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.ss.selectedSupplierKeys.length > 0) {
        this.model.party = this.ss.selectedSupplierKeys;
        this.entity.execute('SaveRFQ', this.model).then((resp: any) => { resolve(resp); }, err => { reject(err); });
      } else {
        notify(this.L('NO_SUPPLIER_SELECTED'), 'error', 3000);
      }
    });
  }
  reset(): void {
    this.model = this.createDefault();
  }

  public supplierItems(partyId: string, rfqId: string) {
    return this.entity.store(new EntityQuery('RFQItemQuoteView').eq('riq.partyId', partyId).eq('ri.rfqId', rfqId).addOrderBy(['m.name']).addOrderByDesc(['riq.score']), false, 'ri_id');
  }

  // test function (to be deleted)
  public getRFQParty(partyId: string, rfqId: string) {
    return this.entity.single(new EntityQuery('RFQParty').eq('partyId', partyId).eq('rfqId', rfqId));
  }

  public setPartyWinner(rfqPartyId: string) {
    return this.entity.update('RFQParty', rfqPartyId, { isWinner: true });
  }

  private getPartyExcept(rfqId: string) {
    const exceptQuery = new EntityQuery('Party').linkEq('id', 'partyId', 'RFQParty', 'rfqId', rfqId);
    return this.entity.store(new EntityQuery('Party').eq('isActiv', true).except(exceptQuery).addOrderBy(['name']), false, 'id');
  }

}
