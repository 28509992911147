<dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="2">
    <dxi-item [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [editorOptions]="{}" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('CODE')}" dataField="code" editorType="dxTextBox" [editorOptions]="{ readOnly: true }">
    </dxi-item>
    <dxi-item [label]="{text: L('SEARCH_KEY')}" dataField="searchKey" editorType="dxTextBox" [editorOptions]="{ }">
    </dxi-item>
    <dxi-item [label]="{text: L('PHYSICAL_ADDRESS')}" dataField="address" editorType="dxTextArea" [editorOptions]="{  }"
        [colSpan]="2">
    </dxi-item>
    <dxi-item [label]="{text: L('CIF')}" dataField="cif" editorType="dxTextBox" [editorOptions]="{}"></dxi-item>
    <dxi-item [label]="{text: L('REG_NO')}" dataField="regNo" editorType="dxTextBox" [editorOptions]="{}"></dxi-item>
    <dxi-item [label]="{text: L('PHONE')}" dataField="phone" editorType="dxTextBox" [editorOptions]="{}"></dxi-item>
    <dxi-item [label]="{text: L('CURRENCY')}" dataField="currencyId" editorType="dxSelectBox" [editorOptions]="{
        dataSource: edit.currency, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
    <dxi-item [label]="{text: L('ALTERNATIVE_CONTACT')}" dataField="alternativeContact" editorType="dxTextBox"
        [editorOptions]="{}"></dxi-item>
        <dxi-item [label]="{text: L('RATING_SUPPLIER')}" dataField="rating" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: ds.static.rating, displayExpr: 'id', valueExpr: 'id'}"></dxi-item>
    <dxi-item [label]="{text: L('EMAIL_ADDRESS')}" dataField="emailAddress" editorType="dxTextArea" [editorOptions]="{}"
        [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('IS_ACTIV')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{}">
    </dxi-item>
    <dxi-item [label]="{text: L('INTERNATIONAL_LANGUAGE')}" dataField="enLanguage" editorType="dxCheckBox"
        [editorOptions]="{}">
    </dxi-item>
</dx-form>