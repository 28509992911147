<dx-data-grid [dataSource]="edit.data" keyExpr="riq_id" [height]="500" (onToolbarPreparing)="toolbarPreparing($event, false)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	
    <dxi-column [caption]='L("SUPPLIER")' dataField="p_name" dataType="string"></dxi-column>
    <dxi-column [caption]='L("OFFER_DATE")' dataField="eti_created" dataType="date"></dxi-column>
    <dxi-column [caption]='L("QUANTITY")' dataField="riq_quantity" dataType="number"></dxi-column>
    <dxi-column [caption]='L("INTRODUCED_PRICE")' dataField="riq_itemPrice" dataType="number"></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField="riq_currencyId" dataType="string">
        <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name" ></dxo-lookup>
    </dxi-column>
</dx-data-grid>
