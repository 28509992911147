<dx-data-grid [dataSource]='dsEnumType' keyExpr="id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true'>

    <dxo-editing mode="row" [allowAdding]="auth.tabAccess.edit" [allowUpdating]="auth.tabAccess.edit"
        [allowDeleting]="auth.tabAccess.delete"></dxo-editing>
    <dxo-master-detail [enabled]='true' template='detail'></dxo-master-detail>

    <dxi-column [caption]='L("CODE")' dataField='code' dataType='string'></dxi-column>
    <dxi-column [caption]='L("NAME")' dataField='name' dataType='string'></dxi-column>

    <div *dxTemplate='let data of "detail"'>
        <dx-data-grid [remoteOperations]='true' [height]="ui.detailsfullHeight"
            (onToolbarPreparing)='toolbarPreparing($event, false)' (onRowInserting)='onInsertNewRow($event, data.data)'
            (onContentReady)='onGridInitialized($event, data.data)'>

            <dxo-editing mode="row" [allowAdding]='true' [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>
            <dxi-column [caption]='L("INDEX")' dataField='orderIndex' dataType='number'></dxi-column>
            <dxi-column [caption]='L("NAME")' dataField='name' dataType='string'></dxi-column>
            <dxi-column [caption]='L("DESCRIPTION")' dataField='description' dataType='string'></dxi-column>
        </dx-data-grid>
    </div>

</dx-data-grid>