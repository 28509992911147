<dx-data-grid class="grid-container box-shadow" [dataSource]="edit.orderHeader" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>


    <dxi-column [caption]='L("BUYER")' dataField='oh_userId' dataType='string'>
      <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField='r_typeId' dataType='number'>
        <dxo-lookup [dataSource]="ds.static.rfqType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER_NO")' dataField='supplierNo' dataType='number'></dxi-column>
    <dxi-column [caption]='L("SUPPLIER_ITEM_NO")' dataField='supplierItemNo' dataType='number'></dxi-column>
    <dxi-column [caption]='L("WINNER")' dataField='p_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ORDER_NO_SAP")' dataField='oh_sapRefNumber' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ORDER_DATE")' dataField='oh_orderDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='oh_currencyId' dataType='string'>
        <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("ORDER_VALUE")' dataField='value' dataType='number'></dxi-column>
    <dxi-column [caption]='L("PURCHASE_ORDER_DETAILS")' dataField='details' dataType='string'></dxi-column>

</dx-data-grid>

