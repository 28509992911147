import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { HumanTimePipe } from './pipe/human-time.pipe';
import { SingleCardComponent } from './single-card/single-card.component';
import { QuotationItemEditService } from './quotation-item-edit/quotation-item-edit.service';
import { QuotationItemEditComponent } from './quotation-item-edit/quotation-item-edit.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { NotifySupplierEditComponent } from './notify-supplier/notify-supplier-edit.component';
import { NotifySupplierEditService } from './notify-supplier/notify-supplier-edit.service';
import { ModalFileComponent } from './modal-file/modal-file.component';
import { ModalFileService } from './modal-file/modal-file.service';
import { iBisCommonModule } from '@dohu/ibis-common';
import { NotificationsComponent } from './notifications/notifications.component';
import { DxSwitchModule } from 'devextreme-angular';
import { iBisWSModule } from '@dohu/ibis-ws';
import { ClarificationResponseComponent } from './clarification-response/clarification-response.component';

@NgModule({
  declarations: [
    HumanTimePipe,
    SingleCardComponent,
    QuotationItemEditComponent,
    SendNotificationComponent,
    ModalFileComponent,
    NotifySupplierEditComponent,
    NotificationsComponent,
    ClarificationResponseComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    iBisCommonModule,
    iBisWSModule,
    DxScrollViewModule,
    DxFormModule,
    DxDataGridModule,
    DxSwitchModule,
  ],
  exports: [
    HumanTimePipe,
    SingleCardComponent,
    QuotationItemEditComponent,
    SendNotificationComponent,
    NotifySupplierEditComponent,
    ModalFileComponent,
    NotificationsComponent,
    ClarificationResponseComponent
  ],
  providers: [QuotationItemEditService, ModalFileService, NotifySupplierEditService]
})
export class CommonCompModule { }
