import { Component, ViewEncapsulation } from '@angular/core';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqService } from '../../rfq.service';

@Component({
  selector: 'app-nav-rfq-list',
  templateUrl: './nav-rfq-list.component.html',
  styles: ['.list-tab { max-width: calc(100vw - 200px);margin-top:3px }'],
  encapsulation: ViewEncapsulation.None
})
export class NavRfqListComponent extends iBisBaseComponent {

  constructor(lg: iBisLanguageService, iui: iBisUIService, public rfq: RfqService) {
    super((<any>null), lg, iui, (<any>null))
  }
}
