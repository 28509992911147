<dx-form [formData]="edit.model" [colCount]="4">
    <dxi-item [label]="{text: L('CONTRACT_REF_NUMBER')}" dataField="refNumber" editorType="dxTextBox" [editorOptions]="{  }"
        [colSpan]="1">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('TITLE') }" dataField="title" editorType="dxTextBox" [colSpan]="3"></dxi-item>
    <dxi-item [label]="{text: L('SUPPLIER') }" dataField="partyId" editorType="dxSelectBox" [colSpan]="4"
        [editorOptions]="{ dataSource: edit.partyActiv, valueExpr: 'id', displayExpr: 'name', 
        searchEnabled: true, searchExpr: ['searchKey', 'name', 'code'], readOnly: edit.model.rfqId }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('VALUE') }" dataField="value" editorType="dxNumberBox" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('CURRENCY') }" dataField="currencyId" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: edit.currency, valueExpr: 'id', displayExpr: 'name' }" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('FROM_DATE') }" dataField="fromDate" editorType="dxDateBox" [colSpan]="2"
        [editorOptions]="{displayFormat: 'dd.MM.yyyy'}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('THRU_DATE') }" dataField="thruDate" editorType="dxDateBox" [colSpan]="2"
        [editorOptions]="{displayFormat: 'dd.MM.yyyy'}"></dxi-item>
</dx-form>