import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import { PartyEditService } from './party-edit.service';

@Component({
  selector: 'app-party-edit',
  templateUrl: './party-edit.component.html'
})
export class PartyEditComponent extends iBisBaseComponent implements OnInit {

  constructor(public override edit: PartyEditService, lg: iBisLanguageService, iui: iBisUIService, public ds: DataService) {
    super(edit, lg, iui, <any>null);
  }

  ngOnInit(): void {
  }

}
