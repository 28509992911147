import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { AgreementEditService } from './agreement-edit.service';

@Component({
  selector: 'app-agreement-edit',
  templateUrl: './agreement-edit.component.html'
})
export class AgreementEditComponent extends iBisBaseComponent {

  constructor(public override edit: AgreementEditService, lg: iBisLanguageService,
    iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route)
  }

}
