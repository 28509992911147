<dx-data-grid #clarificationGrid [dataSource]="clarification" [height]="qs.gridHeight"
    (onToolbarPreparing)="toolbarPreparing($event, false)" [wordWrapEnabled]="true">
    <dxi-column [caption]='L("SUPPLIER_SUBJECT")' cellTemplate="subjectTemplate">
        <div *dxTemplate="let row of 'subjectTemplate'">
            <p>{{row.data.subject}}</p>
            <p class="mini">{{L("CREATED")}}: {{row.data.created | date:'dd.MM.yyyy HH:mm'}}</p>
            <div class="flex-column">
                <a *ngFor="let file of getFiles(clarificationFiles, row.data.id)" href="#"
                    (click)="openFile(file.id, file.name)">
                    <i class="dx-icon dx-icon-file" style="font-size: 14px;margin-right: 10px;"></i>
                    <span>{{file.name}}</span>
                </a>
            </div>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("PURCHASE_RESPONSE")' cellTemplate="responseTemplate">
        <div *dxTemplate="let row of 'responseTemplate'">
            <p>{{row.data.response}}</p>
            <p *ngIf="row.data.responseAt" class="mini">{{L("CREATED")}}: {{row.data.responseAt | date:'dd.MM.yyyy HH:mm'}}</p>
            <div class="flex-column">
                <a *ngFor="let file of getFiles(clarificationResponseFiles, row.data.responseFileId)" href="#"
                    (click)="openFile(file.id, file.name)">
                    <i class="dx-icon dx-icon-file" style="font-size: 14px;margin-right: 10px;"></i>
                    <span>{{file.name}}</span>
                </a>
            </div>
        </div>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false">
    <app-add-clarification></app-add-clarification>
</ibis-edit-popup>