<dx-data-grid #gridQuotation [dataSource]="red.rfqItemQuote" keyExpr="riq_id" [remoteOperations]='false'
    [height]='red.gridHeight' [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true"
    (onCellPrepared)="cellPrepared($event)" [rowAlternationEnabled]="true" [paging]="{ enabled: false }"
    (onToolbarPreparing)="toolbarPreparing($event, false)" (onExporting)="onExporting($event)">

    <dxo-scrolling [mode]="'standard'" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-export [enabled]="true" fileName="Cotatii_RFQ_{{red.model.refNumber}}"></dxo-export>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button [onClick]="openQuotationDetails" icon="info"></dxi-button>
        <dxi-button [visible]="approveVisible" icon="check" [hint]="L('APPROVE')" [onClick]='onApproveQuotation'>
        </dxi-button>
        <dxi-button [visible]="rejectVisible" icon="close" [hint]="L('REJECT')" [onClick]='onRejectQuotation'>
        </dxi-button>
        <dxi-button [visible]="markWinnerVisible" icon="fa-solid fa-trophy" [onClick]="onWinnerMark"></dxi-button>
        <dxi-button [visible]="unmarkWinnerVisible" icon="close" [onClick]="onUnwinnerMark"></dxi-button>

        <dxi-button icon="attach" [visible]="attachVisible" [onClick]="onAttachClick"></dxi-button>
        <dxi-button icon="tips" [onClick]="onAttachClarificationClick"
            [visible]="attachClarificationVisible"></dxi-button>
    </dxi-column>

    <dxi-column [caption]='L("WINNER")' dataField="riq_isWinner" dataType="boolean" [width]="100"></dxi-column>
    <dxi-column [caption]='L("SCORE")' dataField="riq_score" dataType="number"></dxi-column>
    <dxi-column [caption]='L("RN_NO")' dataField='ri_rnNo' dataType='string' [customizeText]="ds.stringZero"
        [groupIndex]="0"></dxi-column>
    <dxi-column [caption]='L("RN_POS_NO")' dataField='ri_rnPosNo' dataType='string' [customizeText]="ds.stringZero"
        [groupIndex]="1"></dxi-column>
    <dxi-column [caption]='L("NAME")' cellTemplate="materialNameTemplate" [width]="300">
        <div *dxTemplate="let row of 'materialNameTemplate'" class="no-margin">
            <h3 [class.red-txt]="row.data.riq_unavailable">{{row.data.m_name}}</h3>
            <p>{{L("CODE")}}: <b>{{ds.stringZero({value: row.data.m_code} )}}</b></p>
            <p>{{L("ALTERNATE_MATERIAL_NAME")}}: <b
                    [title]="row.data.riq_alternateName">{{row.data.riq_alternateName}}</b>
            </p>
            <p>{{L("MANUFACTURER")}}: <b [title]="row.data.riq_manufacturer">{{row.data.riq_manufacturer}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("CODE")' dataField="m_code" dataType="string" [visible]="false"></dxi-column>
    <dxi-column [caption]='L("ALTERNATE_MATERIAL_NAME")' dataField="riq_alternateName" dataType="string"
        [visible]="false"></dxi-column>
    <dxi-column [caption]='L("MANUFACTURER")' dataField="riq_manufacturer" dataType="string" [visible]="false">
    </dxi-column>

    <dxi-column [caption]='L("SUPPLIER")' cellTemplate="supplierTemplate">
        <div *dxTemplate="let row of 'supplierTemplate'" class="no-margin">
            <p>{{L("SUPPLIER_NAME")}}: <b>{{row.data.p_name}}</b></p>
            <p>{{L("SUPPLIER_CODE")}}: <b>{{row.data.p_code}}</b></p>
            <p>{{L("RATING_SUPPLIER")}}: <b>{{row.data.p_rating}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER_NAME")' dataField="p_name" dataType="string" [visible]="false"></dxi-column>
    <dxi-column [caption]='L("SUPPLIER_CODE")' dataField="p_code" dataType="string" [visible]="false"></dxi-column>

    <dxi-column [caption]='L("TRANSPORT_VALUE")' dataField="rp_transportValue" dataType="number"
        [visible]="false"></dxi-column>

    <dxi-column [caption]='L("PRICE")' cellTemplate="priceTemplate">
        <div *dxTemplate="let row of 'priceTemplate'" class="no-margin">
            <p style="color:red">
                <span *ngIf="row.data.riq_originalPrice">{{L("INITIAL_PRICE")}}:
                    <b>{{row.data.riq_originalPrice}}</b></span>
                <span *ngIf="row.data.riq_originalPrice && row.data.rp_discountGranted == false"> / </span>
                <span *ngIf="row.data.rp_discountGranted == false">Nu acorda discount</span>
            </p>
            <p>{{L("UNIT_PRICE")}}: <b>{{row.data.riq_itemPrice}}</b></p>
            <p>{{L("CURRENCY")}}: <b>{{getCurrency(row.data.riq_currencyId)}}</b></p>
            <p>{{L("PAYMENT_DAYS")}}: <b>{{row.data.riq_paymentDays}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField="riq_currencyId" dataType="string" [visible]="false"></dxi-column>
    <dxi-column [caption]='L("PAYMENT_DAYS")' dataField="riq_paymentDays" dataType="number"
        [visible]="false"></dxi-column>

    <dxi-column [caption]='L("EXCHANGE_RATE")' dataField='riq_exchangeRate' dataType='number'></dxi-column>
    <dxi-column [caption]='L("UNIT_PRICE_RON")' cssClass="gray-bg" [calculateCellValue]="calcUnitPrice"></dxi-column>
    <dxi-column [caption]='L("TOTAL")' name="total_price" cssClass="gray-bg"
        [calculateCellValue]="totalItem"></dxi-column>
    <dxi-column [caption]='L("OBSERVATIONS")' dataField='riq_observations' dataType='string'></dxi-column>

    <dxi-column [caption]='L("APPROVE_TEMPLATE")' cellTemplate="approveTemplate" [width]="200">
        <div *dxTemplate="let row of 'approveTemplate'" class="no-margin">
            <p *ngIf="red.model.flowType == 1; else doubleTemplate">{{L("TECHNICAL_NOTE")}}:
                <a href="#" (click)="openTechUser($event, row.data.riq_id, row.data.ri_id)">
                    <b *ngIf="row.data.riq_techStatus == 0" class="red-txt">Respins</b>
                    <b *ngIf="row.data.riq_techStatus == 1" class="green-txt">Aprobat</b>
                    <b *ngIf="row.data.riq_techStatus == 2" class="blue-txt">Clarificari</b>
                    <b *ngIf="row.data.riq_techStatus == null" class="yellow-txt">In asteptare</b>
                </a>
            </p>
            <ng-template #doubleTemplate>
                <p>{{L("TECHNICAL_NOTE")}}:
                    <b *ngIf="row.data.rp_techStatus == 0" class="red-txt">Respins</b>
                    <b *ngIf="row.data.rp_techStatus == 1" class="green-txt">Aprobat</b>
                    <b *ngIf="row.data.rp_techStatus == 2" class="blue-txt">Clarificari</b>
                    <b *ngIf="row.data.rp_techStatus == null" class="yellow-txt">In asteptare</b>
                </p>
                <p>{{L("TECHNICAL_REJECTED_REASON")}}: <b>{{row.data.rp_rejectedReason}}</b>
            </ng-template>
            <p>{{L("COMMERCIAL_NOTE")}}:
                <b *ngIf="row.data.riq_commRejected" class="red-txt">Respins</b>
                <b *ngIf="row.data.riq_commRejected == false" class="green-txt">Aprobat</b>
                <b *ngIf="row.data.riq_commRejected == null" class="yellow-txt">In asteptare</b>
            </p>
            <p *ngIf="[0,2].includes(row.data.riq_techStatus)" style="white-space: pre-wrap;">
                <span [ngSwitch]="row.data.riq_techStatus">
                    <span *ngSwitchCase="0">{{L("REJECTED_REASON")}}</span>
                    <span *ngSwitchCase="2">{{L("CLARIFICATION_MSG")}}</span>
                </span>: <b>{{row.data.riq_rejectedReason}}</b>
            </p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("TECHNICAL_NOTE")' dataField="riq_techStatus" dataType="number" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("COMMERCIAL_NOTE")' dataField="riq_commRejected" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("REJECTED_REASON")' dataField="riq_rejectedReason" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("IS_ALTERNATE")' dataField='riq_isAlternate' dataType='boolean'></dxi-column>

    <dxi-column [caption]='L("QUANTITY")' cellTemplate="qtyTemplate">
        <div *dxTemplate="let row of 'qtyTemplate'" class="no-margin">
            <p>{{L("PROVIDED_QUANTITY")}}: <b>{{row.data.ri_quantity}}</b></p>
            <p>{{L("UOM")}}: <b>{{row.data.uom_name}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("PROVIDED_QUANTITY")' dataField="ri_quantity" dataType="number" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("UOM")' dataField="uom_name" dataType="string" [visible]="false"></dxi-column>

    <dxi-column [caption]='L("TERMS")' cellTemplate="termsTemplate">
        <div *dxTemplate="let row of 'termsTemplate'" class="no-margin">
            <p>{{row.data.m_typeId == 1 ? L("DELIVERY_DAYS") : L("EXECUTION_DAYS")}}:
                <b>{{row.data.riq_deliveryDays}}</b>
            </p>
            <p>{{L("WARRANTY_MONTHS")}}: <b>{{row.data.riq_warrantyMonths}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("DELIVERY_DAYS")' dataField="riq_deliveryDays" dataType="number" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("WARRANTY_MONTHS")' dataField="riq_warrantyMonths" dataType="number" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("DELIVERY_CONDITIONS")' dataField="rp_incoterm" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("PAYMENT_TERM")' dataField="rp_paymentDays" dataType="number" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("EXECUTION_DAYS")' dataField="rp_executionDays" dataType="number" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("TRANSPORT")' dataField="rp_transportState" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column [caption]='L("OBSERVATIONS")' dataField="rp_observations" dataType="string" [visible]="false">
    </dxi-column>

    <dxi-column [caption]='L("TERMS_OFFER")' cellTemplate="termsOfferTemplate">
        <div *dxTemplate="let row of 'termsOfferTemplate'" class="no-margin">
            <p>{{L("DELIVERY_CONDITIONS")}}: <b>{{getIncoTerms(row.data.rp_incoTerm)}}</b></p>
            <p>{{L("PAYMENT_TERM")}}: <b>{{row.data.rp_paymentDays}}</b> / {{L("EXECUTION_DAYS")}}:
                <b>{{row.data.rp_executionDays}}</b>
            </p>
            <p>{{L("TRANSPORT")}}: <b>{{getTransportState(row.data.rp_transportState)}}</b>
                <span *ngIf="row.data.rp_transportValue"> (<b>{{row.data.rp_transportValue}}
                        {{getCurrency(row.data.rp_transportCurrency)}})</b></span>
            </p>
            <p>{{L("OBSERVATIONS")}}: <b>{{row.data.rp_observations}}</b></p>
        </div>
    </dxi-column>

    <dxi-column [caption]='L("CREATED/MODIFIED")' cellTemplate="infoTemplate" [width]="250">
        <div *dxTemplate="let row of 'infoTemplate'" class="no-margin">
            <p *ngIf="row.data.eti_createdBy">Creat de: <b>{{getBuyer(row.data.eti_createdBy)}}</b></p>
            <p *ngIf="row.data.eti_created">Data crearii: <b>{{row.data.eti_created | date : 'dd.MM.YYYY HH:mm'}}</b>
            </p>
            <p *ngIf="row.data.eti_modifiedBy">Ultima modificare: <b>{{getBuyer(row.data.eti_modifiedBy)}}</b></p>
            <p *ngIf="row.data.eti_modified">Data modificarii: <b>{{row.data.eti_modified | date : 'dd.MM.YYYY HH:mm'}}</b></p>
        </div>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false" [width]="'90vw'">
    <app-quotation-item-edit [rfqItems]="red.rfqItemSum" [currency]="rqs.currency" [readOnly]="true"
        [priceItemSource]="red.priceItemSource" [dsPaymentMethods]="ds.static.paymentMethods">
    </app-quotation-item-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="techUser" [showAdd]="false">
    <app-rfq-edit-tech-user></app-rfq-edit-tech-user>
</ibis-edit-popup>