import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import { iBisEditService, iBisFileService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class RejectReasonService extends iBisEditService {

  public clarificationFileGridInst: any;
  constructor(public iBisFile: iBisFileService, lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L('REJECT_QUOTATION');
    this.validation = 'rejectReasonValidation';
  }
  createDefault() {
    return {}
  }
  getById(data: any, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve) => {
      if (data && data.techStatus == 2) {
        this.addToolbarBtn();
      } else {
        this.popup.beginUpdate();
        const ti = this.popup.option('toolbarItems');
        ti.splice(1, 1);
        this.popup.option('toolbarItems', ti);
        this.popup.endUpdate();
      }
      this.popup.repaint();
      resolve(data);
    })
  }
  addToolbarBtn() {
    this.popup.beginUpdate();
    const ti = this.popup.option('toolbarItems');
    if (ti.length == 1) {
      ti.push({
        location: "before",
        options: {
          text: this.L('UPLOAD_CLARIFICATION_FILE'),
          icon: 'upload',
          type: 'normal',
          onClick: (ev: any) => {
            this.iBisFile.upload(this.model.entityFileId).then(() => {
              notify(this.L('FILE_UPLOADED'), 'success', 2000);
              this.clarificationFileGridInst.refresh();
            });
          }
        },
        toolbar: "bottom",
        widget: "dxButton"
      });
    }
    this.popup.option('toolbarItems', ti);
    this.popup.endUpdate();
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    var obj: any = { rejectedReason: this.model.rejectedReason, quoteId: this.model.quoteId, techUserId: this.model.techUserId };
    if (this.model.commRejected != null) {
      obj.commRejected = this.model.commRejected;
    }
    if (this.model.techStatus != null) {
      obj.techStatus = this.model.techStatus;
    }
    return this.entity.save(this.model.name, this.model.id, obj);
  }
  reset(): void {
    this.model = this.createDefault();
  }
  updateTechParty(id: string, status: number, reason: string) {
    return this.entity.update('RFQParty', id, { techStatus: status, rejectedReason: reason });
  }
  updateTechStatusQuote(id: string, quoteId: string, techUserId: string, status: number, reason: string) {
    return this.entity.save('TechUserStatus', id, { quoteId, techUserId, techStatus: status, rejectedReason: reason })
  }

}
