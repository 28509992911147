import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services';
import { ReportBuyerEditService } from './report-buyer-edit.service';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';

@Component({
  selector: 'app-report-buyer',
  templateUrl: './report-buyer.component.html'
})
export class ReportBuyerComponent extends iBisViewComponent {

  constructor(public override edit: ReportBuyerEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public ds: DataService) {

    super(edit, lg, iui, route);
    this.key = 'buyerReportGrid';
  }

  displayDiscountBool = (rowInfo: any) => {
    return rowInfo.discountRequested ? "DA" : "NU";
  }

  customizeDiscountText = (ev: any) => {
    return ev.valueText ? (ev.valueText + '%') : '';
  }
}
