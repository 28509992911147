<app-order-header-edit></app-order-header-edit>

<dx-data-grid class="grid-container box-shadow" #orderEditGrid [dataSource]="oes.orderLine" keyExpr="ol_id" [height]="'calc(90vh - 385px)'"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='false'
    (onRowPrepared)="onLinePrepared($event)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button icon="info" [onClick]="onEditClick"></dxi-button>
        <dxi-button [visible]="auth.hasRole('support')" icon="trash" [onClick]="onDeleteClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]='L("ORDER_POSITION_NO")' dataField='ol_posNo' dataType='number'></dxi-column>
    <dxi-column [caption]='L("RN_NO")' dataField='ol_compusNo' dataType='string' [customizeText]="ds.string_Zero">
    </dxi-column>
    <dxi-column [caption]='L("MATERIAL_CODE")' dataField='m_code' dataType='string' [customizeText]="ds.stringZero">
    </dxi-column>
    <dxi-column [caption]='L("MATERIAL_NAME")' dataField='ol_materialDescription' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ALTERNATE_MATERIAL_NAME")' dataField='ol_alternateName' dataType='string'></dxi-column>
    <dxi-column [caption]='L("REQUESTER")' dataField='ol_requesterName' dataType='string'></dxi-column>
    <dxi-column [caption]='L("STATUS")' dataField='ol_isActiv' dataType='string'>
        <dxo-lookup [dataSource]="ds.static.statusPoLine" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("QUANTITY")' dataField='ol_quantity' dataType='number'></dxi-column>
    <dxi-column [caption]='L("UOM")' dataField='uom_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PER_UNIT")' dataField='ol_perUnit' dataType='number'></dxi-column>
    <dxi-column [caption]='L("PRICE")' dataField='ol_netPrice' dataType='number'></dxi-column>
    <dxi-column [caption]='L("TOTAL_PRICE")' name="total_price" [calculateCellValue]="totalItem" dataType='number'></dxi-column>
    <dxi-column [caption]='L("DELIVERY_DATE")' dataField='ol_deliveryDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("DEPARTMENT")' dataField='ol_department' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ACCOUNT")' dataField='ol_categoryAccount' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_NO")' dataField='ol_contractNo' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_POSITION_NO")' dataField='ol_contractPosNo' dataType='number'></dxi-column>
    <dxi-column [caption]='L("FIXED_ASSET")' dataField='ol_fixedAsset' dataType='string'></dxi-column>
    <dxi-column [caption]='L("FIXED_ASSET_CODE")' dataField='ol_fixedAssetCode' dataType='string'></dxi-column>
    <dxi-column [caption]='L("COST_CENTER")' dataField='ol_costCenter' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ORDER_REF_NO")' dataField='ol_orderRef' dataType='string'></dxi-column>
    <dxi-column [caption]='L("SDA")' dataField='ol_sda' dataType='string'></dxi-column>

    <dxo-summary>
        <dxi-total-item column='total_price' summaryType="sum"></dxi-total-item>
    </dxo-summary>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false">
    <app-order-line-edit></app-order-line-edit>
</ibis-edit-popup>