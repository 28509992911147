<dx-data-grid #gridTechApp [dataSource]="quotationList" keyExpr="ri_id" [height]="height" [remoteOperations]="false"
    (onToolbarPreparing)="toolbarPreparing($event)" (onCellPrepared)="cellPrepared($event)"
    [paging]="{ enabled: false }">

    <dxo-scrolling [mode]="'standard'" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column [width]="50" [fixed]="true" cellTemplate="statusTemplate">
        <div *dxTemplate="let row of 'statusTemplate'">
            <i class="dx-icon-check" *ngIf="row.data.tus_techStatus == 1" title="Aprobat"></i>
            <i class="dx-icon-clear" *ngIf="row.data.tus_techStatus == 0" title="Respins"></i>
            <i class="dx-icon-clock yellow-clock" title="În așteptare" *ngIf="row.data.tus_techStatus == null"></i>
            <i class="dx-icon-tips blue-tips" title="Solicitare clarificări" *ngIf="row.data.tus_techStatus == 2"></i>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER")' dataField='p_name' dataType='string' [groupIndex]="0"
        groupCellTemplate="supplierCellTemplate">
        <div *dxTemplate="let row of 'supplierCellTemplate'">
            <div style="display: flex">
                <div class="box" style="max-width: 400px">
                    <p>Furnizor: {{row.value}}</p>
                    <p *ngIf="row.data.items">IncoTerms: {{getIncoTerms(row.data.items[0].rp_incoTerm) || '-'}}</p>
                    <p *ngIf="row.data.items" style="white-space: break-spaces;">Observatii oferta:
                        {{row.data.items[0].rp_observations || '-'}}</p>
                </div>
                <div class="box" style="margin-left: 50px">
                    <p *ngIf="row.data.items">Nota furnizor: {{row.data.items[0]?.p_rating || '-'}}</p>
                    <p *ngIf="row.data.items">Termen plata: {{row.data.items[0].rp_paymentDays || '-'}}</p>
                    <p *ngIf="row.data.items">Termen executie: {{row.data.items[0].rp_executionDays || '-'}} </p>
                </div>
            </div>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("RN_POS_NO")' dataField='ri_compusNo' dataType='string'></dxi-column>
    <dxi-column [caption]='L("MATERIAL")' dataField='m_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("MANUFACTURER")' dataField='riq_manufacturer' dataType='string' [width]="200"></dxi-column>
    <dxi-column [caption]='L("REQUESTED_QUANTITY")' dataField='riq_quantity' dataType='number'>
    </dxi-column>
    <dxi-column [caption]='L("IS_ALTERNATE")' dataField='riq_isAlternate' dataType='boolean'></dxi-column>
    <dxi-column [caption]='L("ALTERNATE_MATERIAL_NAME")' dataField='riq_alternateName' dataType='string'
        [width]="300"></dxi-column>
    <dxi-column [caption]='L("SUPPLIER_OBSERVATIONS")' dataField='riq_observations' dataType='string'
        [width]="300"></dxi-column>
    <dxi-column [caption]='L("DELIVERY_DAYS")' dataField='riq_deliveryDays' dataType='number'></dxi-column>
    <dxi-column [caption]='L("WARRANTY_MONTHS")' dataField='riq_warrantyMonths' dataType='number'></dxi-column>
    <dxi-column [caption]='L("DOC_TYPE")' dataField='doc_description' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DEPARTMENT")' dataField='dep_description' dataType='string'></dxi-column>
    <dxi-column [caption]='L("REQUESTER")' dataField='ri_requesterName' dataType='string'></dxi-column>
    <dxi-column [caption]='L("REJECTED_REASON")' dataField='tus_rejectedReason' dataType='string'></dxi-column>

    <dxi-column type='buttons' [fixed]="true" fixedPosition="right">
        <dxi-button icon="dx-icon-tips blue-tips" [visible]="clarificationTipsVisible" [hint]="L('CLARIFICATION_FILES')"
            [onClick]='onClarificationClick'></dxi-button>
        <dxi-button icon="attach" [visible]="attachVisible" [onClick]='onAttachClick' [hint]="L('SUPPLIER_FILES')"></dxi-button>
        <dxi-button cssClass="approve-btn" [visible]="approveVisible" [text]="L('APPROVE')"
            [onClick]='onApproveQuotation'></dxi-button>
        <dxi-button cssClass="clarifications-btn" [visible]="clarificationVisible" [text]="L('CLARIFICATIONS')"
            [onClick]='onClarificationQuotation'>
        </dxi-button>
        <dxi-button cssClass="reject-btn" [visible]="rejectVisible" [text]="L('REJECT')" [onClick]='onRejectQuotation'>
        </dxi-button>
        <dxi-button [visible]="returnVisible" icon="return" [onClick]='onApproveQuotation'></dxi-button>
    </dxi-column>
</dx-data-grid>