import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgreementComponent } from './agreement.component';
import { AgreementEditComponent } from './agreement-edit/agreement-edit.component';
import { DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { iBisCommonModule } from '@dohu/ibis-common';



@NgModule({
  declarations: [
    AgreementComponent,
    AgreementEditComponent
  ],
  imports: [
    CommonModule,
    iBisCommonModule,
    DxDataGridModule,
    DxFormModule
  ],
  exports: [
    AgreementComponent,
    AgreementEditComponent
  ],
  providers: [AgreementEditComponent]
})
export class AgreementModule { }
