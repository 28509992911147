import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqService } from 'src/app/rfq/rfq.service';
import { DataService } from 'src/app/services';
import { EmailDetailsService } from './email-details.service';

@Component({
  selector: 'app-email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.scss']
})
export class EmailDetailsComponent extends iBisBaseComponent implements OnInit {


  constructor(edit: EmailDetailsService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,  public rfq: RfqService, public ds: DataService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
    
  }
  
}
