<dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="2">
    <dxi-item [label]="{text: L('MATERIAL_GROUP')}" dataField="groupId" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: edit.materialGroup, valueExpr: 'id', displayExpr: 'name',
        searchEnabled:true, searchExpr: ['name', 'description'] }" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('CODE')}" dataField="code" editorType="dxTextBox" [editorOptions]="{ readOnly: true }">
    </dxi-item>
    <dxi-item [label]="{text: L('TYPE')}" dataField="typeId" editorType="dxSelectBox" [editorOptions]="{
        dataSource: ds.static.materialType, valueExpr: 'id', displayExpr: 'name' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [colSpan]="2" [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('NAME_ENG')}" dataField="nameEng" editorType="dxTextBox" [colSpan]="2"
        [editorOptions]="{ }">
    </dxi-item>
    <dxi-item [label]="{text: L('UOM')}" dataField="uomId" editorType="dxSelectBox" [editorOptions]="{
        dataSource: edit.uom, valueExpr: 'id', displayExpr: 'name', searchEnabled:true, 
        searchExpr: ['name', 'description'] }">
    </dxi-item>
    <dxi-item [label]="{text: L('ACTIV')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{}">
    </dxi-item>
</dx-form>