<div *ngIf="closeEarlier()">
    <p class="txt-16">{{L('EARLY_TECHNICAL_SEND')}}<b>{{oldDueDate}}</b>?</p>
</div>
<dx-form [formData]="edit.model" [colCount]="2" [validationGroup]="edit.validation">
    <dxi-item [label]="{ text: L('LIMIT_DATE') }" [colSpan]="2" dataField="dueDate" editorType="dxDateBox"
        [editorOptions]="{
        displayFormat: 'dd.MM.yyyy HH:mm', type: 'datetime',min: currentDate }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text : L('PARTY_NOTES') }" dataField="partyNotes" editorType="dxTextArea"
        [editorOptions]="{ height: 300 }" [visible]="[5,12].includes(edit.model.statusId)">
    </dxi-item>
    <dxi-item [label]="{text : L('PARTY_NOTES_ENG') }" dataField="partyNotesEng" editorType="dxTextArea"
        [editorOptions]="{ height: 300 }" [visible]="[5,12].includes(edit.model.statusId)">
    </dxi-item>
    <dxi-item [label]="{text : L('TECHNICAL_NOTES') }" [colSpan]="2" dataField="technicalNotes" editorType="dxTextArea"
        [editorOptions]="{ height: 300 }" [visible]="[10].includes(edit.model.statusId)">
    </dxi-item>
</dx-form>