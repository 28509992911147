import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { iBisCommonModule, iBisFileService, iBisGridProperties, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { iBisEntityModule, iBisEntityService } from '@dohu/ibis-entity';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as labelDevEx from '../assets/devExtreme.json';
import * as label from '../assets/label.json';
import * as nav from '../assets/navigation.json';
import * as ui from '../assets/uiCustomize.json';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { iBisNavigationModule, iBisNavigationService } from '@dohu/ibis-navigation';
import { iBisAuthModule, iBisAuthService, iBisEditUserService } from '@dohu/ibis-auth';
import { iBisNotifyService, iBisWSModule } from '@dohu/ibis-ws';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DataService, ProxyService, ScreenService } from './services/index';
import { AdminModule } from './admin/admin.module';
import { BugetComponent } from './buget/buget.component';
import { ReportModule } from './report/report.module';
import { RfqModule } from './rfq/rfq.module';
import { CommonCompModule } from './common/common.module';
import { RouterModule } from '@angular/router';
import { QuotationModule } from './quotation/quotation.module';
import { OrderModule } from './order/order.module';
import { TechnicalModule } from './technical/technical.module';
import { AgreementModule } from './agreement/agreement.module';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './common/notifications/notifications.component';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';

@NgModule({
  declarations: [
    AppComponent,
    BugetComponent,
    ProfileComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,

    DxTabsModule,
    DxDataGridModule,
    DxPopupModule,
    DxFormModule,
    DxButtonModule,
    DxScrollViewModule,
    DxDrawerModule,
    DxPopoverModule,
    DxSwitchModule,

    CommonCompModule,
    RfqModule,
    OrderModule,
    AgreementModule,
    ReportModule,
    QuotationModule,
    TechnicalModule,
    AdminModule,

    iBisAuthModule,
    iBisEntityModule.forRoot({ key: 'id', server: environment.defaultUrlServer }),
    iBisCommonModule.forRoot({
      display: {},
      logo: 'assets/img/icon.png',
      lang: 'ro',
      label: [labelDevEx.default, label.default, {}],
      grid: ui.default.grid as iBisGridProperties,
      ui: ui.default.ui
    }, {
      saasServer: environment.saasServer,
      fileServer: environment.saasServer,
      authServer: environment.authServer,
      deployId: environment.deployId,
      useLocalStorage: true,
      skipLocation: false
    }),
    iBisNavigationModule.forRoot({
      navigation: nav.default,
      shortcut: nav.default.shortcut,
      ignorePath: nav.default.ignorePath,
      outer: true
    }),
    iBisWSModule.forRoot({
      server: environment.notifyServer,
      models: ['saas:Notify', 'saas:NotifyAssignee']
    })
  ],
  providers: [
    HttpClient,
    ScreenService,
    DataService,
    ProxyService,
    iBisAuthService,
    iBisGridProperties,
    iBisUIService,
    iBisFileService,
    iBisEntityService,
    iBisLanguageService,
    iBisEditUserService,
    iBisNotifyService,
    iBisNavigationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
