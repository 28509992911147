import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class AgreementEditService extends iBisSecureEditService {
  user!: any;
  agreement!: any;
  currency!: any;
  party!: any;
  partyActiv!: any;
  constructor(auth: iBisAuthService, lg: iBisLanguageService, entity: iBisEntityService, config: iBisServerConfig) {
    super(auth, entity, config, lg);
    this.title = this.L('SUPPLIER_AGREEMENT');
  }
  protected load(): void {
    this.agreement = this.entity.store(new EntityQuery('AgreementView').eq('ag.isActiv', true).addOrderBy(['p.name']), false, 'ag_id');
    this.party = this.entity.store(new EntityQuery('Party').push('id', 'code', 'name', 'searchKey').addOrderBy(['name']), false, 'id');
    this.partyActiv = this.entity.store(new EntityQuery('Party').eq('isActiv', true).push('id', 'code', 'name', 'searchKey').addOrderBy(['name']), false, 'id');
    this.user = this.entity.store(new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).addOrderBy(['u.fullName']), false, 'u_id', this.config.saasServer);
    this.currency = this.entity.store(new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'name', 'CURRENCY').addOrderBy(['orderIndex']), false, 'id', this.config.saasServer);
  }

  createDefault() {
    return { isActiv: true };
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return this.entity.getById('Agreement', id);
  }
  onRemove(id: string): Promise<void> {
    return this.entity.update('Agreement', id, { isActiv: false });
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return this.entity.save('Agreement', this.model.id, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }

}
