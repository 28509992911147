import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisEditService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class RfqEditItemListPriceHistoryService extends iBisSecureEditService {

  data!: DataSource;
  currency!: any;

  constructor(auth:iBisAuthService , lg: iBisLanguageService, entity: iBisEntityService, config: iBisServerConfig) {
    super(auth, entity, config, lg);
  }

  protected load() {
    this.currency = new DataSource(this.getCurrency());
  }

  createDefault() {
    return {};
  }

  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.data = new DataSource(this.getData(id));
      resolve({})
    })
  }

  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    throw new Error('Method not implemented.');
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    event.component.option('toolbarItems', []);
    event.component.endUpdate();
  }


  public getData(materialId: string): CustomStore {
    var q = new EntityQuery("RFQItemQuoteView").eq('ri.materialId', materialId).addOrderByDesc(['eti.created']);
    return this.entity.store(q, false, 'riq_id');
  }

  public getCurrency(): CustomStore {
    var q = new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'name', 'CURRENCY').addOrderBy(['orderIndex']);
    return this.entity.store(q, false, 'id', this.config.saasServer);
  }

  
}
