import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisFileService, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { AddClarificationService } from './add-clarification.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-add-clarification',
  templateUrl: './add-clarification.component.html'
})
export class AddClarificationComponent extends iBisBaseComponent {
  apiUrl: string;

  constructor(public override edit: AddClarificationService, lg: iBisLanguageService,
    iui: iBisUIService, route: ActivatedRoute, private file: iBisFileService) {
    super(edit, lg, iui, route);
    this.apiUrl = environment.defaultUrlServer + 'Download/';
  }

  ngOnInit(): void {
  }

  openFile() {
    return false;
  }

  removeFile(fileName: string) {
    var f = this.edit.files.findIndex(x => x.name == fileName);
    if (f > -1) {
      this.edit.files.splice(f, 1);
    }
    return false;
  }

}
