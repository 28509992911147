import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { alert } from 'devextreme/ui/dialog';

@Injectable({
	providedIn: 'root'
})
export class QuotationItemEditService extends iBisSecureEditService {
	partyId!: string;
	rfqId!: string;
	popupInst: any;
	formInst: any;
	quotePriceItem: any[] = [];

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);
		this.title = this.L('ITEM_QUOTATION');
		this.validation = 'itemQuotationValidation';
	}

	protected load(): void {
	}

	createDefault() {
		return { partyId: this.partyId, rfqId: this.rfqId, isAlternate: false, perUnit: 1 }
	}
	getById(id: string, serverUrl?: string | undefined): Promise<any> {
		this.refreshPopupToolbar(this.popupInst, true);
		// folosita doar la deschidere Achizitii
		this.quotePriceItem = [];
		this.entity.load(new EntityQuery('QuotePrice').eq('quoteId', id).addOrderBy(['orderIndex'])).then(data => {
			if (data && data.length) {
				this.quotePriceItem = data;
			}
		});
		return this.entity.getById('RFQItemQuote', id, 'id', undefined, (item) => { item.paymentMethod = item.paymentDays == 0 ? 0 : 1; });
	}
	onRemove(id: string): Promise<void> {
		return this.entity.execute('RemoveRFQItemQuote', { id: id });
	}
	onSaveEv(serverUrl?: string | undefined): Promise<any> {
		this.partyId = this.model.partyId;
		this.rfqId = this.model.rfqId;
		this.model.quotePrice = this.quotePriceItem;
		return new Promise((resolve, reject) => {
			this.entity.execute('SaveRFQItemQuote', this.model).then(resp => {
				resolve(resp);
			}, err => {
				const msg = this.getMsgError(err);
				alert(msg, this.L('ALERT'));
				reject(err);
			});
		});
	}

	setUnavailableItem(quoteItem: any) {
		return this.entity.execute('SaveRFQItemQuote', quoteItem);
	}

	getMsgError(err: string) {
		switch (err) {
			case "CP105":
				return "Cotația nu a putut fi adăugată. Termenul pentru depunere este depașit sau alte condiții nu sunt îndeplinite.";
			case "CP106":
				return "Prețul introdus nu poate fi 0.";
			default:
				return err;
		}
	}
	reset(): void {
		this.model = this.createDefault();
	}

	updateComm(qouteId: string, rejected: boolean) {
		return this.entity.update('RFQItemQuote', qouteId, { commRejected: rejected });
	}

	override popupInit(event: any): void {
		super.popupInit(event);
		this.popupInst = event.component;
		this.refreshPopupToolbar(event.component, false);
	}

	public override showPopup(data: any, byId?: boolean, serverUrl?: string): Promise<any> {
		this.refreshPopupToolbar(this.popup, byId);
		return super.showPopup(data, byId, serverUrl);
	}

	private refreshPopupToolbar(popupInstance: any, id?: boolean): void {
		popupInstance.beginUpdate();
		let tbBtns = [{}];
		if (!id) {
			tbBtns = [{
				location: 'after',
				options: {
					text: this.L('SAVE'),
					icon: 'fas fa-save',
					validationGroup: this.validation,
					type: 'default',
					onClick: (ev: any) => {
						this.formInst.getEditor("observations").focus();
						setTimeout(() => {
							this.onSaveClose(ev);
						}, 100);
					}
				},
				toolbar: 'bottom',
				widget: 'dxButton'
			}];
		}
		popupInstance.option('toolbarItems', tbBtns);
		popupInstance.endUpdate();
	}
}
