<dx-data-grid #agreementGrid class="grid-container box-shadow" [dataSource]='edit.agreement' keyExpr="ag_id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, true)' [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button icon="edit" [onClick]="onEditClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]='L("CONTRACT_REF_NUMBER")' dataField='ag_refNumber' dataType='string'></dxi-column>
    <dxi-column [caption]='L("TITLE")' dataField='ag_title' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PARTY_NAME")' dataField='p_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("VALUE")' dataField='ag_value' dataType='number'></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='ag_currencyId' dataType='string'>
        <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("FROM_DATE")' dataField='ag_fromDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("THRU_DATE")' dataField='ag_thruDate' dataType='date'></dxi-column>

    <dxi-column [caption]='L("CREATED")' dataField='eti_created' dataType='date'></dxi-column>
    <dxi-column [caption]='L("CREATED_BY")' dataField='eti_createdBy' dataType='string'>
        <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("MODIFIED")' dataField='eti_modified' dataType='date'></dxi-column>
    <dxi-column [caption]='L("MODIFIED_BY")' dataField='eti_modifiedBy' dataType='string'>
        <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>

</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false">
    <app-agreement-edit></app-agreement-edit>
</ibis-edit-popup>