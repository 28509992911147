<dx-data-grid #gridReqList [dataSource]="requestList" keyExpr="ri_id" [height]="height"
    (onToolbarPreparing)="toolbarPreparing($event)" (onCellPrepared)="cellPrepared($event)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="requestListGrid">
    </dxo-state-storing>

    <dxi-column [caption]='L("SUPPLIER")' dataField='p_name' dataType='string' [groupIndex]="0"
        groupCellTemplate="supplierCellTemplate">
        <div *dxTemplate="let row of 'supplierCellTemplate'">
            <div style="display: flex">
                <div class="box" style="max-width: 400px">
                    <p>Furnizor: {{row.value}}</p>
                    <p *ngIf="row.data.items">IncoTerms: {{getIncoTerms(row.data.items[0].rp_incoTerm) || '-'}}</p>
                    <p *ngIf="row.data.items" style="white-space: break-spaces;">Observatii oferta:
                        {{row.data.items[0].rp_observations || '-'}}</p>
                </div>
                <div class="box" style="margin-left: 50px">
                    <p *ngIf="row.data.items">Termen plata: {{row.data.items[0].rp_paymentDays || '-'}}</p>
                    <p *ngIf="row.data.items">Termen executie: {{row.data.items[0].rp_executionDays || '-'}} </p>
                    <p [ngStyle]="{ 'color': getSupplierStatusColor(row.data.items[0].rp_techStatus) }"
                        *ngIf="row.data.items">Status tehnic:
                        {{getSupplierStatus(row.data.items[0])}}</p>
                </div>
                <div class="box" style="margin-left: 50px; display: flex; flex-direction: column;">
                    <div style="display:flex; justify-content: space-between; margin: 10px;">
                        <dx-button hint="Aproba acest furnizor"
                            [visible]="approvePartyVisible({row: {data: row.data.items[0] }})" [text]="L('APPROVE')"
                            (click)='onApproveQuotation({row: {data: row.data.items[0] }})' type="success"></dx-button>
                        <dx-button [visible]="rejectPartyVisible({row: {data: row.data.items[0] }})"
                            [text]="L('REJECT')" (click)='onRejectQuotation({row: {data: row.data.items[0] }})'
                            hint="Respinge acest furnizor" type="danger">
                        </dx-button>
                        <dx-button [visible]="returnPartyVisible({row: {data: row.data.items[0] }})" icon="return"
                            text="Întoarce" (click)='onApproveQuotation({row: {data: row.data.items[0] }})'
                            hint="Intoarce in aprobare"></dx-button>
                    </div>
                    <div style="display:flex; justify-content: space-between; margin: 10px; width: 280px;">
                        <dx-button icon="dx-icon-tips blue-tips"
                            [visible]="clarificationPartyAttachVisible({row: {data: row.data.items[0] }})"
                            type="default" [hint]="L('CLARIFICATION_FILES')" (click)='onClarificationClick'></dx-button>
                        <dx-button icon="attach" [text]="L('FILES')"
                            [visible]="attachVisible({row: {data: row.data.items[0] }})"
                            (click)='onAttachClick({row: {data: row.data.items[0] }})'
                            [hint]="L('SUPPLIER_FILES')"></dx-button>
                        <dx-button type="default"
                            [visible]="clarificationPartyVisible({row: {data: row.data.items[0] }})"
                            [text]="L('CLARIFICATIONS')" hint="Cere clarificari"
                            (click)='onClarificationQuotation({row: {data: row.data.items[0] }})'>
                        </dx-button>
                    </div>
                </div>
            </div>
        </div>
    </dxi-column>
    <!-- <dxi-column [width]="50" [fixed]="true" cellTemplate="statusTemplate">
        <div *dxTemplate="let row of 'statusTemplate'">
            <i class="dx-icon-check" *ngIf="row.data.rp_techStatus == 1" title="Aprobat"></i>
            <i class="dx-icon-clear" *ngIf="row.data.rp_techStatus == 0" title="Respins"></i>
            <i class="dx-icon-clock yellow-clock" title="În așteptare"
                *ngIf="row.data.rp_techStatus == undefined || row.data.rp_techStatus == null"></i>
            <i class="dx-icon-tips blue-tips" title="Solicitare clarificări" *ngIf="row.data.rp_techStatus == 2"></i>
        </div>
    </dxi-column> -->
    <dxi-column [caption]='L("RN_NO")' dataField='ri_compusNo' dataType='string' [customizeText]="ds.string_Zero">
    </dxi-column>
    <dxi-column [caption]='L("MATERIAL")' dataField='m_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField='m_typeId' dataType='string' [width]="100">
        <dxo-lookup [dataSource]="ds.static.materialType" valueExpr="id" displayExpr="name">
        </dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("REQUESTED_QUANTITY")' dataField='ri_quantity' dataType='number'>
    </dxi-column>
    <dxi-column [caption]='L("UOM")' dataField='uom_description' dataType='string' [width]="100">
    </dxi-column>
    <dxi-column [caption]='L("REQUESTER")' dataField='ri_requesterName' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DOC_TYPE")' dataField='doc_description' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DEPARTMENT")' dataField='dep_description' dataType='string'></dxi-column>
    <dxi-column [caption]='L("REQUEST_REASON")' dataField='ri_reason' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CREATED_BY")' dataField='ri_createdBy' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CREATED")' dataField='ri_created' dataType='datetime'></dxi-column>

    <dxi-column [caption]='L("REASON")' dataField='rp_rejectedReason' dataType='string'>
    </dxi-column>


    <!-- <dxi-column type='buttons' [fixed]="true" fixedPosition="right">
        <dxi-button icon="dx-icon-tips blue-tips" [visible]="clarificationPartyAttachVisible"
            [hint]="L('CLARIFICATION_FILES')" [onClick]='onClarificationClick'></dxi-button>
        <dxi-button icon="attach" [visible]="attachVisible" [onClick]='onAttachClick' [hint]="L('SUPPLIER_FILES')"></dxi-button>
        <dxi-button cssClass="approve-btn" [visible]="approvePartyVisible" [text]="L('APPROVE')"
            [onClick]='onApproveQuotation'></dxi-button>
        <dxi-button cssClass="clarifications-btn" [visible]="clarificationPartyVisible" [text]="L('CLARIFICATIONS')"
            [onClick]='onClarificationQuotation'>
        </dxi-button>
        <dxi-button cssClass="reject-btn" [visible]="rejectPartyVisible" [text]="L('REJECT')"
            [onClick]='onRejectQuotation'>
        </dxi-button>
        <dxi-button [visible]="returnPartyVisible" icon="return" [onClick]='onApproveQuotation'></dxi-button>
    </dxi-column> -->

</dx-data-grid>