<dx-data-grid #gridSupplierSelect [dataSource]="supplier" [(selectedRowKeys)]="ss.selectedSupplierKeys" [height]="300"
    (onToolbarPreparing)="onToolbarPrep($event)" [columnAutoWidth]="true"
    (onSelectionChanged)="onSupplierSelect($event)">
    <dxo-selection [mode]="selectMode"></dxo-selection>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="supplierSelectGrid">
    </dxo-state-storing>

    <dxi-column [caption]='L("CODE")' dataField='code' dataType='string'></dxi-column>
    <dxi-column [caption]='L("NAME")' dataField='name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("SEARCH_KEY")' dataField='searchKey' dataType='string'></dxi-column>
    <dxi-column [caption]='L("EMAIL")' dataField='emailAddress' dataType='string'></dxi-column>
    <dxi-column [caption]='L("INTERNATIONAL_LANGUAGE")' dataField='enLanguage' dataType='boolean'></dxi-column>
    <dxi-column [caption]='L("CIF")' dataField='cif' dataType='string'></dxi-column>
    <dxi-column [caption]='L("REG_NO")' dataField='regNo' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PHONE")' dataField='phone' dataType='string'></dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="party" [showAdd]="false">
    <app-party-edit></app-party-edit>
</ibis-edit-popup>