<dx-form [formData]="edit.model">
    <dxi-item itemType="group" [caption]="L('WRITE_A_QUERY')">
        <dxi-item [label]="{visible: false}" dataField="subject" editorType="dxTextArea"
            [editorOptions]="{ height: 200 }"></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [caption]="L('UPLOADED_FILES')">
        <div class="flex-column">
            <a *ngFor="let file of edit.files" href="#" (click)="openFile()">
                <i class="dx-icon dx-icon-file" style="font-size: 14px;margin-right: 10px;"></i>
                <span>{{file.name}}</span>
                <i class="dx-icon dx-icon-clear" style="font-size: 14px;margin-left: 15px;"
                    (click)="removeFile(file.name)"></i>
            </a>
        </div>
        <p *ngIf="!edit.files?.length">{{L('NO_FILES_FOUNDED')}}</p>
    </dxi-item>
</dx-form>