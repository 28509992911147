import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class RfqEditItemListTechnicalSelectionService extends iBisEditService {
  data!: any;
  initialData!: any;
  rfqItemId!: string;
  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L('COMMITTEE');
  }

  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getData(id).then(dtResp => {
        this.rfqItemId = id;
        this.data = [...dtResp.map(x => x.userId)];
        this.initialData = [...dtResp];
        resolve(true);
      })
    })
  }
  override onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.data.length == 0 || !this.rfqItemId) { reject('Invalid params.'); return; }
      var insertData = this.data.filter((x: string) => !this.initialData.map((x: any) => x.userId).includes(x));
      for (let tuID of insertData) {
        this.entity.save("TechUser", null, { rfqItemId: this.rfqItemId, userId: tuID });
      }
      var deleteData = this.initialData.filter((x: any) => !this.data.includes(x.userId));
      for (let tu of deleteData) {
        this.entity.remove("TechUser", tu.id);
      }
      setTimeout(() => {
        resolve(true);
      }, 2000);
    })
  }
  override reset(): void {
    this.model = this.createDefault();
  }

  public getData(rfqItemId: string): Promise<any[]> {
    var q = new EntityQuery("TechUser").eq('rfqItemId', rfqItemId).addOrderByDesc(['userId']);
    return this.entity.load(q);
  }

}
