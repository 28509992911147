import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class RfqEditTechUserService extends iBisEditService {

  techUserData!: DataSource;
  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L("TECHNICAL_USER_STATUS");
  }

  createDefault() {
    return {}
  }
  getById(data: any, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      const tsQuery = new EntityQuery('TechUserStatusView').eq('riq.id', data.quoteId).eq('tu.rfqItemId', data.rfqItemId).addOrderBy(['eti.modified','eti.created']);
      this.techUserData = new DataSource(this.entity.store(tsQuery, false, 'riq_id', undefined, (item) => { item.eti_modified = item.eti_modified || item.eti_created; }));
      resolve(this.createDefault());
    })
  }
  override onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  override onSaveEv(serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    event.component.option('toolbarItems', []);
    event.component.endUpdate();
  }

}
