import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class SendNotificationService extends iBisEditService {

  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L('SEND_NOTIFICATION');
  }
  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return this.entity.execute(this.model.method, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    const ti = event.component.option('toolbarItems');
    ti[0].options.icon = 'email';
    ti[0].options.text = this.L("SEND");
    event.component.option('toolbarItems', ti);
    event.component.endUpdate();
  }
}
