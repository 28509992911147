import { Component, Input, ViewChild } from '@angular/core';
import { iBisFileService, iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
import { DataService } from '../../../app/services';
import { environment } from '../../../environments/environment';
import { QuotationService } from '../quotation.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-quotation-file',
  templateUrl: './quotation-file.component.html'
})
export class QuotationFileComponent extends iBisViewComponent {
  apiUrl: string;

  @Input() dsFile: any;
  @Input() allowAdd: boolean = false;
  @Input() allowDelete: boolean = false;
  @ViewChild('filesGrid') override grid!: DxDataGridComponent;
  constructor(public qs: QuotationService, lg: iBisLanguageService, iui: iBisUIService,
    private file: iBisFileService, private ds: DataService) {
    super((<any>null), lg, iui, (<any>null));
    this.apiUrl = environment.defaultUrlServer + 'Download/';
  }

  override toolbarPreparing(ev: any, add: boolean) {
    // super.toolbarPreparing(ev, false);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        text: this.L('REFRESH'),
        onClick: (e: any) => {
          this.qs.initQuotationItem();
        }
      },
      location: 'after'
    });
    if (add) {
      ev.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          icon: 'plus',
          text: this.L('UPLOAD_FILE'),
          onClick: (ev: any) => {
            this.qs.upload(this.qs.uniqueId).then(() => {
              notify(this.L('FILE_UPLOADED'), 'success', 2000);
              this.qs.initQuotationItem();
            })
          }
        },
        location: 'after'
      });
    }
  }

  onViewClick = (ev: any) => {
    this.file.downloadByUrl(this.apiUrl + ev.row.data.id, true).then(
      () => { notify(this.L('FILES_DOWNLOADED'), 'success', 2000); },
      () => { notify(this.L('DOWNLOAD_ERROR'), 'error', 3000); });
  }

  onDownloadClick = (ev: any) => {
    this.file.downloadByUrl(this.apiUrl + ev.row.data.id).then(
      () => { notify(this.L('FILES_DOWNLOADED'), 'success', 2000); },
      () => { notify(this.L('DOWNLOAD_ERROR'), 'error', 3000); });
  }

  override onDeleteClick = (ev: any) => {
    confirm(this.L('CONFIRM_DELETE_FILE'), this.L('ALERT')).then((resp: any) => {
      if (resp) {
        this.qs.removeFile(ev.row.data.id).then(
          () => {
            this.qs.initQuotationItem();
            notify(this.L('FILES_REMOVED'), 'success', 2000);
          }, () => { notify(this.L('DOWNLOAD_ERROR'), 'error', 3000); }); 
      }
    })  
  }
}
