import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class RfqDetailsService extends iBisEditService {
  

  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L('RFQ');
  }

  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    throw new Error('Method not implemented.');
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    event.component.option('toolbarItems', []);
    event.component.endUpdate();
  }

  
}
