<dx-data-grid class="grid-container box-shadow" [dataSource]="edit.buyer" [height]="ui.fullHeight"
  (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true'>

  <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
    [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
  </dxo-scrolling>
  <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
  </dxo-state-storing>

  <dxi-column [caption]="L('BUYER')">
    <dxi-column [caption]='L("BUYER")' dataField='oh_userId' dataType='string'>
      <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField='r_typeId' dataType='number'>
      <dxo-lookup [dataSource]="ds.static.buyerReportRfqType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER_NO")' dataField='supplierNo' dataType='number'></dxi-column>
    <dxi-column [caption]='L("WINNER")' dataField='p_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DISCOUNT_REQUESTED")' dataField='discountRequested' dataType='string' [calculateDisplayValue]="displayDiscountBool"></dxi-column>
    <dxi-column [caption]='L("DISCOUNT_PERCENT")' dataField='discountDiff' dataType='number' [customizeText]="customizeDiscountText"></dxi-column>
    <dxi-column [caption]='L("CONTRACT_OR_PO")' dataField='copo' dataType='string'></dxi-column>
    <dxi-column [caption]='L("OPEX_OR_CAPEX")' dataField='department' dataType='string'></dxi-column>
    <dxi-column [caption]='L("MATERIALS_OR_SERVICES")' dataField='materialType' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PURCHASE_TYPE")' dataField='oh_docType' dataType='string'></dxi-column>
  </dxi-column>
  <dxi-column [caption]="L('CONTRACT')">
    <dxi-column [caption]='L("CONTRACT_NUMBER")' dataField='ag_refNumber' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_RESPONSIBLE")' dataField='eti_createdBy' dataType='string'>
      <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("START_DATE")' dataField='ag_fromDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("END_DATE")' dataField='ag_thruDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_VALUE")' dataField='ag_value' dataType='number'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_CURRENCY")' dataField='ag_currencyId' dataType='string'>
      <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("CONTRACT_OBJECT")' dataField='ag_title' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_QUANTITY")' dataField='ag_quantity' dataType='number'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_PRIORITY")' dataField='ag_priority' dataType='number'></dxi-column>
  </dxi-column>
  <dxi-column [caption]="L('ORDER')">
    <dxi-column [caption]='L("ORDER_NO_SAP")' dataField='oh_sapRefNumber' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ORDER_DATE")' dataField='oh_orderDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='oh_currencyId' dataType='string'>
      <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("PURCHASE_ORDER_DETAILS")' dataField='details' dataType='string'></dxi-column>
  </dxi-column>
</dx-data-grid>