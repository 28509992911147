import { Component, OnInit } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-buget',
  templateUrl: './buget.component.html'
})
export class BugetComponent extends iBisViewComponent implements OnInit {
  buget!: any;

  constructor(public auth: iBisAuthService, lg: iBisLanguageService, iui: iBisUIService, private entity: iBisEntityService) {
    super(<any>null, lg, iui, <any>null);
    this.key = 'bugetGrid';
  }

  ngOnInit(): void {
    this.buget = this.entity.store(new EntityQuery('BugetControl').addOrderBy(['costCenter']));
  }

}
