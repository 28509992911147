<dx-data-grid [dataSource]='userGroup' keyExpr="id"  [height]="ui.fullHeight" (onToolbarPreparing)='toolbarPreparing($event, false)'
    [remoteOperations]='true'>

    <dxo-editing mode="row" [allowAdding]="auth.tabAccess.edit" [allowUpdating]="auth.tabAccess.edit"
        [allowDeleting]="auth.tabAccess.delete"></dxo-editing>
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
    [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>

    <dxi-column [caption]='L("SAP_CODE")' dataField='sapCode' dataType='string'></dxi-column>
    <dxi-column [caption]='L("USER")' dataField='userId' dataType='string'>
        <dxo-lookup [dataSource]="user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>

</dx-data-grid>