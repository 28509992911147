<dx-form [formData]="edit.model" [colCount]="2">
    <dxi-item [label]="{text: L('NO') }" dataField="r_refNumber" editorType="dxTextBox"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('TITLE') }" dataField="r_title" editorType="dxTextBox"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('TYPE') }" dataField="r_typeId" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: ds.static.rfqType, valueExpr: 'id', displayExpr: 'name', readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('FLOW_TYPE') }" dataField="r_flowType" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: ds.static.flowType, valueExpr: 'id', displayExpr: 'name', readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('PRIORITY') }" dataField="r_priorityId" editorType="dxSelectBox" 
        [editorOptions]="{ dataSource: rfq.priority, valueExpr: 'id', displayExpr: 'description', readOnly: true }">
    </dxi-item>
    <dxi-item [label]="{text: L('LIMIT_DATE') }" dataField="r_dueDate" editorType="dxDateBox"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('CREATED') }" dataField="r_created" editorType="dxDateBox"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('PUBLISH_DATE') }" dataField="r_publishedDate" editorType="dxDateBox"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('EVALUATION_MATRIX') }" dataField="r_evaluationMatrixId" editorType="dxSelectBox"
        [editorOptions]="{dataSource: rfq.evMatrix, valueExpr: 'id', displayExpr: 'name', readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{ visible: false }" dataField="r_automated" editorType="dxCheckBox"
        [editorOptions]="{ text: L('AUTOMATION'), readOnly : true}">
    </dxi-item>
    <dxi-item [colSpan]="2" [label]="{text: L('SUPPLY_GROUP') }" dataField="r_userId" editorType="dxSelectBox"
        [editorOptions]="{dataSource: rfq.buyer, valueExpr: 'u_id', displayExpr: 'u_fullName', readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('PARTY_NOTES') }" dataField="r_partyNotes" editorType="dxTextArea"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('PARTY_NOTES_ENG') }" dataField="r_partyNotesEng" editorType="dxTextArea"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('TECHNICAL_NOTES') }" dataField="r_technicalNotes" editorType="dxTextArea"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
    <dxi-item [label]="{text: L('NOTES') }" dataField="r_internalNotes" editorType="dxTextArea"
        [editorOptions]="{ readOnly : true }">
    </dxi-item>
</dx-form>