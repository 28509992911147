import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { SendNotificationService } from './send-notification.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html'
})
export class SendNotificationComponent extends iBisBaseComponent {

  constructor(public override edit: SendNotificationService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route);
  }

}