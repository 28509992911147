import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DataService } from '../../../../app/services';
import { MaterialEditService } from './material-edit.service';

@Component({
  selector: 'app-material-edit',
  templateUrl: './material-edit.component.html'
})
export class MaterialEditComponent extends iBisBaseComponent implements OnInit {

  constructor(public override edit: MaterialEditService, lg: iBisLanguageService, iui: iBisUIService,
    public ds: DataService) {
    super(edit, lg, iui, <any>null);
  }

  ngOnInit(): void {
  }

}
