import { Component } from '@angular/core';
import { DataService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { ReportOrderEditService } from './report-order-edit.service';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';


@Component({
  selector: 'app-report-order',
  templateUrl: './report-order.component.html'
})
export class ReportOrderComponent extends iBisViewComponent {

  constructor(public override edit: ReportOrderEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public ds: DataService) {
    super(edit, lg, iui, route);
    this.key = 'orderReportGrid';
  }
}
