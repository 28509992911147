import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class ReportAuditEditService extends iBisSecureEditService {
  audit!: any;
  user!: any;

  constructor(auth: iBisAuthService, lg: iBisLanguageService, entity: iBisEntityService, config: iBisServerConfig) {
    super(auth, entity, config, lg);
  }

  protected load(): void {
    this.user = this.entity.store(new EntityQuery("UserLoginView").addOrderBy(['u.fullName']), false, 'u_id', this.config.saasServer);
    this.audit = this.entity.store(new EntityQuery('ReportAuditView').addOrderByDesc(['modified']), false, 'id');
  }

  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    this.model = this.createDefault();
  }
}
