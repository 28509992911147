import { Component, OnInit } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-evaluation-matrix',
  templateUrl: './evaluation-matrix.component.html'
})
export class EvaluationMatrixComponent extends iBisViewComponent implements OnInit {
  evMatrix!: DataSource;

  constructor(lg: iBisLanguageService, iui: iBisUIService, public entity: iBisEntityService,
    public auth: iBisAuthService) {
    super(<any>null, lg, iui, <any>null);
    this.key = 'evaluationMatrixGrid';
  }

  ngOnInit(): void {
    this.evMatrix = new DataSource(this.getEvMatrix());
  }
  private getEvMatrix(): CustomStore {
    return this.entity.store(new EntityQuery('EvaluationMatrix'), false, 'id');
  }

}
