import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { QuotationItemEditService } from 'src/app/common/quotation-item-edit/quotation-item-edit.service';
import { DataService } from 'src/app/services';
import { QuotationService } from '../quotation.service';

@Component({
  selector: 'app-quotation-request',
  templateUrl: './quotation-request.component.html',
  styleUrls: ['./quotation-request.component.scss']
})
export class QuotationRequestComponent extends iBisViewComponent {

  constructor(public override edit: QuotationItemEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public qs: QuotationService, public ds: DataService) {
    super((<any>null), lg, iui, route);
    this.key = 'quotationRequest';
  }

  onAddQuotation = (ev: any) => {
    this.edit.showPopup(this.getQuote(ev.row.data.m_id, ev.row.data.ris_id)).then((resp) => {
      if (resp) {
        notify(this.L('SUCCESS_ADD_QUOTATION'), 'success', 3000);
        this.qs.initQuotationItem();
      }
    })
  }

  onUnavailable = (ev: any) => {
    confirm(this.L("ARE_YOU_SURE_UNAVAILABLE"), this.L("CONFIRM")).then(val => {
      if (val) {
        this.edit.setUnavailableItem({
          partyId: this.qs.party.id,
          materialId: ev.row.data.m_id,
          rfqId: this.qs.rfqModel.id,
          unavailable: true,
          itemPrice: 0
        }).then(resp => {
          notify(this.L('UNAVAILABLE_QUOTATION'), 'success', 3000);
          this.qs.initQuotationItem();
        }, err => {
          this.lg.serverError(err);
        })
      }
    })
  }

  getQuote(materialId?: string | null, rfqItemId?: string | null) {
    return {
      unavailable: false,
      isAlternate: false,
      partyId: this.qs.party.id,
      currencyId: this.qs.party.currencyId,
      materialId: materialId,
      rfqItemId: rfqItemId,
      rfqId: this.qs.rfqModel.id,
      itemPrice: 0,
      quotePriceItem: []
    }
  }

  onRowPrep(ev: any) {
    if (ev.rowType == 'data') {
      if (ev.data.unavailable && !ev.rowElement.classList.hasOwnProperty('red-txt')) {
        ev.rowElement.classList.add('red-txt');
      }
    }
  }

  override toolbarPreparing(ev: any) {
    super.toolbarPreparing(ev, false);

    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        name: 'quotation',
        text: this.L('ADD_QUOTATION'),
        disabled: this.qs.isUpdateDisabled,
        visible: this.qs.commercialVisible,
        onClick: (ev: any) => {
          this.edit.showPopup(this.getQuote(null)).then((resp) => {
            if (resp) {
              console.log(resp);
              notify(this.L('SUCCESS_ADD_QUOTATION'), 'success', 3000);
              this.qs.initQuotationItem();
            }
          })
        }
      },
      location: 'after'
    });
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        name: 'fileAdd',
        text: this.L('UPLOAD_FILE'),
        visible: (this.qs.rfqModel.statusId == 5 && new Date(this.qs.rfqModel.dueDate) > new Date()) || this.qs.discountVisible || this.qs.partyRfq.techStatus == 2,
        onClick: (ev: any) => {
          this.qs.upload(this.qs.uniqueId).then(() => {
            notify(this.L('FILE_UPLOADED'), 'success', 2000);
          })
        }
      },
      location: 'after'
    });
  }

}
