import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { iBisCommonModule } from "@dohu/ibis-common";
import { DxDataGridModule } from "devextreme-angular/ui/data-grid";
import { DxFormModule } from "devextreme-angular/ui/form";
import { MaterialComponent } from './material/material.component';
import { MaterialEditComponent } from './material/material-edit/material-edit.component';
import { PartyComponent } from './party/party.component';
import { PartyEditComponent } from './party/party-edit/party-edit.component';
import { ConfigurationComponent } from "./configuration/configuration.component";
import { PartyEditService } from "./party/party-edit/party-edit.service";
import { MaterialEditService } from "./material/material-edit/material-edit.service";
import { CommonModule } from "@angular/common";
import { EvaluationMatrixComponent } from './evaluation-matrix/evaluation-matrix.component';
import { SapMapComponent } from './sap-map/sap-map.component';
import { UserComponent } from "./user/user.component";
import { iBisAuthModule } from "@dohu/ibis-auth";
import { NotifySupplierTemplateComponent } from "./notify-supplier-template/notify-supplier-template.component";


@NgModule({
  declarations: [
    MaterialComponent,
    MaterialEditComponent,
    PartyComponent,
    PartyEditComponent,
    ConfigurationComponent,
    EvaluationMatrixComponent,
    SapMapComponent,
    UserComponent,
    NotifySupplierTemplateComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    iBisCommonModule,
    iBisAuthModule,
    DxDataGridModule,
    DxFormModule
  ],
  exports: [
    MaterialComponent,
    MaterialEditComponent,
    PartyComponent,
    PartyEditComponent,
    ConfigurationComponent,
    SapMapComponent
  ],
  providers: [PartyEditService, MaterialEditService]
})

export class AdminModule { }