import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DataService } from 'src/app/services';
import { RfqService } from '../rfq.service';
import { RfqDetailsService } from './rfq-details/rfq-details.service';

@Component({
  selector: 'app-rfq-list',
  templateUrl: './rfq-list.component.html',
  styles: ['.nr-col{text-decoration: none;} .icon-col{font-size: 15px;} .human-time{font-size: 13px;margin-left:10px}']
})
export class RfqListComponent extends iBisViewComponent implements OnInit {
  dsFilter: string[] = [];

  @ViewChild('gridRfq') override grid!: DxDataGridComponent;
  constructor(public override edit : RfqDetailsService, public rfq: RfqService, lg: iBisLanguageService, iui: iBisUIService,
    public auth: iBisAuthService, public ds: DataService, private router: Router) {
    super(edit, lg, iui, <any>null);
    this.key = 'rfqListGrid';

    if (this.auth.hasRole('buyer')) {
      this.dsFilter = ['r_userId', '=', this.auth.user.id];
      this.processState = (state) => { state.filterValue = this.dsFilter; return state; };
    }
  }
  ngOnInit(): void {
  }

  onReloadVisible = (ev: any) => {
    return this.auth.hasRole('support') && ev.row.data.r_statusId != 0;
  }

  onEditRfq = (ev: any) => {
    this.router.navigateByUrl('rfq-edit/' + ev.row.data.r_id);
  }

  onReloadRfq = (ev: any) => {
    confirm(this.L('ARE_YOU_SURE'), this.L('ALERT')).then((resp: any) => {
      if (resp) {
        this.rfq.reloadRfq(ev.row.data.r_id).then(() => {
          notify(this.L('RFQ_RELOADED'), 'success', 3000);
          this.grid.instance.refresh();
        })
      }
    })
  }
  
  onSeeDetailsClick  = (ev: any) => {
    this.edit.showPopup(ev.row.data);
  }

}
