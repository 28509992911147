
<dx-pivot-grid [dataSource]="dsQuotationItem" [allowSortingBySummary]="true" [allowSorting]="true"
    [allowFiltering]="true" [height]='red.gridHeight' [allowExpandAll]='true' [showColumnGrandTotals]='false' rowHeaderLayout="standard"
    [showColumnTotals]='false' [showRowTotals]='true' [showRowGrandTotals]='true'
    [texts]='{ grandTotal: "Total"}' (onExporting)="onExportSummary($event)">
    <!-- (onCellPrepared)='onStockCellPrepared($event)' -->
    <!-- (onCellClick)='onStockCellClick($event)'> -->
    <!-- <dxo-state-storing [enabled]='true' type="localStorage" storageKey="summparyQuote"></dxo-state-storing> -->
    <dxo-field-chooser [enabled]="true"></dxo-field-chooser>
    <dxo-scrolling mode="virtual" [useNative]='true'></dxo-scrolling>
    <dxo-export [enabled]='true' fileName='Centralizator'></dxo-export>
    <dxo-field-panel [showDataFields]="true" [showRowFields]="true" [showColumnFields]="true"
        [showFilterFields]="true" [allowFieldDragging]="false" [visible]="true"> </dxo-field-panel>
</dx-pivot-grid>