<dx-form [formData]="edit.model" [validationGroup]="edit.validation" (onFieldDataChanged)="onOrderFieldChanged($event)"
    [colCount]="3">
    <dxi-item [label]="{ text: L('SUPPLIER') }" dataField="partyId" editorType="dxSelectBox" [editorOptions]="{
        dataSource: edit.dsParty, valueExpr: 'rp_partyId', displayExpr: 'p_name' }" [colSpan]="2">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('ORDER_DATE') }" dataField="orderDate" editorType="dxDateBox" [editorOptions]="{
        displayFormat: 'dd.MM.yyyy', type: 'date' }">
        <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
    </dxi-item>
    <dxi-item [label]="{ text: L('DOC_TYPE') }" dataField="docType" editorType="dxSelectBox" [editorOptions]="{
        dataSource: edit.dsDocType, valueExpr: 'docType', displayExpr: 'docType' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('PAYMENT_TERM') }" dataField="paymentTerm" editorType="dxSelectBox" [editorOptions]="{
        dataSource: rqs.paymentTerm, valueExpr: 'name', displayExpr: 'description' }">
        <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
    </dxi-item>
</dx-form>

<dx-data-grid class="grid-container box-shadow" [dataSource]="edit.dsOrderQuote" keyExpr="ri_id" [height]="400">

    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="orderQuoteGrid">
    </dxo-state-storing>

    <dxo-editing [allowDeleting]="true" [allowUpdating]="true"></dxo-editing>

    <dxi-column [caption]='L("RN_NO")' dataField='ri_compusNo' dataType='string' [width]="120"
        [customizeText]="ds.string_Zero" [allowEditing]="false">
    </dxi-column>
    <dxi-column [caption]='L("MATERIAL_CODE")' dataField='m_code' dataType='string' [width]="100"
        [customizeText]="ds.stringZero" [allowEditing]="false">
    </dxi-column>
    <dxi-column [caption]='L("MATERIAL_NAME")' dataField='m_name' dataType='string' [allowEditing]="false"></dxi-column>
    <dxi-column [caption]='L("IS_ALTERNATE")' dataField='riq_isAlternate' dataType='boolean' [width]="100" [allowEditing]="false"></dxi-column>
    <dxi-column [caption]='L("ALTERNATE_MATERIAL_NAME")' dataField='riq_alternateName' dataType='string' [allowEditing]="false"></dxi-column>
    <dxi-column [caption]='L("PROVIDED_QUANTITY")' dataField='ri_quantity' dataType='number' [width]="100">
    </dxi-column>
    <dxi-column [caption]='L("UNIT_PRICE")' dataField='riq_itemPrice' dataType='number' [width]="100" [allowEditing]="false"></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='riq_currencyId' dataType='string' [width]="120" [allowEditing]="false">
        <dxo-lookup [dataSource]="rqs.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>

</dx-data-grid>