import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';
import { DataService } from 'src/app/services';
import { RejectReasonService } from '../../reject-reason/reject-reason.service';
import { alert, confirm } from 'devextreme/ui/dialog';
import { InterestConflictService } from '../interest-conflict/interest-conflict.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-technical-request-list',
  templateUrl: './technical-request-list.component.html'
})
export class TechnicalRequestListComponent extends iBisViewComponent implements OnInit {

  requestList!: DataSource;

  @Input() height!: string;
  @Input() rfq!: any;
  @ViewChild('gridReqList') override grid!: DxDataGridComponent;
  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, public ic: InterestConflictService,
    private rr: RejectReasonService, public techFile: ModalFileService, public ds: DataService,
    private entity: iBisEntityService, public auth: iBisAuthService, private router: Router) {
    super((<any>null), lg, iui, route);
  }

  ngOnInit(): void {
    if (this.rfq) {
      this.requestList = new DataSource(this.techRequestList(this.rfq.id));
    }
  }
  techRequestList(rfqId: string): CustomStore<any, any> {
    return this.entity.store(new EntityQuery('RFQItemPartyView').eq('ri.rfqId', rfqId).linkEq('ri.id', 'rfqItemId', 'TechUser', 'userId', this.auth.user.id).addOrderBy(['p.name', 'm.name']), false, 'rp_id');
  }

  returnPartyVisible = (ev: any) => {
    return ev.row.data && ev.row.data.rp_techStatus == 0 && this.rfq.flowType == 2
      && this.rfq.statusId == 10;
  }

  getIncoTerms(code: string) {
    var ts = this.ds.static.incoTerm.find((r: any) => r.code == code);
    return ts ? ts.name : '-';
  }

  getSupplierStatus(data: any) {
    switch (data.rp_techStatus) {
      case 2: return 'Solicitare clarificări';
      case 1: return 'Aprobat';
      case 0: return 'Respins';
      case null:
      case undefined:
        return "În așteptare";
      default: return "-";
    }
  }
  getSupplierStatusColor(status: any) {
    switch (status) {
      case 2: return '#183EA2';
      case 1: return '#47a147';
      case 0: return 'red';
      case null:
      case undefined:
        return "#ff9c00";
      default: return "inherit";
    }
  }

  attachVisible = (ev: any) => {
    return ev.row.data && ev.row.data.fc_fileNo;
  }

  onApproveQuotation = (ev: any) => {
    confirm(this.L('CONFIRM_APPROVE'), this.L('ALERT')).then(resp => {
      if (resp) {
        this.rr.updateTechParty(ev.row.data.rp_id, 1, '').then(() => {
          this.grid.instance.refresh();
        });
      }
    });
  }

  onAttachClick = (ev: any) => {
    this.techFile.showPopup({ id: ev.row.data.rp_id })
  }

  approvePartyVisible = (ev: any) => {
    return ev.row.data && (ev.row.data.rp_techStatus == 2 || ev.row.data.rp_techStatus == null) && this.rfq.flowType == 2 &&
      this.rfq.statusId == 10;
  }

  clarificationPartyVisible = (ev: any) => {
    return ev.row.data && this.rfq.flowType == 2 && this.rfq.statusId == 10 && ev.row.data.rp_techStatus !== 2;
  }

  clarificationPartyAttachVisible = (ev: any) => {
    return ev.row.data && this.rfq.flowType == 2 && this.rfq.statusId == 10 && ev.row.data.rp_techStatus == 2;
  }

  rejectPartyVisible = (ev: any) => {
    return ev.row.data && ev.row.data.rp_techStatus !== 0 && this.rfq.flowType == 2 &&
      this.rfq.statusId == 10;
  }

  onRejectQuotation = (ev: any) => {
    this.rr.title = this.L('REJECT_QUOTATION');
    this.rr.showPopup({
      id: ev.row.data.rp_id,
      name: 'RFQParty',
      entityFileId: ev.row.data.rp_entityFileId,
      flowType: this.rfq.flowType,
      product: ev.row.data.m_name,
      ri_compusNo: ev.row.data.ri_compusNo,
      dep_description: ev.row.data.dep_description,
      ri_requesterName: ev.row.data.ri_requesterName,
      techStatus: 0,
      supplier: ev.row.data.p_name,
      materialName: ev.row.data.m_name,
      manufacturer: ev.row.data.riq_manufacturer,
      isAlternate: ev.row.data.riq_isAlternate,
      alternateName: ev.row.data.riq_alternateName,
      observations: ev.row.data.riq_observations
    }, true).then((resp) => {
      if (resp) {
        notify(this.L('SUCCESSFULL_REJECT'), 'success', 3000);
      }
      if (this.grid) {
        this.grid.instance.refresh();
      }
    });
  }

  onClarificationClick = (ev: any) => {
    this.techFile.showPopup({ id: ev.row.data.rp_entityFileId });
  }

  onClarificationQuotation = (ev: any) => {
    this.rr.title = this.L('REQUEST_CLARIFICATIONS');
    this.rr.showPopup({
      id: ev.row.data.rp_id,
      entityFileId: ev.row.data.rp_entityFileId,
      name: 'RFQParty',
      flowType: this.rfq.flowType,
      product: ev.row.data.m_name,
      ri_compusNo: ev.row.data.ri_compusNo,
      ri_department: ev.row.data.ri_department,
      ri_requesterName: ev.row.data.ri_requesterName,
      techStatus: 2,
      supplier: ev.row.data.p_name,
      materialName: ev.row.data.m_name,
      manufacturer: ev.row.data.riq_manufacturer,
      isAlternate: ev.row.data.riq_isAlternate,
      alternateName: ev.row.data.riq_alternateName,
      observations: ev.row.data.riq_observations
    }).then((resp) => {
      if (resp) {
        notify(this.L('SUCCESSFULL_CLARIFICATION'), 'success', 3000);
      }
      if (this.grid) {
        this.grid.instance.refresh();
      }
    });
  }


  cellPrepared(ev: any) {
    if (ev.rowType === 'data' && ev.data.rp_techStatus == 0 && !ev.cellElement.classList.hasOwnProperty('red-txt')) {
      ev.cellElement.classList.add('red-txt');
    }
  }

  override toolbarPreparing(ev: any) {
    super.toolbarPreparing(ev, false);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'warning',
        name: 'conflict',
        text: this.L('REPORT_CONFLICT_OF_INTEREST'),
        onClick: () => {
          this.ic.showPopup(this.rfq.id, true).then((resp) => {
            if (resp) {
              alert(this.L('THANK_YOU_FOR_REPORTING'), this.L('ALERT'));
              this.router.navigateByUrl('technical-new');
            }
          })
        }
      },
      location: 'after'
    });
  }
}
