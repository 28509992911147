import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { iBisBaseComponent, iBisFileComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RejectReasonService } from './reject-reason.service';

@Component({
  selector: 'app-reject-reason',
  templateUrl: './reject-reason.component.html'
})
export class RejectReasonComponent extends iBisBaseComponent implements AfterViewInit {

  @ViewChild('fileGrid') fileGrid!: iBisFileComponent;
  constructor(public override edit: RejectReasonService, lg: iBisLanguageService, iui: iBisUIService) {
    super(edit, lg, iui, (<any>null));
  }
  ngAfterViewInit(): void {
    this.edit.clarificationFileGridInst = this.fileGrid;
  }

}