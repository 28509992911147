<dx-data-grid #gridSupplier [dataSource]="red.supplier" keyExpr="rp_id" [height]="red.gridHeight"
    (onToolbarPreparing)="toolbarPreparing($event, false)" [remoteOperations]="false">
    <!-- <dxo-master-detail [enabled]="true" template="details"></dxo-master-detail> -->
    <dxo-scrolling [mode]="'standard'" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column [caption]='L("BID_STATUS")' cellTemplate="supplierProgress" [width]="120">
        <div *dxTemplate="let row of 'supplierProgress'">
            <dx-progress-bar [max]="row.data.materialCount" [value]="row.data.materialOffer"></dx-progress-bar>
        </div>
    </dxi-column>

    <dxi-column [caption]='L("SCORE")' cellTemplate="scoreFileTemplate">
        <div *dxTemplate="let row of 'scoreFileTemplate'" class="no-margin">
            <p>{{L('SCORE')}}: <b>{{row.data.riqScore}}</b></p>
            <p>{{L('FILE_NO')}}: <b>{{row.data.fileNo}}</b></p>
            <p>{{L('STATUS')}}:
                <b class="green-txt" *ngIf="row.data.rp_isWinner; else losserTemplate">Câștigator</b>
                <ng-template #losserTemplate>
                    <b class="red-txt" *ngIf="row.data.rp_register == null">Neînregistrat</b>
                    <b class="red-txt" *ngIf="row.data.rp_register == false">Nu participă</b>
                    <b *ngIf="row.data.rp_register">Înregistrat</b>
                </ng-template>
            </p>
        </div>
    </dxi-column>
    <dxi-column [caption]="L('DISCOUNT')" cellTemplate="discountColumnsTemplate">
        <div *dxTemplate="let row of 'discountColumnsTemplate'" class="no-margin">
            <p>{{L('DISCOUNT_REQUESTED')}}: <b>{{row.data.rp_discountRequested ? 'Da' : 'Nu'}}</b></p>
            <p>{{L('DISCOUNT_GRANTED')}}: <b>{{row.data.rp_discountGranted ? 'Da': 'Nu'}}</b></p>
        </div>
    </dxi-column>

    <dxi-column [caption]='L("NAME")' cellTemplate="partyTemplate">
        <div *dxTemplate="let row of 'partyTemplate'" class="no-margin">
            <h3><a style='text-decoration: none; color:inherit'
                    href="/#/quotation/{{row.data.rp_id}}">{{row.data.p_name}}</a></h3>
            <p>{{L("CODE")}}:<b>{{row.data.p_code}}</b></p>
            <p>{{L("CIF")}}:<b>{{row.data.p_cif}}</b></p>
        </div>
    </dxi-column>

    <dxi-column [caption]='L("CONTACT")' cellTemplate="contactDetailsTemplate">
        <div *dxTemplate="let row of 'contactDetailsTemplate'" class="no-margin">
            <p>{{L("REG_NO")}}:<b>{{row.data.p_regNo}}</b></p>
            <p>{{L("PHONE")}}:<b>{{row.data.p_phone}}</b></p>
            <p>{{L("EMAIL")}}:<b>{{row.data.p_emailAddress}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]="L('BID_TECHNICAL_NOTE')" cellTemplate="techApproveTemplate" [visible]="red.model.flowType == 2">
        <div *dxTemplate="let row of 'techApproveTemplate'" class="no-margin">
            <p *ngIf="red.model.flowType == 2">{{L("BID_TECHNICAL_NOTE")}}:
                <b *ngIf="row.data.rp_techStatus == 0" class="red-txt">Respins</b>
                <b *ngIf="row.data.rp_techStatus == 1" class="green-txt">Aprobat</b>
                <b *ngIf="row.data.rp_techStatus == null" class="yellow-txt">In asteptare</b>
                <b *ngIf="row.data.rp_techStatus == 2" class="blue-txt">Clarificari</b>
            </p>
            <p *ngIf="red.model.flowType == 2">{{L("REJECTED_REASON")}}: <b>{{row.data.rp_rejectedReason}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]="L('TRANSPORT_TERMS')" cellTemplate="transportTermsTemplate" [visible]="false">
        <div *dxTemplate="let row of 'transportTermsTemplate'" class="no-margin">
            <p>{{L("INCO_TERM")}}: <b>{{getIncoTerm(row.data.rp_incoTerm)}}</b></p>
            <p>{{L("TRANSPORT_TERM")}}: <b>{{getTransportState(row.data.rp_transportState)}}</b></p>
            <p>{{L("TRANSPORT_VALUE")}}: <b>{{row.data.rp_transportValue}}
                    {{getCurrency(row.data.rp_transportCurrency)}}</b>
            </p>
        </div>
    </dxi-column>
    <dxi-column [caption]="L('OTHER_TERMS')" cellTemplate="otherTermsTemplate" [visible]="false">
        <div *dxTemplate="let row of 'otherTermsTemplate'" class="no-margin">
            <p>{{L("PAYMENT_DAYS")}}: <b>{{row.data.rp_paymentDays}}</b></p>
            <p>{{L("EXECUTION_DAYS")}}: <b>{{row.data.rp_executionDays}}</b></p>
            <p>{{L("OBSERVATIONS")}}: <b>{{row.data.rp_observations}}</b></p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("CONTRACT_REF_NUMBER")' dataField='ag_refNumber' dataType="string" [width]="120"
        [visible]="red.model && red.model.statusId >= 18" [allowSearch]="false"></dxi-column>
    <dxi-column [caption]='L("ORDER_REF_NUMBER")' dataField='orderRefNumber' dataType="string" [width]="120"
        [visible]="red.model && red.model.statusId > 20" [allowSearch]="false"></dxi-column>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button icon="attach" [onClick]="onAttachClick"></dxi-button>
        <dxi-button icon="edit" [onClick]="onEditSupplier"></dxi-button>
        <dxi-button icon="fa-solid fa-trophy" [onClick]="promoteWinner" [visible]="promoteWinnerVisible"></dxi-button>


        <dxi-button icon="fa-solid fa-file-contract" [onClick]="createContract" [visible]="contractVisible">
        </dxi-button>
        <dxi-button icon="email" [onClick]="sendMessage"></dxi-button>
        <dxi-button icon="trash" [onClick]="deleteSupplier"></dxi-button>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false">
    <app-rfq-edit-supplier-add></app-rfq-edit-supplier-add>
</ibis-edit-popup>

<ibis-edit-popup [edit]="aes" [showAdd]="false">
    <app-agreement-edit></app-agreement-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="party" [showAdd]="false">
    <app-party-edit></app-party-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="notifySupp" [showAdd]="false">
    <app-notify-supplier></app-notify-supplier>
</ibis-edit-popup>