import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisUIService, iBisFileService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../services';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends iBisBaseComponent implements OnInit {
	validation: string;

	data: any = {};
	signatureUrl: any;
	isEditing: boolean = false;
	isChangingPassword: boolean = false;

	constructor(lg: iBisLanguageService, iui: iBisUIService, public auth: iBisAuthService, public entity: iBisEntityService,
		public file: iBisFileService, private ds: DataService, private config: iBisServerConfig) {
		super((<any>null), lg, iui, (<any>null));
		this.validation = 'profileDetailsValidation';
	}

	ngOnInit() {
		this.isEditing = false;
		this.isChangingPassword = false;
		this.auth.getFullUser().then(user => {
			this.data = user;
			this.data.lang = 'ro';
		})

	}

	passwordComparison = () => this.data.newPassword;

	onChangingPassword = () => {
		this.isChangingPassword = true;
	}

	onStartEdit = () => {
		this.isEditing = true;
	}
	onClose = () => {
		this.isEditing = false;
		this.isChangingPassword = false;
	}

	onSave = (ev: any) => {
		if (!ev.validationGroup.validate().isValid) {
			notify(this.L('FIELDS_NOT_VALID'), 'error', 3000);
			return;
		}
		if (this.isChangingPassword) {
			if (this.data.newPassword === '12345') {
				notify(this.L('COMMON_PASSWORD'), 'warning', 3000);
				return;
			}
			this.entity.execute('ChangePassword', { currentPass: this.data.currentPassword.trim(), newPass: this.data.newPassword.trim() }, this.config.authServer).then((resp) => {
				notify(this.L('PASSWORD_CHANGED'), 'success', 3000);
				this.isChangingPassword = false;
			}, err => {
				this.lg.showError("A124");
			})
		} else if (this.isEditing) {
			this.entity.save('UserLogin', this.data.id, this.data, this.config.saasServer).then(() => {
				notify(this.L('SUCCESS'), 'success', 3000);
				this.isEditing = false;
			})
		}
	}


}
