import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanTime' })
export class HumanTimePipe implements PipeTransform {
	transform(minutes: any): string {
		if (!minutes) {
			return '0m';
		}
		let sum = 1;
		let result = '';
		const t = ['h', 'd', 'w', 'mo', 'y'];
		const q = [24, 7, 4.28, 12, 1];
		for (let i = 0; i < 5; i++) {
			const tmp = Math.floor(i === 5 ? (minutes / sum) : ((minutes / sum) % q[i]));
			if (tmp !== 0) {
				result = tmp + t[i] + ' ' + result;
			}
			sum = sum * q[i];
		}
		return result.trim();
	}
}
