import { Component, OnInit } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Component({
	selector: 'app-config',
	templateUrl: './configuration.component.html'
})
export class ConfigurationComponent extends iBisViewComponent implements OnInit {
	dsEnumType!: any;

	constructor(lg: iBisLanguageService, iui: iBisUIService, public auth: iBisAuthService, private entity: iBisEntityService) {
		super(<any>null, lg, iui, <any>null);
		this.key = 'configGrid';
	}

	ngOnInit(): void {
		this.dsEnumType = this.getEnumType();
	}

	onGridInitialized(ev: any, data: any): void {
		if (ev.component.option('dataSource') == null) {
			ev.component.option('dataSource', this.getEnumValueById(data.id));
		}
	}

	onInsertNewRow(event: any, data: any): void {
		event.data.typeId = data.id;
	}

	private getEnumType(): CustomStore {
		return this.entity.store(new EntityQuery('EnumType').addOrderBy(['code']), false, 'id');
	}

	private getEnumValueById(typeId: any): CustomStore {
		return this.entity.store(new EntityQuery('EnumValue').eq('typeId', typeId).addOrderBy(['orderIndex']), false, 'id');
	}

}
