import { Component, OnInit } from '@angular/core';
import { RfqService } from '../../rfq.service';

@Component({
  selector: 'app-nav-rfq-item-list',
  templateUrl: './nav-rfq-item-list.component.html',
  styles: ['.item-list-tab {max-width: calc(100vw - 200px);margin-top:3px } ']
})
export class NavRfqItemListComponent implements OnInit {
  navItems!: { text: string; }[];

  constructor(public rfq: RfqService) { }

  ngOnInit(): void {
    this.navItems = [{ text: 'Nou' }, { text: 'În lucru' }, { text: 'Comandate' }, { text: 'Închise' }, {text: 'Anulate'}];
  }
}
