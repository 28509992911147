import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisFileService, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { ClarificationResponseService } from './clarification-response.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clarification-response',
  templateUrl: './clarification-response.component.html'
})
export class ClarificationResponseComponent extends iBisBaseComponent {

  constructor(public override edit: ClarificationResponseService, lg: iBisLanguageService,
    iui: iBisUIService, route: ActivatedRoute, private file: iBisFileService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
  }

  openFile = (ev: any) => {
    this.file.view(ev.itemData.id);
  }

}
