import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { OrderEditService } from '../order-edit.service';
import { OrderLineEditService } from './order-line-edit.service';

@Component({
  selector: 'app-order-line-edit',
  templateUrl: './order-line-edit.component.html'
})
export class OrderLineEditComponent extends iBisBaseComponent {

  constructor(public override edit: OrderLineEditService, lg: iBisLanguageService, 
    iui: iBisUIService, route: ActivatedRoute, public oed: OrderEditService) {
    super(edit, lg, iui, route);
  }
}
