<dx-data-grid #clarificationGrid [dataSource]="red.clarifications" [height]="red.gridHeight"
    (onToolbarPreparing)="toolbarPreparing($event, false)" [wordWrapEnabled]="true">
    <dxi-column [caption]='L("SUBJECT")' dataField="subject" dataType="string"></dxi-column>
    <dxi-column [caption]='L("CREATED")' dataField="created" dataType="datetime"></dxi-column>
    <dxi-column [caption]='L("RESPONSE")' dataField="response" dataType="string"></dxi-column>
    <dxi-column [caption]='L("RESPONSE_DATE")' dataField="responseAt" dataType="datetime"></dxi-column>

    <dxi-column type="buttons">
        <dxi-button icon="email" [text]="L('RESPONSE')" [onClick]="clarificationResponse"></dxi-button>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit">
    <app-clarification-response></app-clarification-response>
</ibis-edit-popup>