import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityConditionGroup, EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { alert } from 'devextreme/ui/dialog';

@Injectable({
  providedIn: 'root'
})
export class RfqEditOrderSelectService extends iBisEditService {
  dsParty: any;
  dsOrderQuote: any[] = [];
  dsDocType: any = [];

  private _modelClone: {partyId: string, docType: string, rfqId: string} = { partyId: '', docType: '', rfqId: '' };
  constructor(lg: iBisLanguageService, private entity: iBisEntityService, private router: Router) {
    super(lg);
    this.title = this.L('GENERATE_ORDER');
    this.validation = 'orderSelectSupplier';
  }
  createDefault() {
    return {};
  }
  getById(data: any, serverUrl?: string | undefined): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const unQuery = new EntityQuery('RFQPartyView').push('rp.id', 'rp.partyId', 'p.name').eq('rp.rfqId', data.rfqId).link('rp.partyId', 'partyId', new EntityQuery('RFQItemQuote').eq('rfqId', data.rfqId).eq('isWinner', true));
      const impParty = new EntityQuery('RFQPartyView').push('rp.id', 'rp.partyId', 'p.name').eq('rp.rfqId', data.rfqId).eq('rp.isWinner', true).addOrderBy(['p.name']).union(unQuery);
      this.dsParty = await this.entity.load(impParty);

      this.dsDocType = await this.entity.load(new EntityQuery('RFQItem', false, true).eq('rfqId', data.rfqId).push('docType').addOrderBy(['docType']));

      const mdl = {
        partyId: this.dsParty[0].rp_partyId,
        docType: this.dsDocType[0].docType,
        rfqId: data.rfqId,
        rfqNumber: data.rfqNumber,
        paymentTerm: 'T030',
        byMaterial: data.byMaterial,
        orderDate: new Date()
      }
      this.setOrderQuote(mdl.partyId, mdl.docType, mdl.rfqId, mdl.byMaterial);

      resolve(mdl);
    });
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.dsOrderQuote.length == 0) reject('NO_ORDER_ITEMS');
      if (this.dsOrderQuote.find(x => x.riq_alternateName)) {
        alert('Există cel puțin o linie cu Material alternativ. Nu uitați să specificați această denumire în comanda către furnizor.', 'Atenție');
      }
      this.model.quantityItems = this.dsOrderQuote.map(item => { return { id: item.riq_id, quantity: item.riq_quantity } });
      this.model.orderItems = this.dsOrderQuote.map(item => item.riq_id);
      const obj: any = {};
      Object.assign(obj, this.model);
      if (typeof obj.orderDate == 'object') {
        obj.orderDate = this.entity.toDateTimeFilter(this.model.orderDate);
      }
      this.entity.execute("GenerateOrder", obj).then(resp => {
        if (resp) {
          resolve({
            orderId: resp.id,
            refNumber: resp.refNumber,
            rfqNumber: this.model.rfqNumber,
            partyName: this.dsParty.find((x: any) => x.rp_partyId == resp.partyId)?.p_name,
            redirect: this.model.redirect,
            sendToSAP: this.model.sendToSAP
          });
        } else {
          reject('GENERATE_NOT_SUCCEED');
        }
      }, err => { reject(err); });
    })
  }
  reset(): void {
    this.model = this.createDefault();
  }

  setOrderQuote(partyId: string, docType: string, rfqId: string, byMaterial: boolean) {
    if (rfqId == this._modelClone.rfqId && partyId == this._modelClone.partyId && docType == this._modelClone.docType) return;
    this._modelClone = { partyId, docType, rfqId};
    const q = new EntityQuery('RFQItemQuoteView').
      eq('ri.statusId', 1).
      eq('ri.rfqId', rfqId).
      eq('riq.partyId', partyId).
      eq('ri.docType', docType);
    const qg = new EntityConditionGroup();
    qg.eq('riq.isWinner', true);
    qg.eq('rp.isWinner', true);
    qg.useOr = true;
    q.conditionGroups.groups.push(qg);
    this.entity.load(q).then((data: any) => {
      this.dsOrderQuote = byMaterial ? data : data.filter((x: any) => x.riq_rfqItemId == x.ri_id);
    })
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    const ti = [{
      location: "before",
      options: {
        text: this.L('SAVE'),
        icon: 'save',
        type: 'normal',
        validationGroup: this.validation,
        onClick: (ev: any) => {
          this.model.redirect = false;
          this.onSaveClose(ev);
        }
      },
      toolbar: "bottom",
      widget: "dxButton"
    }, {
      location: "before",
      options: {
        text: this.L('SAVE_AND_OPEN'),
        icon: 'save',
        type: 'normal',
        validationGroup: this.validation,
        onClick: (ev: any) => {
          this.model.redirect = true;
          this.onSaveClose(ev);
        }
      },
      toolbar: "bottom",
      widget: "dxButton"
    }, {
      location: "after",
      options: {
        text: this.L('SEND_TO_SAP'),
        icon: 'message',
        type: 'success',
        validationGroup: this.validation,
        onClick: (ev: any) => {
          this.model.sendToSAP = true;
          this.onSaveClose(ev);
        }
      },
      toolbar: "bottom",
      widget: "dxButton"
    }];
    event.component.option('toolbarItems', ti);
    event.component.endUpdate();
  }

}
