import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class RfqEditAddManualQuotationService extends iBisEditService {
  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L('ADD_QUOTATION');
    this.validation = 'addManualQuotation';
  }
  createDefault() {
    { }
  }
  getById(data: any, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      const q = new EntityQuery('RFQItemQuote').eq('rfqId', data.rfqId).eq('partyId', data.partyId).eq('materialId', data.materialId);
      if(data.rfqItemId) q.eq('rfqItemId', data.rfqItemId);
      this.entity.single(q).then((resp) => {
        if (resp) {
          resolve(resp);
        }else{
          resolve({
            isAlternate: false,
            partyId: data.partyId,
            paymentDays: 1,
            deliveryDays: 1,
            isWinner: true,
            currencyId: data.currencyId,
            materialId: data.materialId,
            rfqItemId: data.rfqItemId,
            rfqId: data.rfqId,
            itemPrice: 0,
            quantity: data.quantity,
            unavailable: false
          })
        }
      })
    })
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return this.entity.save('RFQItemQuote', null, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }

}
