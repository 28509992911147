<dx-data-grid [dataSource]='evMatrix' keyExpr="id" [height]="ui.fullHeight" (onToolbarPreparing)='toolbarPreparing($event, false)'
    [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>
    <dxo-editing mode="row" [allowAdding]="auth.tabAccess.edit" [allowUpdating]="auth.tabAccess.edit"
        [allowDeleting]="auth.tabAccess.delete"></dxo-editing>

    <dxi-column [caption]='L("NAME")' dataField='name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PRICE_FACTOR")' dataField='priceFactor' dataType='number'></dxi-column>
    <dxi-column [caption]='L("DELIVERY_FACTOR")' dataField='deliveryFactor' dataType='number'></dxi-column>
    <dxi-column [caption]='L("PAYMENT_FACTOR")' dataField='paymentFactor' dataType='number'></dxi-column>
    <dxi-column [caption]='L("WARRANTY_FACTOR")' dataField='warrantyFactor' dataType='number'></dxi-column>
</dx-data-grid>