import { Component, Input } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DataService } from '../services';
import { OrderEditService } from './order-edit/order-edit.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styles: [`.nr-col{text-decoration: none;} `]
})
export class OrderComponent extends iBisViewComponent {
  dsFilter: string[] = [];

  @Input() dsOrder: any;
  constructor(public override edit: OrderEditService, public auth: iBisAuthService,
    public ds: DataService, lg: iBisLanguageService, iui: iBisUIService) {
    super(edit, lg, iui, <any>null);
    this.key = 'orderGrid';
    this.id = 'id';
    if (!this.dsOrder) {
      this.dsOrder = edit.orderHeader;
    }

    if (this.auth.hasRole('buyer')) {
      this.dsFilter = ['userId', '=', this.auth.user.id];
      this.processState = (state) => { state.filterValue = this.dsFilter; return state; };
    }
  }

  getRFQNumber(rfqId: string) {
    return this.edit.rfqNumber.find(x => x.id == rfqId)?.refNumber || '';
  }
}
