import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { CommonCompModule } from '../common/common.module';
import { QuotationService } from './quotation.service';
import { QuotationComponent } from './quotation.component';
import { QuotationListComponent } from './quotation-list/quotation-list.component';
import { QuotationFileComponent } from './quotation-file/quotation-file.component';
import { QuotationRequestComponent } from './quotation-request/quotation-request.component';
import { QuotationMessageComponent } from './quotation-message/quotation-message.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { ClarificationListComponent } from './clarification-list/clarification-list.component';
import { AddClarificationComponent } from './clarification-list/add-clarification/add-clarification.component';
import { DxListModule } from 'devextreme-angular';



@NgModule({
  declarations: [
    QuotationComponent,
    QuotationListComponent,
    QuotationFileComponent,
    QuotationRequestComponent,
    QuotationMessageComponent,
    ClarificationListComponent,
    AddClarificationComponent
  ],
  imports: [
    CommonModule,
    CommonCompModule,
    BrowserModule,
    iBisCommonModule,

    DxDataGridModule,
    DxFormModule,
    DxButtonModule,
    DxTabPanelModule,
    DxLoadPanelModule,
    DxSwitchModule,
    DxNumberBoxModule,
    DxListModule
  ],
  exports: [
    QuotationComponent,
    QuotationListComponent
  ],
  providers: [QuotationService]
})
export class QuotationModule { }
