<dx-form [formData]="edit.model" [colCount]="4" [readOnly]="true" [validationGroup]="edit.validation">
    <dxi-item [label]="{ text: L('MATERIAL') }" dataField="materialId" editorType="dxSelectBox" [colSpan]="4"
        [editorOptions]="{dataSource: oed.material, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
    <dxi-item [label]="{ text: L('MATERIAL_NAME') }" dataField="materialDescription" editorType="dxTextBox"
        [colSpan]="4">
    </dxi-item>
    <dxi-item [label]="{ text: L('REQUESTER') }" dataField="requesterName" editorType="dxTextBox" [colSpan]="4"></dxi-item>
    <dxi-item [label]="{ text: L('QUANTITY') }" dataField="quantity" editorType="dxNumberBox"></dxi-item>
    <dxi-item [label]="{ text: L('UOM') }" dataField="uomId" editorType="dxSelectBox"
        [editorOptions]="{dataSource: oed.uom, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
    <dxi-item [label]="{ text: L('PER_UNIT') }" dataField="perUnit" editorType="dxNumberBox"></dxi-item>
    <dxi-item [label]="{ text: L('PRICE') }" dataField="netPrice" editorType="dxNumberBox"></dxi-item>

    <dxi-item [label]="{ text: L('DELIVERY_DATE') }" dataField="deliveryDate" editorType="dxDateBox"></dxi-item>
    <dxi-item [label]="{ text: L('ACCOUNT') }" dataField="categoryAccount" editorType="dxTextBox"></dxi-item>
    <dxi-item [label]="{ text: L('CONTRACT_NUMBER') }" dataField="contractNo" editorType="dxTextBox"></dxi-item>
    <dxi-item [label]="{ text: L('CONTRACT_POSITION_NO') }" dataField="contractPosNo" editorType="dxNumberBox">
    </dxi-item>
    <dxi-item [label]="{ text: L('FIXED_ASSET') }" dataField="fixedAsset" editorType="dxNumberBox"></dxi-item>
    <dxi-item [label]="{ text: L('FIXED_ASSET_CODE') }" dataField="fixedAssetCode" editorType="dxNumberBox">
    </dxi-item>
    <dxi-item [label]="{ text: L('COST_CENTER') }" dataField="costCenter" editorType="dxTextBox"></dxi-item>
    <dxi-item [label]="{ text: L('ORDER_REF_NO') }" dataField="orderRef" editorType="dxTextBox"></dxi-item>

</dx-form>