import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class PartyEditService extends iBisSecureEditService {
  party!: DataSource;
  currency!: any;

  constructor(auth: iBisAuthService, lg: iBisLanguageService, entity: iBisEntityService, config: iBisServerConfig) {
    super(auth, entity, config, lg);
    this.title = this.L('SUPPLIER');
    this.validation = 'partyValidation';
  }
  protected load(): void {
    this.party = new DataSource(this.entity.store(new EntityQuery('Party').addOrderBy(['name']), false, 'id'));
    this.currency = this.entity.store(new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'name', 'CURRENCY').addOrderBy(['orderIndex']), false, 'id', this.config.saasServer);
  }
  createDefault() {
    return { isActiv: true, enLanguage : false };
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    return this.entity.getById('Party', id);
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    return this.entity.save('Party', this.model.id, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }
}
