<dx-data-grid class="grid-container box-shadow" [dataSource]='dsOrder' [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true' [filterValue]='dsFilter'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right">
        <dxi-button icon="info" [onClick]="onEditClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]='L("INTERNAL_REF_NUMBER")' cellTemplate="refNoTemplate">
        <div *dxTemplate="let rowData of 'refNoTemplate'">
            <p class="nr-col" class="blue-txt" (click)="onEditClick({row: rowData})">{{rowData.data.refNumber}}</p>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("RFQ_NO")' cellTemplate="rfqNumberTemplate">
        <div *dxTemplate="let rowData of 'rfqNumberTemplate'">
            <a class="nr-col" class='blue-txt'
                href="#/rfq-edit/{{rowData.data.rfqId}}">{{getRFQNumber(rowData.data.rfqId)}}</a>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("SAP_REF_NUMBER")' dataField='sapRefNumber' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DOC_TYPE")' dataField='docType' dataType='string'></dxi-column>
    <dxi-column [caption]='L("STATUS")' dataField='statusId' dataType='boolean'>
        <dxo-lookup [dataSource]="ds.static.statusPo" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER")' dataField='partyId' dataType='string'>
        <dxo-lookup [dataSource]="edit.partyStore" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("PAYMENT_TERM")' dataField='paymentTerm' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DATE")' dataField='orderDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='currencyId' dataType='string'>
        <dxo-lookup [dataSource]="edit.currency" displayExpr="name" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("EXCHANGE_RATE")' dataField='exchangeRate' dataType='number'></dxi-column>
    <dxi-column [caption]='L("SELLER_NAME")' dataField='sellerName' dataType='string'></dxi-column>
    <dxi-column [caption]='L("SELLER_PHONE")' dataField='sellerPhone' dataType='string'></dxi-column>
    <dxi-column [caption]='L("INCOTERM_CODE")' dataField='incotermCode' dataType='string'></dxi-column>
    <dxi-column [caption]='L("INCOTERM_DESCRIPTION")' dataField='incotermDescription' dataType='string'></dxi-column>
    <dxi-column [caption]='L("USER")' dataField='userId' dataType='string'>
        <dxo-lookup [dataSource]="edit.buyer" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("CREATED")' dataField='created' dataType='datetime'></dxi-column>
    <dxi-column [caption]='L("MODIFIED")' dataField='modified' dataType='datetime'></dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false" [width]="'90vw'" [height]="'90vh'">
    <app-order-edit></app-order-edit>
</ibis-edit-popup>