import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisViewComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqEditService } from '../../rfq-edit.service';
import { RfqEditTechUserService } from './rfq-edit-tech-user.service';
import { RfqService } from 'src/app/rfq/rfq.service';

@Component({
  selector: 'app-rfq-edit-tech-user',
  templateUrl: './rfq-edit-tech-user.component.html'
})
export class RfqEditTechUserComponent extends iBisViewComponent implements OnInit {
  dsTechStatus: { id: number | null; name: string; }[] = [];

  constructor(public override edit: RfqEditTechUserService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public red: RfqEditService, public rfq: RfqService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
    this.dsTechStatus = [
      { id: null, name: 'In asteptare' },
      { id: 0, name: 'Respins' },
      { id: 1, name: 'Aprobat' },
      { id: 2, name: 'Clarificari' }];


  }

}
