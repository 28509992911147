import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { iBisAuthModule } from "@dohu/ibis-auth";
import { iBisCommonModule } from "@dohu/ibis-common";
import { iBisNavigationModule } from "@dohu/ibis-navigation";
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxToolbarModule } from "devextreme-angular/ui/toolbar";
import { CommonCompModule } from "../common/common.module";
import { RejectReasonComponent } from "./reject-reason/reject-reason.component";
import { TechnicalApproveComponent } from "./technical-approve/technical-approve.component";
import { TechnicalQuotationListComponent } from "./technical-approve/technical-quotation-list/technical-quotation-list.component";
import { TechnicalRequestListComponent } from "./technical-approve/technical-request-list/technical-request-list.component";
import { TechnicalListComponent } from "./technical-list/technical-list.component";
import { TechnicalService } from "./technical.service";
import { InterestConflictComponent } from './technical-approve/interest-conflict/interest-conflict.component';
import { DxSelectBoxModule } from "devextreme-angular";
import { TechnicalClarificationComponent } from "./technical-clarification/technical-clarification.component";

@NgModule({
  declarations: [
    TechnicalListComponent,
    TechnicalApproveComponent,
    TechnicalRequestListComponent,
    TechnicalQuotationListComponent,
    TechnicalClarificationComponent,
    RejectReasonComponent,
    InterestConflictComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    iBisAuthModule,
    iBisCommonModule,
    iBisNavigationModule,

    CommonCompModule,

    DxDataGridModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxFormModule,
    DxButtonModule,
    DxSelectBoxModule
  ],
  exports: [
    TechnicalListComponent,
    TechnicalApproveComponent
  ],
  providers: [TechnicalService]
})
export class TechnicalModule { }
