import { Injectable } from "@angular/core";
import { iBisAuthService, iBisSecureService } from "@dohu/ibis-auth";
import { iBisServerConfig } from "@dohu/ibis-common";
import { EntityQuery, iBisEntityService } from "@dohu/ibis-entity";
import DataSource from "devextreme/data/data_source";


@Injectable()
export class TechnicalService extends iBisSecureService {
  data!: DataSource;
  constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig) {
    super(auth, entity, config);
  }

  protected load(): void {
  }

  getClarification() {
    const q = new EntityQuery('ClarificationView').link('c.rfqId', 'rfqId', new EntityQuery('RFQItem').linkEq('id', 'rfqItemId', 'TechUser', 'userId', this.auth.user.id)).addOrderByDesc(['c.created']);
    q.distinct = true;
    return new DataSource(this.entity.store(q, false, 'c_id'));
  }

  getProductList(rfqId: string) {
    const q = new EntityQuery('RFQItemView').eq('ri.rfqId', rfqId).addOrderBy(['ri.rnNo', 'ri.rnPosNo']);
    q.fields.push('ri.id', 'ri.compusNo', 'm.name')
    return this.entity.store(q, false, 'ri_id');
  }

  getTechList(allNew: boolean) {
    const inq = new EntityQuery('RFQItem').linkEq('id', 'rfqItemId', 'TechUser', 'userId', this.auth.user.id);

    // if (allNew) {
    //     const g = new EntityConditionGroup();
    //     g.useOr = true;
    //     g.eq('rp.techStatus', null).eq('riq.techStatus', null);
    //     inq.conditionGroups.groups.push(g);
    // }
    const q = new EntityQuery('RFQ', false, true).link('id', 'rfqId', inq).addOrderByDesc(['created']);
    if (allNew) {
      q.eq('statusId', 10);
    } else {
      q.gt('statusId', 10);
    }
    return this.entity.store(q);
  }
}