<dx-tab-panel [animationEnabled]="true" [swipeEnabled]="false" [height]="295">
    <dxi-item *ngIf="edit.model" [title]="L('HEADER')">
        <dx-form [formData]="edit.model" [readOnly]="true" [colCount]="4">
            <dxi-item [label]="{ text: L('SUPPLIER') }" dataField="partyId" editorType="dxSelectBox" [colSpan]="2"
                [editorOptions]="{dataSource: edit.party, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
            <dxi-item [label]="{ text: L('ORDER_DATE') }" dataField="orderDate" editorType="dxDateBox">
            </dxi-item>
            <dxi-item [label]="{ text: L('STATUS') }" dataField="statusId" editorType="dxSelectBox"
                [editorOptions]="{ dataSource: ds.static.statusPo, valueExpr: 'id', displayExpr: 'name' }">
            </dxi-item>
            <dxi-item [label]="{ text: L('DOC_TYPE') }" dataField="docType" editorType="dxTextBox"></dxi-item>
            <dxi-item itemType="empty"></dxi-item>
            <dxi-item [label]="{ text: L('PAYMENT_TERM') }" dataField="paymentTerm" editorType="dxTextBox"
                [editorOptions]="{ }">
            </dxi-item>
            <dxi-item [label]="{ text: L('RELEASE_INDICATOR') }" dataField="releaseIndicator" editorType="dxTextBox">
            </dxi-item>

            <dxi-item [label]="{ text: L('EXCHANGE_RATE') }" dataField="exchangeRate" editorType="dxNumberBox"
                [editorOptions]="{ }"></dxi-item>
            <dxi-item [label]="{ text: L('CURRENCY') }" dataField="currencyId" editorType="dxSelectBox"
                [editorOptions]="{dataSource: edit.currency, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
            <dxi-item [label]="{ text: L('SELLER_NAME') }" dataField="sellerName" editorType="dxTextBox"></dxi-item>
            <dxi-item [label]="{ text: L('SELLER_PHONE') }" dataField="sellerTel" editorType="dxTextBox"></dxi-item>

            <dxi-item [label]="{ text: L('REQUEST_REASON') }" dataField="reasonRequest" editorType="dxTextArea"
                [colSpan]="2">
            </dxi-item>
            <dxi-item [label]="{ text: L('PARTY_NOTES') }" dataField="infoPartyNotes" editorType="dxTextArea"
                [colSpan]="2">
            </dxi-item>
        </dx-form>
    </dxi-item>
    <dxi-item *ngIf="edit.model" [title]="L('INFO')">
        <dx-form [formData]="edit.model" [readOnly]="true" [colCount]="4">
            <dxi-item [label]="{ text: L('INCOTERM_CODE') }" dataField="incotermCode" editorType="dxTextBox"></dxi-item>
            <dxi-item [label]="{ text: L('INCOTERM_DESCRIPTION') }" dataField="incotermDescription"
                editorType="dxTextBox"></dxi-item>
            <dxi-item [label]="{ text: L('EXTRA_TERM_INFO') }" dataField="extraTermInfo" editorType="dxTextBox"
                [colSpan]="2">
            </dxi-item>
            <dxi-item [label]="{ text: L('DELIVERY_CONDITIONS') }" dataField="deliveryCondition" editorType="dxTextArea"
                [colSpan]="2"></dxi-item>
            <dxi-item [label]="{ text: L('EXPEDITION_INSTRUCTIONS') }" dataField="shippingInstrunction"
                editorType="dxTextArea" [colSpan]="2"></dxi-item>

            <dxi-item [label]="{ text: L('PAYMENT_CONDITIONS') }" dataField="paymentCondition" editorType="dxTextArea"
                [colSpan]="2"></dxi-item>
            <dxi-item [label]="{ text: L('WARRANTY_INFO') }" dataField="warrantyInfo" editorType="dxTextArea"
                [colSpan]="2">
            </dxi-item>

        </dx-form>
    </dxi-item>


    <!-- <dxi-item [label]="{ text: L('EXTRA_COST_TYPE') }" dataField="extraCostId" editorType="dxSelectBox"
    [editorOptions]="{dataSource: edit.extraCost, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
<dxi-item [label]="{ text: L('EXTRA_COST_VALUE') }" dataField="extraCostValue" editorType="dxNumberBox">
</dxi-item>
<dxi-item [label]="{ text: L('EXTRA_COST_CURRENCY') }" dataField="extraCostCurrencyId" editorType="dxSelectBox"
    [editorOptions]="{dataSource: edit.currency, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
<dxi-item [label]="{ text: L('EXTRA_COST_PARTY') }" dataField="extraCostPartyId" editorType="dxSelectBox"
    [editorOptions]="{dataSource: edit.party, valueExpr: 'id', displayExpr: 'name' }"></dxi-item> -->