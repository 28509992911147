<dx-form [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
  labelLocation="left" [colCount]="2">
  <dxi-item itemType="group" caption="Romanian" [colSpan]="(edit.model.en == undefined || edit.model.en == null) ? 2: 1"
    [visible]="edit.model.ro != undefined || edit.model.ro != null">
    <dxi-item [label]="{ text: L('TEMPLATE') }" dataField="ro.templateId" editorType="dxSelectBox"
      [editorOptions]="{ dataSource: edit.templateRo, valueExpr: 'id', displayExpr: 'name', onValueChanged: onRoTemplateChanged}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('SUBJECT') }" dataField="ro.subject" editorType="dxTextBox" [editorOptions]="{}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('MESSAGE') }" dataField="ro.message" editorType="dxTextArea" [editorOptions]="{ height: '200px'}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
  </dxi-item>
  <dxi-item itemType="group" caption="English" [colSpan]="(edit.model.ro == undefined || edit.model.ro == null) ? 2: 1"
    [visible]="edit.model.en != undefined || edit.model.en != null">
    <dxi-item [label]="{ text: L('TEMPLATE') }" dataField="en.templateId" editorType="dxSelectBox"
      [editorOptions]="{ dataSource: edit.templateEn, valueExpr: 'id', displayExpr: 'name', onValueChanged: onEnTemplateChanged}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('SUBJECT') }" dataField="en.subject" editorType="dxTextBox" [editorOptions]="{}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('MESSAGE') }" dataField="en.message" editorType="dxTextArea" [editorOptions]="{height: '200px'}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
  </dxi-item>
</dx-form>