<dx-data-grid [dataSource]='edit.templateData' keyExpr="id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' (onInitNewRow)="onInitNewRow($event)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>
    <dxo-editing mode="form" [allowAdding]="auth.tabAccess.edit" [allowUpdating]="auth.tabAccess.edit"
        [allowDeleting]="auth.tabAccess.delete"></dxo-editing>

    <dxi-column [caption]='L("NAME")' dataField='name' dataType='string' [width]="150"></dxi-column>
    <dxi-column [caption]='L("IS_ACTIV")' dataField='isActiv' dataType='boolean' [width]="150"></dxi-column>
    <dxi-column [caption]='L("SUBJECT")' dataField='subject' dataType='string' [width]="200"></dxi-column>
    <dxi-column [caption]='L("LANG")' dataField='lang' dataType='string' [width]="100"></dxi-column>
    <dxi-column [caption]='L("MESSAGE")' dataField='message' dataType='string' [minWidth]="150">
        <dxo-form-item [colSpan]="2" editorType="dxTextArea" [editorOptions]="{ height: 100 }">
        </dxo-form-item>
    </dxi-column>

    <dxi-column type="buttons" [fixed]="true" fixedPosition="right" width="70">
        <dxi-button name="edit"></dxi-button>
    </dxi-column>
</dx-data-grid>