import { Component, OnInit } from '@angular/core';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { TechnicalService } from '../technical.service';
import DataSource from 'devextreme/data/data_source';
import { ClarificationResponseService } from 'src/app/common/clarification-response/clarification-response.service';
import { DataService } from 'src/app/services';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';

@Component({
  selector: 'app-technical-clarification',
  templateUrl: './technical-clarification.component.html'
})
export class TechnicalClarificationComponent extends iBisViewComponent implements OnInit {
  clarifications!: DataSource;

  constructor(public override edit: ClarificationResponseService,
    lg: iBisLanguageService, iui: iBisUIService, public tech: TechnicalService,
    public ds: DataService, public modalFile: ModalFileService) {
    super(edit, lg, iui, <any>null);
    this.id = 'c_id';
  }

  ngOnInit(): void {
    this.clarifications = this.tech.getClarification();
  }

  onAttachClick = (ev: any) => {
    this.modalFile.title = this.L("CLARIFICATION_ATTACHMENTS");
    this.modalFile.showPopup({ id: ev.row.data.c_id })
  }

  attachVisible = (ev: any) => {
    return ev.row.data && ev.row.data.fc_fileNo;
  }

  clarificationResponse = (ev: any) => {
    const obj = { id: ev.row.data.c_id, responseFileId: ev.row.data.c_responseFileId };
    this.edit.showPopup(obj, true).then(resp => {
      ev.component.refresh();
    })
  }

  onProductListRdy(ev: any, data: any) {
    if (ev.component.option('dataSource') == null) {
      ev.component.option('dataSource', this.tech.getProductList(data.c_rfqId));
    }
  }

}
