import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';
import { RfqService } from '../rfq.service';

@Injectable({
  providedIn: 'root'
})
export class RfqItemListService extends iBisEditService {
  gridInst: any;
  constructor(lg: iBisLanguageService, private entity: iBisEntityService, private rqs: RfqService) {
    super(lg);
    this.title = this.L('ADD_RN');
  }
  createDefault() {
    return { items: [] };
  }

  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.gridInst.refresh();
      resolve({ id, items: [] });
    })
  }

  onRemove(id: string): Promise<void> {
    return this.entity.update('RFQItem', id, { rfqId: null, statusId: 0 });
  }

  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    this.model.items = this.gridInst.getSelectedRowKeys();
    if (this.model.items?.length > 0) {
      return this.entity.execute('SaveRFQ', this.model);
    } else {
      notify(this.L('RFQ_ITEM_SELECTION_REQUIRED'), 'error', 3000);
      return new Promise((resolve, error) => { error('NO_ITEMS_SELECTED'); });
    }
  }

  reset(): void {
    this.model = this.createDefault();
  }

  sendTo(items: string[], statusId: number) {
    return new Promise((resolve, reject) => {
      var lastItem = items[items.length - 1];
      items.forEach((el) => {
        this.entity.update('RFQItem', el, { statusId: statusId }).then((resp) => {
          notify(this.L('SUCCESS'), 'success', 3000);
          if (resp.key == lastItem) {
            resolve(resp);
          }
        }, err => {
          reject(err);
        })
      })
    })
  }

  closeRFQ(rfqId: string) {
    return this.entity.update('RFQ', rfqId, { statusId: -1 })
  }

  updateRFQItem(rfqItemId: string, statusId: number): Promise<void> {
    return this.entity.update('RFQItem', rfqItemId, { rfqId: null, statusId: statusId });
  }

  override hidePopup(): void {
    super.hidePopup();
    this.reset();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.option('height', '90vh');
    event.component.option('width', '90vw');
  }

}
