import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services'
import { RfqService } from '../../rfq.service';
import { RfqDetailsService } from './rfq-details.service';


@Component({
  selector: 'app-rfq-details',
  templateUrl: './rfq-details.component.html',
  styleUrls: ['./rfq-details.component.scss']
})
export class RfqDetailsComponent extends iBisBaseComponent implements OnInit {

  constructor(edit: RfqDetailsService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,  public rfq: RfqService, public ds: DataService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
  }

}
