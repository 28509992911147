import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import CustomStore from 'devextreme/data/custom_store';
import { RfqEditService } from '../rfq-edit.service';

@Component({
  selector: 'app-rfq-edit-audit',
  templateUrl: './rfq-edit-audit.component.html',
  styleUrls: ['./rfq-edit-audit.component.scss']
})
export class RfqEditAuditComponent extends iBisViewComponent implements OnInit {

  audit!: any;
  @Input() entityId!: string;
  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, public red: RfqEditService) {
    super((<any>null), lg, iui, route);
    this.key = 'auditRecordGrid';
  }

  ngOnInit(): void {
    this.red.getAuditRecord(this.entityId).then(cs => { this.audit = cs; })
  }

}
