import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { AgreementEditService } from './agreement-edit/agreement-edit.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html'
})
export class AgreementComponent extends iBisViewComponent implements OnInit {

  @ViewChild('agreementGrid') override grid!: DxDataGridComponent;
  constructor(public override edit: AgreementEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route);
    this.key = 'agreementGrid';
    this.id = 'ag_id';
  }

  ngOnInit(): void {
  }

}
