<dx-data-grid #gridParty [dataSource]='edit.party' keyExpr="id"  [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, auth.tabAccess.edit)' [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>

    <dxi-column [caption]='L("CODE")' dataField='code' dataType='string'></dxi-column>
    <dxi-column [caption]='L("SUPPLIER_NAME")' dataField='name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("SEARCH_KEY")' dataField='searchKey' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PHYSICAL_ADDRESS")' dataField='address' dataType='string'></dxi-column>
    <dxi-column [caption]='L("ZIP_CODE")' dataField='zipCode' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='currencyId' dataType='string'>
        <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("CIF")' dataField='cif' dataType='string'></dxi-column>
    <dxi-column [caption]='L("REG_NO")' dataField='regNo' dataType='string'></dxi-column>
    <dxi-column [caption]='L("RATING_SUPPLIER")' dataField='rating' dataType='string'></dxi-column>
    <dxi-column [caption]='L("PHONE")' dataField='phone' dataType='string'></dxi-column>
    <dxi-column [caption]='L("EMAIL_ADDRESS")' dataField='emailAddress' dataType='string' [width]="200"></dxi-column>
    <dxi-column [caption]='L("ALTERNATIVE_CONTACT")' dataField='alternativeContact' dataType='string'></dxi-column>
    <dxi-column [caption]='L("IS_ACTIV")' dataField='isActiv' dataType='boolean'></dxi-column>
    <dxi-column [caption]='L("INTERNATIONAL_LANGUAGE")' dataField='enLanguage' dataType='boolean'></dxi-column>

    <dxi-column type='buttons' [fixed]="true" fixedPosition="right">
        <dxi-button [hint]='L("EDIT_ROW")' icon='edit' [onClick]='onEditClick' [visible]="auth.tabAccess.edit">
        </dxi-button>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-party-edit></app-party-edit>
</ibis-edit-popup>