import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class MaterialEditService extends iBisSecureEditService {
  materialGroup!: any;
  material!: DataSource;
  uom!: any;

  constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
    super(auth, entity, config, lg);
    this.title = 'Material';
    this.validation = 'materialValidation';
  }
  protected load(): void {
    this.material = new DataSource(this.entity.store(new EntityQuery('Material').addOrderBy(['code']), false, 'id'));
    this.materialGroup = this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'MATERIAL_GROUP').addOrderBy(['name']), false, 'id', undefined, item => { item.name = item.name + "-" + item.description });
    this.uom = this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'UNIT_MEASURE').addOrderBy(['name']), false, 'id', undefined, item => { item.name = item.name + "-" + item.description })
  }
  createDefault() {
    return { isActiv: true };
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    return this.entity.getById('Material', id);
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    return this.entity.save('Material', this.model.id, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }
}
