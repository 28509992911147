import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisViewComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { iBisNotifyService } from '@dohu/ibis-ws';
import { Subscription } from 'rxjs';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends iBisViewComponent implements OnInit  {

  seeNew:boolean = true;
  notificationSubscribe !: Subscription ;

  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, edit: NotificationsService, private notify: iBisNotifyService) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
    this.notificationSubscribe = this.notify.selected.subscribe((value) =>{
      this.edit.hidePopup();
    });
  }

  ngOnDestroy(): void {
    this.notificationSubscribe.unsubscribe();
  }
}
