<dx-data-grid #gridMaterial [dataSource]='edit.material' keyExpr="id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, auth.tabAccess.edit)' [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>

    <dxi-column [caption]='L("GROUP")' dataField='groupId' dataType='string' [width]="200">
        <dxo-lookup [dataSource]="edit.materialGroup" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("CODE")' dataField='code' dataType='string' [width]="150"></dxi-column>
    <dxi-column [caption]='L("NAME")' dataField='name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("NAME_ENG")' dataField='nameEng' dataType='string'></dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField="typeId" dataType="number">
        <dxo-lookup [dataSource]="ds.static.materialType" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("UOM")' dataField="uomId" dataType="number">
        <dxo-lookup [dataSource]="edit.uom" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("IS_ACTIV")' dataField='isActiv' dataType='boolean' [width]="150"></dxi-column>

    <dxi-column type='buttons' [fixed]="true" fixedPosition="right">
        <dxi-button [hint]='L("EDIT_ROW")' icon='edit' [onClick]='onEditClick' [visible]="auth.tabAccess.edit"></dxi-button>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]='true'>
    <app-material-edit></app-material-edit>
</ibis-edit-popup>