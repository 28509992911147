import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { RfqEditService } from '../rfq-edit.service';
import { EmailDetailsService } from './email-details/email-details.service';

@Component({
  selector: 'app-rfq-edit-email-notification',
  templateUrl: './rfq-edit-email-notification.component.html',
  styleUrls: ['./rfq-edit-email-notification.component.scss']
})
export class RfqEditEmailNotificationComponent extends iBisViewComponent implements OnInit {

  
  emails!: { to: string, subject: string, body: string, created: Date }[];
  @Input() entityId!: string;
  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, public red: RfqEditService, public entity: iBisEntityService, 
    public override edit: EmailDetailsService) {
    super((<any>null), lg, iui, route);
    this.key = 'emailRecordGrid';
  }

  ngOnInit(): void {
    this.red.getEmailRecord().then(resp => { this.emails = resp; });
  }

  openEmailDetails = (ev:any) => {
    this.edit.showPopup(ev.row.data);
  }

}
