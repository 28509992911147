<ng-container *ngIf="auth.isAuth; else notAuthorized">
    <ibis-header (toggle)="menuOpened = !menuOpened;" [user1Template]='user1Template'>
        <ng-template #user1Template let-item="item">
            <img src='assets/img/logo.png' height="36px" style="padding-left: 10px;" />
        </ng-template>
    </ibis-header>

    <dx-drawer class="layout-body" position="before" [closeOnOutsideClick]="true" [shading]='true'
        openedStateMode="overlap" revealMode="expand" [(opened)]="menuOpened" [minSize]="49" [maxSize]='260'
        height='calc(100vh - 56px)'>

        <div *dxTemplate="let dataMenu of 'panel'" class="dx-swatch-additional">
            <ibis-menu style='width: 260px; height: calc(100vh - 56px)' [compactMode]='!menuOpened'
                (toggle)="menuOpened = !menuOpened;">
            </ibis-menu>
        </div>
        <div class="ibis-content-block actions-bar">
            <ibis-breadcrumb *ngIf='proxy.showActionsBar' style='width: 150px;'></ibis-breadcrumb>
            <router-outlet *ngIf='proxy.showActionsBar' name='undernav'></router-outlet>
        </div>
        <div class="ibis-content">
            <router-outlet></router-outlet>
        </div>
        <div class="ibis-content-block">
            <ibis-footer [version]='version' [app]='{link: "https://www.dohu.ro/", text: "dohu.ro"}'
                [copy]='{link: "https://www.dohu.ro/", text: "DoHu Consulting"}'>
            </ibis-footer>
        </div>
    </dx-drawer>
</ng-container>
<ng-template #notAuthorized>
    <app-single-card>
        <router-outlet></router-outlet>
    </app-single-card>
</ng-template>



<ibis-grid-config></ibis-grid-config>

<div id="loginModal" *ngIf='ds.isLoginModalVisible'>
    <ibis-login (loginFinished)='onLoginFinished()'></ibis-login>
</div>

<dx-popover target="#notification" position="top" [(visible)]="proxy.notifyVisible">
    <div *dxTemplate="let data = model of 'content'">
        <dx-button text="Goleste" icon="trash" style="margin-left:30px" stylingMode="contained" (onClick)="deleteNewNotifications()"></dx-button>
        <dx-button text="Notificari" style="margin-left:30px" stylingMode="contained" type="default" (onClick)="seeAllNotifications()"></dx-button>
        <ibis-notify [newOnly]="true"></ibis-notify>
    </div>
</dx-popover>

<ibis-edit-popup [edit]="ns">
    <app-notifications></app-notifications>
</ibis-edit-popup>