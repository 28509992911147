import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { alert, confirm } from 'devextreme/ui/dialog';
import { RfqService } from '../../rfq.service';
import { RfqEditService } from '../rfq-edit.service';
import { RfqEditSupplierAddService } from './rfq-edit-supplier-add/rfq-edit-supplier-add.service';
import { AgreementEditService } from 'src/app/agreement/agreement-edit/agreement-edit.service';
import { PartyEditService } from 'src/app/admin/party/party-edit/party-edit.service';
import { SendNotificationService } from 'src/app/common/send-notification/send-notification.service';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';
import { DataService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { NotifySupplierEditService } from 'src/app/common/notify-supplier/notify-supplier-edit.service';

@Component({
  selector: 'app-rfq-edit-supplier',
  templateUrl: './rfq-edit-supplier.component.html'
})
export class RfqEditSupplierComponent extends iBisViewComponent implements OnInit, OnDestroy {
  sbs!: Subscription;

  @ViewChild('gridSupplier') override grid!: DxDataGridComponent;
  constructor(public override edit: RfqEditSupplierAddService, lg: iBisLanguageService, iui: iBisUIService,
    route: ActivatedRoute, public red: RfqEditService, public rqs: RfqService, private router: Router,
    public sns: SendNotificationService, public aes: AgreementEditService, public party: PartyEditService,
    public modalFile: ModalFileService, public ds: DataService, public notifySupp: NotifySupplierEditService) {
    super(edit, lg, iui, route);
    this.id = 'rp_rfqId';
    this.key = 'rfqEditSupplierGrid';
  }

  ngOnInit() {
    this.sbs = this.red.rfqSubject.subscribe(() => {
      if (this.grid) {
        this.grid.instance.refresh();
      }
    })
  }

  ngOnDestroy() {
    if (this.sbs) {
      this.sbs.unsubscribe();
    }
  }

  onEditSupplier = (ev: any) => {
    this.party.showPopup(ev.row.data.rp_partyId, true).then(data => {
      if (data) {
        notify('UPDATE_SUCCESS', 'success', 3000);
      }
      ev.component.refresh();
    })
  }

  getCurrency(id: string) {
    var item = this.rqs.currency.find(r => r.id == id)
    return item ? item.name : '-';
  }

  contractVisible = (ev: any) => {
    return ev.row.data && !ev.row.data.ag_id && this.red.model.statusId >= 18;
  }

  promoteWinnerVisible = (ev: any) => {
    return ev.row.data && !ev.row.data.rp_isWinner && this.red.model.statusId == 18 && this.red.model.typeId == 1;
  }

  getIncoTerm(incoTermCode: string) {
    var it = this.ds.static.incoTerm.find((x: any) => x.code == incoTermCode);
    return it ? it.name : 'Nespecificat';
  }

  getTransportState(state: number) {
    var st = this.ds.static.transportState.find((x: any) => x.id == state);
    return st ? st.name : 'Necompletat';
  }

  createContract = (ev: any) => {
    this.aes.showPopup({ isActiv: true, rfqId: this.red.model.id, partyId: ev.row.data.rp_partyId }).then((resp) => {
      ev.component.refresh();
      if (resp) {
        notify(this.L('SUCCESS'), 'success', 3000);
      }
    })
  }

  sendMessage = (ev: any) => {
    let obj: any = {};
    if (ev.row.data.p_enLanguage) {
      obj = { en: { rfqId: this.red.model.id, supplierId: ev.row.data.rp_partyId, refNo: this.red.model.refNumber } };
    } else {
      obj = { ro: { rfqId: this.red.model.id, supplierId: ev.row.data.rp_partyId, refNo: this.red.model.refNumber } };
    }
    this.notifySupp.showPopup(obj).then((resp) => {
      if (resp) {
        notify(this.L('EMAIL_SENT'), 'success', 3000);
      }
    });
  }

  openSupplierPage = (ev: any) => {
    this.edit.getRFQParty(ev.row.data.rp_partyId, this.red.model.id).then(resp => {
      if (resp && resp.id) {
        this.router.navigateByUrl('/quotation/' + resp.id);
      }
    });
  }

  deleteSupplier = (ev: any) => {
    confirm(this.L("CONFIRM_DELETE"), this.L("ALERT")).then((resp: any) => {
      if (resp) {
        this.edit.onRemove(ev.row.data.rp_id).then((resp) => {
          ev.component.refresh();
          notify(this.L('SUCCESS'), 'success', 3000);
        });
      }
    })
  }

  promoteWinner = (ev: any) => {
    confirm(this.L('ARE_YOU_SURE'), this.L('ALERT')).then(alertResp => {
      if (alertResp) {
        this.edit.setPartyWinner(ev.row.data.rp_id).then(resp => {
          ev.component.refresh();
          notify(this.L('SUCCESS'), 'success', 3000);
        }, err => { this.lg.showError(err); });
      }
    })
  }

  onAttachClick = (ev: any) => {
    this.modalFile.title = this.L("OFFER_ATTACHMENTS");
    this.modalFile.showPopup({ id: ev.row.data.rp_id })
  }

  override toolbarPreparing(ev: any, add: boolean) {
    super.toolbarPreparing(ev, add);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: this.L('ADD_SUPPLIER'),
        visible: [0, 5].indexOf(this.red.model.statusId) > -1,
        onClick: (eb: any) => {
          if (this.red.model.typeId == 3) {
            confirm(this.L('UPDATE_RFQ_UNIQUE_PARTY'), this.L('ALERT')).then((rld) => {
              if (rld) {
                this.red.updateRFQType(2);
                this.showSupplierPopup(ev);
              }
            })
          } else {
            this.showSupplierPopup(ev);
          }
        }
      },
      location: 'after'
    });
  }

  showSupplierPopup(ev: any) {
    this.edit.showPopup(this.red.model.id, true).then((resp) => {
      if (resp) {
        notify(this.L('ADD_SUCCESSFULL'), 'success', 3000);
      }
      if (this.red.model.typeId == 3) {
        this.red.initData(this.red.model.id);
      }
      ev.component.refresh();
    })
  }

}
