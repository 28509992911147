import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { QuotationService } from '../quotation.service';

@Component({
  selector: 'app-quotation-message',
  templateUrl: './quotation-message.component.html',
  styleUrls: ['./quotation-message.component.scss']
})
export class QuotationMessageComponent extends iBisBaseComponent implements OnInit {

  @Output() openEvent = new EventEmitter<boolean>();

  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, public qs: QuotationService) {
    super(null as any, lg, iui, route);
  }

  ngOnInit(): void {
  }

  openDetails() {
    if (!this.qs.partyRfq.register) {
      this.qs.updateRFQRegister(true);
    }
    this.openEvent.emit(true);
  }

  discountDeniedEv(ev: any) {
    this.qs.updateRFQDiscount(false);
  }

  expiredDueDate(rfqModel: any) {
    return new Date(rfqModel.dueDate) < new Date() &&
      rfqModel.statusId >= 5 && rfqModel.statusId < 20 && !this.qs.partyRfq.discountRequested;
  }

}
