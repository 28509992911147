import { Injectable } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class InterestConflictService extends iBisEditService {
  dsSupplier!: CustomStore;
  constructor(lg: iBisLanguageService, private entity: iBisEntityService, private auth: iBisAuthService) {
    super(lg);
    this.title = this.L("REPORTING_CONFLICT_OF_INTERESTS");
  }
  createDefault() {
    return {}
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      const ripv = new EntityQuery('RFQItemPartyView').eq('ri.rfqId', id).linkEq('ri.id', 'rfqItemId', 'TechUser', 'userId', this.auth.user.id).addOrderBy(['p.name']);
      ripv.fields.push('p.name', 'rp.partyId');
      ripv.distinct = true;
      this.dsSupplier = this.entity.store(ripv, false, 'ri_id');
      resolve({ rfqId: id })
    })
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return this.entity.save("InterestConflict", null,
      { partyId: this.model.partyId, rfqId: this.model.rfqId, userId: this.auth.user.id })
  }
  reset(): void {
    this.model = this.createDefault();
  }


}
