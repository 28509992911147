<div class='actions-bar'>
    <dx-toolbar [visible]="rfqModel && rfqModel.statusId != null">
        <dxi-item location="before">
            <p class="left-title">Avizare tehnică -
                <span *ngIf="rfqModel && rfqModel.flowType == 2"> Avizul tehnic va fi acordat la nivel de
                    furnizor.</span>
                <span *ngIf="rfqModel && rfqModel.flowType == 1"> Avizul tehnic va fi acordat la nivel de cotație.</span>
            </p>
        </dxi-item>
        <dxi-item location="center" locateInMenu="auto">
        </dxi-item>
        <dxi-item location="after" locateInMenu="never">
            <dx-button class="headbtn" type="normal" icon="fas fa-arrow-left" [hint]="L('BACK')" (onClick)="goBack()">
            </dx-button>
            <dx-button type="default" icon="email" [text]="L('OFFER_ACCEPTED')" (onClick)="sendNotification()">
            </dx-button>
        </dxi-item>
    </dx-toolbar>
</div>

<dx-form class="tech-form" [formData]="rfqModel" [colCount]="4">
    <dxi-item [label]="{ text: L('NO')}" dataField='refNumber' editorType="dxTextBox"
        [editorOptions]="{ readOnly: true}"></dxi-item>
    <dxi-item [label]="{ text: L('STATUS')}" dataField='statusId' editorType="dxSelectBox"
        [editorOptions]="{ readOnly: true, dataSource: ds.static.rfqStatus, valueExpr: 'id', displayExpr: 'name' }"></dxi-item>
    <dxi-item [label]="{ text: L('TITLE')}" dataField='title' editorType="dxTextBox" [editorOptions]="{ readOnly: true}"
        [colSpan]="2"></dxi-item>
    <dxi-item [label]="{ text: L('TYPE')}" dataField='typeId' editorType="dxSelectBox"
        [editorOptions]="{ dataSource: ds.static.rfqType, valueExpr: 'id', displayExpr: 'name', readOnly: true}">
    </dxi-item>
    <dxi-item [label]="{ text: L('SUPPLY_GROUP')}" dataField='userId' editorType="dxSelectBox"
        [editorOptions]="{ dataSource: proxy.rfq.buyer, valueExpr:'u_id', displayExpr:'u_fullName', readOnly: true}">
    </dxi-item>
    <dxi-item [label]="{ text: L('PARTY_NOTES_BUY')}" dataField='technicalNotes' editorType="dxTextArea" [colSpan]="2"
        [editorOptions]="{ readOnly: true }">
    </dxi-item>
</dx-form>

<dx-tab-panel [animationEnabled]="true" [swipeEnabled]="false">
    <dxi-item [title]="L('REQUEST_LIST')" *ngIf="rfqModel && rfqModel.id && rfqModel.flowType == 2">
        <app-technical-request-list [rfq]="rfqModel" [height]="gridHeight"></app-technical-request-list>
    </dxi-item>
    <dxi-item [title]="L('QUOTATION_LIST')" *ngIf="rfqModel && rfqModel.id && rfqModel.flowType == 1">
        <app-technical-quotation-list [rfq]="rfqModel" [height]="gridHeight">
        </app-technical-quotation-list>
    </dxi-item>
    <dxi-item [title]="L('DOCUMENTATION')" *ngIf="rfqModel && rfqModel.id">
        <ibis-file [entityId]="rfqModel.id" [allowUpload]="false" [height]="gridHeight">
        </ibis-file>
    </dxi-item>
</dx-tab-panel>

<ibis-edit-popup [edit]="rr" [showAdd]="false">
    <app-reject-reason></app-reject-reason>
</ibis-edit-popup>

<ibis-edit-popup [edit]="modalFile" [showAdd]="false">
    <app-modal-file></app-modal-file>
</ibis-edit-popup>

<ibis-edit-popup [edit]="ic" [showAdd]="false" [width]="'400'">
    <app-interest-conflict></app-interest-conflict>
</ibis-edit-popup>