<dx-data-grid #gridRfq class="grid-container box-shadow" [dataSource]='rfq.data' keyExpr="r_id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true' [filterValue]='dsFilter'
    [filterSyncEnabled]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key"></dxo-state-storing>

    <dxi-column cellTemplate="rfqTypeTemplate" [width]="180">
        <div *dxTemplate="let item of 'rfqTypeTemplate'" class="icon-col">
            <i *ngIf="item.data.r_typeId == 1" class="red-txt fa-solid fa-clock-rotate-left"></i>
            <i *ngIf="item.data.r_typeId == 2" class="blue-txt fa-brands fa-creative-commons-share"></i>
            <i *ngIf="item.data.r_typeId == 3" class="yellow-txt fa-brands fa-creative-commons-by"></i>
            <span class="human-time" *ngIf="[5, 12].includes(item.data.r_statusId)">{{item.data.publishMinute |
                humanTime}}</span>
            <span class="human-time" *ngIf="item.data.r_statusId == 10">
                <i class="yellow-txt fa-solid fa-circle-exclamation" *ngIf="item.data.overdueTime"></i>
                {{ item.data.overdueTime | humanTime}}</span>
        </div>
    </dxi-column>
    <dxi-column [caption]="L('QUOTATION_NO')" dataField="riq_no" dataType="number" [width]="100"></dxi-column>
    <dxi-column [caption]='L("NO")' cellTemplate="refNumberTemplate" dataField="r_refNumber" dataType='string'
        [width]="100">
        <div *dxTemplate="let item of 'refNumberTemplate'">
            <a class="nr-col" [class.red-txt]="item.data.r_statusId == 12"
                [class.blue-txt]="item.data.r_statusId != 12 && item.data.r_statusId != 25"
                [class.green-txt]="item.data.r_statusId == 25" href="#/rfq-edit/{{item.data.r_id}}"
                [title]="item.data.r_statusId == 12 ? 'Publicare comerciala' : 'Publicare initiala' ">{{item.data.r_refNumber}}</a>
        </div>
    </dxi-column>
    <dxi-column [caption]='L("TITLE")' dataField='r_title' dataType='string' [width]="200"></dxi-column>
    <dxi-column [caption]="L('ORDER_NO')" dataField="oh_no" dataType="number" [width]="100"></dxi-column>
    <dxi-column [caption]='L("TYPE")' dataField='r_typeId' dataType='number'>
        <dxo-lookup [dataSource]="ds.static.rfqType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("FLOW_TYPE")' dataField='r_flowType' dataType='number'>
        <dxo-lookup [dataSource]="ds.static.flowType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("EVALUATION_MATRIX")' dataField='r_evaluationMatrixId' dataType='string'>
        <dxo-lookup [dataSource]="rfq.evMatrix" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLY_GROUP")' dataField='r_userId' dataType='string'>
        <dxo-lookup [dataSource]="rfq.buyer" valueExpr='u_id' displayExpr='u_fullName'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("CREATED")' dataField='r_created' dataType='date'></dxi-column>
    <dxi-column [caption]='L("PUBLISH_DATE")' dataField='r_publishedDate' dataType='datetime'></dxi-column>
    <dxi-column [caption]='L("LIMIT_DATE")' dataField='r_dueDate' dataType='datetime'></dxi-column>
    <dxi-column [caption]='L("NOTES")' dataField='r_internalNotes' dataType='string' [width]="250"></dxi-column>
    <dxi-column [caption]='L("PARTY_NOTES")' dataField='r_partyNotes' dataType='string' [width]="250"></dxi-column>

    <dxi-column type='buttons' [fixed]="true" fixedPosition="right">
        <dxi-button [hint]='L("SEE_DETAILS")' icon='fa-solid fa-circle-info' [onClick]='onSeeDetailsClick'></dxi-button>
        <dxi-button [hint]='L("EDIT_ROW")' icon='fas fa-arrow-right' [onClick]='onEditRfq'></dxi-button>
        <dxi-button [hint]='L("RELOAD_FLUX")' icon='fas fa-rotate-right' [onClick]='onReloadRfq' [visible]="onReloadVisible"></dxi-button>
    </dxi-column>
</dx-data-grid>
<ibis-edit-popup [edit]="edit" [showAdd]="false">
    <app-rfq-details></app-rfq-details>
</ibis-edit-popup>