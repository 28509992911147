import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisViewComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { DataService, ProxyService } from 'src/app/services';
import { TechnicalService } from '../technical.service';

@Component({
  selector: 'app-technical-list',
  templateUrl: './technical-list.component.html',
  styleUrls: ['./technical-list.component.scss']
})
export class TechnicalListComponent extends iBisViewComponent implements OnInit {

  @ViewChild('gridTechReq') override grid!: DxDataGridComponent;
  constructor(lg: iBisLanguageService, iui: iBisUIService, private route: ActivatedRoute, public tech: TechnicalService,
    public ds: DataService, private router: Router, public proxy: ProxyService) {
    super((<any>null), lg, iui, route);
    this.key = 'reqTechGrid';
  }

  ngOnInit(): void {
    this.tech.data = new DataSource(this.tech.getTechList(this.router.url.indexOf('new') > -1));
  }

  onOpenRfq = (ev: any) => {
    this.router.navigateByUrl('/technical-approve/' + ev.row.data.id)
  }

}
