<dx-data-grid #filesGrid [dataSource]="dsFile" [remoteOperations]='true' [height]='qs.gridHeight'
    (onToolbarPreparing)="toolbarPreparing($event, allowAdd)">

    <dxi-column [caption]='L("FILE")' dataField='name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("DATE")' dataField='uploadTime' dataType='datetime' width='170'
        calculateSortValue='uploadTime' [calculateDisplayValue]='iui.formatDateTime'></dxi-column>
    <dxi-column type="buttons" fixedPosition='left' width='110' [allowResizing]='false' [showInColumnChooser]='false'>
        <dxi-button [hint]='L("VIEW")' icon='file' [onClick]="onViewClick"></dxi-button>
        <dxi-button [hint]='L("DOWNLOAD")' icon='download' [onClick]="onDownloadClick"></dxi-button>
        <dxi-button [hint]='L("DELETE")' icon='trash' [onClick]="onDeleteClick" [visible]="allowDelete"></dxi-button>
    </dxi-column>
</dx-data-grid>