import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqEditAddManualQuotationService } from './rfq-edit-add-manual-quotation.service';

@Component({
  selector: 'app-rfq-edit-add-manual-quotation',
  templateUrl: './rfq-edit-add-manual-quotation.component.html'
})
export class RfqEditAddManualQuotationComponent extends iBisBaseComponent implements OnInit {

  @Input() rfqItems: any;
  @Input() currency: any[] = [];
  constructor(edit: RfqEditAddManualQuotationService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route);
  }

  ngOnInit(): void {
  }

}
