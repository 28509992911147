<dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="3">

    <dxi-item [label]="{text: L('MATERIAL_SERVICE') }" dataField="materialId" editorType="dxSelectBox" [colSpan]="3"
        [editorOptions]="{ dataSource: rfqItems, valueExpr: 'm_id', displayExpr: 'm_name', 
        readOnly: true }" [colSpan]="3">
    </dxi-item>

    <dxi-item [label]="{text: L('QUANTITY') }" dataField="quantity" editorType="dxNumberBox"
        [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [label]="{text: L('PRICE') }" dataField="itemPrice" editorType="dxNumberBox" [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [label]="{text: L('CURRENCY') }" dataField="currencyId" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: currency, valueExpr: 'id', displayExpr: 'name'}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
</dx-form>