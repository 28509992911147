import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { RfqService } from 'src/app/rfq/rfq.service';
import { DataService } from 'src/app/services';
import { RfqEditItemListPriceHistoryService } from './rfq-edit-item-list-price-history.service';

@Component({
  selector: 'app-rfq-edit-item-list-price-history',
  templateUrl: './rfq-edit-item-list-price-history.component.html',
  styleUrls: ['./rfq-edit-item-list-price-history.component.scss']
})
export class RfqEditItemListPriceHistoryComponent extends iBisViewComponent implements OnInit {

  constructor(public override edit: RfqEditItemListPriceHistoryService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,  public rfq: RfqService, public ds: DataService) {
    super(edit, lg, iui, route);
    this.key = 'priceHistoryGrid';
    this.id = 'riq_id';
  }

  ngOnInit(): void {

  }

}
