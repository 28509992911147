import { Component } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';
import { NotifySupplierEditService } from '../../common/notify-supplier/notify-supplier-edit.service';

@Component({
    selector: 'app-notify-template',
    templateUrl: './notify-supplier-template.component.html'
})
export class NotifySupplierTemplateComponent extends iBisViewComponent {

    constructor(public override edit: NotifySupplierEditService, lg: iBisLanguageService, iui: iBisUIService, public entity: iBisEntityService,
        public auth: iBisAuthService) {
        super(edit, lg, iui, <any>null);
        this.key = 'notifyTemplate';
    }

    onInitNewRow(ev: any){
        ev.data.isActiv = true;
    }
}
