<dx-data-grid class="grid-container box-shadow" [dataSource]="edit.contract" keyExpr="ag_id" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>


    <dxi-column [caption]='L("BUYER")' dataField='eti_createdBy' dataType='string'>
      <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("SUPPLIER")' dataField='p_name' dataType='string'></dxi-column>
    <dxi-column [caption]='L("POSITION_NO")' dataField='ag_refNumber' dataType='number'></dxi-column>
    <dxi-column [caption]='L("START_DATE")' dataField='ag_fromDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("END_DATE")' dataField='ag_thruDate' dataType='date'></dxi-column>
    <dxi-column [caption]='L("VALUE")' dataField='ag_value' dataType='number'></dxi-column>
    <dxi-column [caption]='L("CURRENCY")' dataField='ag_currencyId' dataType='string'>
      <dxo-lookup [dataSource]="edit.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("OBSERVATIONS")' dataField='ag_title' dataType='string'></dxi-column>
    
    <dxi-column [caption]='L("QUANTITY")' dataField='' dataType='string'></dxi-column>
    <dxi-column [caption]='L("CONTRACT_FRAME")' dataField='' dataType='string'></dxi-column>


</dx-data-grid>
