<dx-data-grid class="grid-container box-shadow" [dataSource]="edit.audit" [height]="ui.fullHeight"
    (onToolbarPreparing)='toolbarPreparing($event, false)' [remoteOperations]='true'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="ui.useNative"
        [renderAsync]='ui.scrollingRenderAsync' [rowRenderingMode]='ui.scrollRenderingMode'>
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>


    <dxi-column [caption]='L("BUYER")' dataField='userId' dataType='string'>
      <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("PROCESS_BUYER")' dataField='modifiedBy' dataType='string'>
      <dxo-lookup [dataSource]="edit.user" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("RN/RFQ/Order")' dataField='refNumber' dataType='string'></dxi-column>
    <dxi-column [caption]='L("MODIF_DATE")' dataField='modified' dataType='date'></dxi-column>
    <dxi-column [caption]='L("MODIF_PROCESS")' dataField='typeId' dataType='number'>
      <dxo-lookup [dataSource]="ds.static.buyerReportRfqType" valueExpr='id' displayExpr='name'></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("MODIF_VALUE")' dataField='description' dataType='string'></dxi-column>
    
</dx-data-grid>