import { Component } from '@angular/core';
import { DataService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { ReportAuditEditService } from './report-audit-edit.service';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';

@Component({
  selector: 'app-report-audit',
  templateUrl: './report-audit.component.html'
})
export class ReportAuditComponent extends iBisViewComponent {

  constructor(public override edit: ReportAuditEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public ds: DataService) {
    super(edit, lg, iui, route);
    this.key = 'auditReportGrid';
  }
}
