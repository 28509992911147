import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import { OrderEditService } from '../order-edit.service';

@Component({
  selector: 'app-order-header-edit',
  templateUrl: './order-header-edit.component.html',
  styles: ['.dx-form{ margin:10px 0; }']
})
export class OrderHeaderEditComponent extends iBisBaseComponent {

  constructor(public override edit: OrderEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public ds: DataService) {
    super(edit, lg, iui, route);
  }
}
