import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisEditService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root',
})
export class NotifySupplierEditService extends iBisSecureEditService {
  public templateData!: any;
  public templateRo!: any;
  public templateEn!: any;

  constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
    super(auth, entity, config, lg);
    this.title = this.L('NOTIFY_SUPPLIER');
    this.validation = 'notifySupplierValidation';
  }

  createDefault() {
    return {};
  }

  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }

  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  //TODO: @Luca-> Try to clean it AND delete duplicated CODE making a notify-supplier-base.service.ts
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.model.ro) {
        this.entity.save('NotifySupplier', this.model.ro.id, this.model.ro).catch((error) => {
          reject(error);
          return;
        });
      }
      if (this.model.en) {
        this.entity.save('NotifySupplier', this.model.en.id, this.model.en).catch((error) => {
          reject(error);
          return;
        });
      }
      resolve("OK");
    });
  }

  reset(): void {
    this.model = this.createDefault();
  }

  public set ROtemplateId(id: any) {
    this.entity.getFieldsById('NotifySupplierTemplate', id, ['message', 'subject']).then((r) => {
      this.model.ro.message = r.message;
      var subject = r.subject
      this.model.ro.subject = subject.replace("__RFQ_NO__", this.model.ro.refNo);
    });
  }
  public set ENtemplateId(id: any) {
    this.entity.getFieldsById('NotifySupplierTemplate', id, ['message', 'subject']).then((r) => {
      this.model.en.message = r.message;
      var subject = r.subject;
      this.model.en.subject = subject.replace("__RFQ_NO__", this.model.en.refNo);
    });
  }

  protected load(): void {
    this.templateData = this.entity.store(new EntityQuery('NotifySupplierTemplate').addOrderBy(['name']));
    this.templateRo = this.entity.store(new EntityQuery('NotifySupplierTemplate').eq('isActiv', true).eq('lang', 'RO').
      push('id', 'name').addOrderBy(['name']));
    this.templateEn = this.entity.store(new EntityQuery('NotifySupplierTemplate').eq('isActiv', true).eq('lang','EN').
      push('id', 'name').addOrderBy(['name']));
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    const ti = event.component.option('toolbarItems');
    ti[0].options.icon = 'email';
    ti[0].options.text = this.L('SEND');
    event.component.option('toolbarItems', ti);
    event.component.endUpdate();
  }
}
