import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { PartyEditService } from 'src/app/admin/party/party-edit/party-edit.service';
import { RfqService } from '../rfq.service';
import { SupplierSelectService } from './supplier-select.service';

@Component({
  selector: 'app-supplier-select',
  templateUrl: './supplier-select.component.html'
})
export class SupplierSelectComponent extends iBisBaseComponent implements OnInit, OnDestroy {

  @Input() supplier: any;
  @Input() selectMode: any;
  @ViewChild('gridSupplierSelect') grid!: DxDataGridComponent;
  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, public rfq: RfqService,
    public ss: SupplierSelectService, public party: PartyEditService) {
    super((<any>null), lg, iui, route);
  }
  ngOnInit(): void {
    this.ss.selectedSupplierKeys = [];
  }

  ngOnDestroy(): void {
    this.grid.instance.clearSelection();
  }

  onSupplierSelect(ev: any) {
    if (ev.currentSelectedRowKeys.length > 0) {
      const item = ev.selectedRowsData.find((x: { id: string }) => x.id == ev.currentSelectedRowKeys[0]);
      if (item && !item.emailAddress) {
        alert(this.L('SUPPLIER_EMAIL_UNDEFINED'), this.L('ALERT'));
      }
    }
  }

  onToolbarPrep(ev: any) {
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      name: 'refresh',
      options: {
        icon: "refresh",
        hint: this.L('REFRESH'),
        onClick: (e: any) => {
          ev.component.refresh();
        }
      },
      location: 'after'
    });
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: this.L('ADD_NEW_SUPPLIER'),
        onClick: (e: any) => {
          this.party.showPopup(null).then((resp) => {
            if (resp) {
              notify(this.L('SUPPLIER_ADDED'), 'success', 3000);
              ev.component.refresh();
            }
          })
        }
      },
      location: 'after'
    });
  }

}
