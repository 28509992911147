import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class OrderLineEditService extends iBisEditService {

  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L("ORDER_LINE");
    this.validation = 'orderLineValidation';
  }
  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return this.entity.getById("OrderLine", id);
  }
  onRemove(id: string): Promise<void> {
    return this.entity.remove('OrderLine', id);
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return this.entity.save("OrderLine", this.model.id, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    event.component.option('toolbarItems', []);
    event.component.endUpdate();
  }

}
