<dx-data-grid #clarificationGrid [dataSource]="clarifications" [height]="ui.fullHeight"
    (onToolbarPreparing)="toolbarPreparing($event, false)" [wordWrapEnabled]="true">

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <dxi-column [caption]='L("RFQ_NO")' dataField="r_refNumber" dataType="string" [width]="100"></dxi-column>
    <dxi-column [caption]='L("RFQ_TITLE")' dataField="r_title" dataType="string" [width]="200"></dxi-column>
    <dxi-column [caption]='L("PARTY_NAME")' dataField="p_name" dataType="string" [width]="130"></dxi-column>


    <dxi-column [caption]='L("SUBJECT")' dataField="c_subject" dataType="string"></dxi-column>
    <dxi-column [caption]='L("CREATED")' dataField="c_created" dataType="datetime" [width]="130"></dxi-column>
    <dxi-column [caption]='L("RESPONSE")' dataField="c_response" dataType="string"></dxi-column>
    <dxi-column [caption]='L("RESPONSE_DATE")' dataField="c_responseAt" dataType="datetime" [width]="120"></dxi-column>


    <div *dxTemplate='let data of "detail"'>
        <dx-data-grid (onContentReady)="onProductListRdy($event, data.data)" keyExpr="ri_id" [height]="300" [remoteOperations]="false"
            [paging]="{ enabled: false }" >


            <dxi-column [caption]='L("RN_NO")' dataField='ri_compusNo' dataType='string'
                [customizeText]="ds.string_Zero">
            </dxi-column>
            <dxi-column [caption]='L("MATERIAL")' dataField='m_name' dataType='string'></dxi-column>
        </dx-data-grid>
    </div>

    <dxi-column type="buttons">
        <dxi-button icon="email" [text]="L('RESPONSE')" [onClick]="clarificationResponse"></dxi-button>
        <dxi-button icon="attach" [text]="L('FILES')" [onClick]="onAttachClick" [visible]="attachVisible"></dxi-button>
    </dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit">
    <app-clarification-response></app-clarification-response>
</ibis-edit-popup>


<ibis-edit-popup [edit]="modalFile" [showAdd]="false">
    <app-modal-file></app-modal-file>
</ibis-edit-popup>
