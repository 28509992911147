import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { alert } from 'devextreme/ui/dialog';
import { iBisEntityService } from '@dohu/ibis-entity';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';
import { DataService, ProxyService } from 'src/app/services';
import { RejectReasonService } from '../reject-reason/reject-reason.service';
import { InterestConflictService } from './interest-conflict/interest-conflict.service';

@Component({
  selector: 'app-technical-approve',
  templateUrl: './technical-approve.component.html',
  styleUrls: ['./technical-approve.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TechnicalApproveComponent extends iBisViewComponent implements OnInit, AfterViewInit {

  isDisabled = true;
  rfqId: string | null = '';
  rfqModel: any;

  gridHeight = 'calc(100vh - 325px)';
  constructor(lg: iBisLanguageService, iui: iBisUIService,
    public route: ActivatedRoute, public rr: RejectReasonService, public auth: iBisAuthService,
    public ds: DataService, public modalFile: ModalFileService, public entity: iBisEntityService,
    public proxy: ProxyService, public ic: InterestConflictService, public router: Router) {
    super((<any>null), lg, iui, route);
    this.key = 'technicalApproveGrid';
  }

  ngOnInit(): void {
    this.rfqId = this.route.snapshot.paramMap.get('id');
    if (this.rfqId && this.auth.hasRole('approver')) {
      this.entity.getById('RFQ', this.rfqId).then((resp) => {
        this.rfqModel = resp;
        // if (this.rfqModel.flowType == 2) {
        //   alert('', 'ATENȚIE!');
        // } else {
        //   alert('', 'ATENȚIE!');
        // }
      });
    } else {
      alert(this.L('NO_DATA_LOADING'), this.L('ALERT'));
    }
  }

  goBack() {
    this.router.navigateByUrl('technical-new');
  }

  sendNotification() {
    this.entity.execute('NotifyBuyer', { rfqId: this.rfqId }).then(resp => {
      if (resp) {
        alert(this.L('TECH_NOTIFY_EMAIL_SENT'), this.L('ALERT')).then((resp) => {
          this.router.navigateByUrl('technical-new');
        });
      }
    }, err => { 
      alert(this.lg.format(err), 'Alerta');
    })
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.ds.resetFileExtra();
  }

}
