<dx-form [formData]="edit.model">
    <dxi-item [visible]="edit.model.method == 'RequestDiscount'">
        <p>{{L('DISCOUNT_TXT_INFO')}}</p>
    </dxi-item>
    <dxi-item [label]="{ text: L('DISCOUNT_PERCENT') }" dataField="discountProc" editorType="dxNumberBox"
        [editorOptions]="{min: 1, max: 50, width: 200, showClearButton: true }"
        [visible]="edit.model.method == 'RequestDiscount'">
    </dxi-item>
    <dxi-item [label]="{ text: L('FREE_TEXT_RO') }" dataField="roFreeTxt" editorType="dxTextArea" [editorOptions]="{ }">
    </dxi-item>
    <dxi-item [label]="{ text: L('FREE_TEXT_ENG') }" dataField="engFreeTxt" editorType="dxTextArea"
        [editorOptions]="{ }">
    </dxi-item>
</dx-form>