<dx-data-grid [dataSource]='audit' [height]='red.gridHeight' width='100%' [remoteOperations]='true'
	(onToolbarPreparing)='toolbarPreparing($event, false)'>

	<dxo-export [enabled]='true' fileName='audit'></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-sorting mode='multiple'></dxo-sorting>

    <dxi-column [caption]="L('DATE_TIME')" dataField='ar_updateTime' dataType='datetime'></dxi-column>
    <dxi-column [caption]="L('USERNAME')" dataField='ul_fullName' dataType='string'></dxi-column>
    <dxi-column [caption]="L('DESCRIPTION')" dataField='ar_message' dataType='string'></dxi-column>

</dx-data-grid>