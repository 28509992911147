import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { NotifySupplierEditService } from './notify-supplier-edit.service';

@Component({
  selector: 'app-notify-supplier',
  templateUrl: './notify-supplier-edit.component.html'
})
export class NotifySupplierEditComponent extends iBisBaseComponent {
  constructor(public override edit: NotifySupplierEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route);
  }

  onRoTemplateChanged = (ev: any) => {
    this.edit.ROtemplateId = ev.value;
  };
  onEnTemplateChanged = (ev: any) => {
    this.edit.ENtemplateId = ev.value;
  };
}
