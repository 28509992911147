<dx-data-grid #techUserGrid [dataSource]="edit.techUserData" [height]="300"
    (onToolbarPreparing)="toolbarPreparing($event, false)" [wordWrapEnabled]="true">
    <dxi-column [caption]='L("TECHNICAL_APPROVER")' dataField="tu_userId" dataType="string">
        <dxo-lookup [dataSource]="rfq.approver" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("TECHNICAL_NOTE")' dataField="tus_techStatus" dataType="number">
        <dxo-lookup [dataSource]="dsTechStatus" displayExpr="name" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]='L("REASON")' dataField="tus_rejectedReason" dataType="string"></dxi-column>
    <dxi-column [caption]='L("DATE")' dataField="eti_modified" dataType="datetime"></dxi-column>
</dx-data-grid>