import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisEntityService } from '@dohu/ibis-entity';
import { alert } from 'devextreme/ui/dialog';

@Injectable({
  providedIn: 'root'
})
export class RfqEditStatusService extends iBisEditService {
  constructor(lg: iBisLanguageService, private entity: iBisEntityService) {
    super(lg);
    this.title = this.L('STS_INFORMATION');
    this.validation = 'sendNewStatus';
  }
  createDefault() {
    return {}
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    throw new Error('Method not implemented.');
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.entity.save('RFQ', this.model.id, this.model).then(resp => {
        resolve(resp);
      }, err => {
        if (err == 'CP108') {
          alert(this.L('CP108'), this.L('ALERT'));
        } else {
          reject(err);
        }
      })

    })
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.option('resizeEnabled', true);
    event.component.beginUpdate();
    const ti = event.component.option('toolbarItems');
    ti[0].options.icon = 'email';
    ti[0].options.text = this.L("SEND");
    event.component.option('toolbarItems', ti);
    event.component.endUpdate();
  }

}
