import { Component, OnInit, ViewChild } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataService } from '../../../app/services';
import { MaterialEditService } from './material-edit/material-edit.service';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html'
})
export class MaterialComponent extends iBisViewComponent implements OnInit {

  @ViewChild('gridMaterial') override grid!: DxDataGridComponent;
  constructor(public override edit: MaterialEditService, lg: iBisLanguageService, iui: iBisUIService,
    public ds: DataService, public auth: iBisAuthService) {
    super(edit, lg, iui, <any>null);
    this.key = 'materialGrid';
   }

  ngOnInit(): void {
  }

}
