import { Component, ViewChild } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { PartyEditService } from './party-edit/party-edit.service';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html'
})
export class PartyComponent extends iBisViewComponent {

  @ViewChild('gridParty') override grid!: DxDataGridComponent;
  constructor(public override edit: PartyEditService, lg: iBisLanguageService, iui: iBisUIService, public auth: iBisAuthService) {
    super(edit, lg, iui, <any>null);
    this.key = 'partyGrid';
  }

}
