import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { iBisAuthService } from "@dohu/ibis-auth";
import { iBisLanguageService } from "@dohu/ibis-common";
import { iBisEntityService } from "@dohu/ibis-entity";
import { alert } from "devextreme/ui/dialog";
import notify from 'devextreme/ui/notify';
import { environment } from "src/environments/environment";
import * as sd from '../../assets/static.json';

@Injectable()
export class DataService {
	isLoginModalVisible: boolean = false;

	constructor(public auth: iBisAuthService, public lg: iBisLanguageService, public entity: iBisEntityService,
		private http: HttpClient, private router: Router) {
		this.entity.onError.subscribe(error => {
			if (error.status === 401) {
				notify(this.lg.format('ERROR_AUTH'), 'error', 3000);
				this.auth.reset();
				this.auth.user.isAuth = false;
				this.isLoginModalVisible = true;
			}
		});
		auth.companyId
		auth.onAuthReset.subscribe(ev => { });
		auth.onAuthFinished.subscribe(isAuthF => {
			if (isAuthF) {
				notify(this.lg.format('SUCCESS_AUTH'), 'success', 3000);
				this.auth.user.isAuth = true;
				this.isLoginModalVisible = false;
			}
			var lstNull = localStorage.getItem('nav.lastUrl.');
			var lstcomp = localStorage.getItem('nav.lastUrl.' + this.auth.companyId);
			if (lstcomp && lstcomp.indexOf('order') > -1 && lstNull && lstNull.indexOf('order') < 0) {
				localStorage.setItem('nav.lastUrl.' + this.auth.companyId, lstNull);
				lstcomp = lstNull;
			}
			const lastUrl = lstcomp || lstcomp;
			if (this.auth.hasRole('approver') && lastUrl) {
				this.router.navigateByUrl(lastUrl?.toString());
			}
		});
	}
	public get static(): any {
		return sd.default;
	}
	
	stringZero = (cellInfo: any) => {
		return cellInfo.value ? parseInt(cellInfo.value).toString() : '';
	}
	string_Zero = (cellInfo: any) => {
		if (!cellInfo.value) { return ''; }
		var c = cellInfo.value.split('_');
		return parseInt(c[0] || 0).toString() + '_' + parseInt(c[1] || 0).toString();
	}

	resetFileExtra() {
		var fe = localStorage.getItem('fileExtra');
		var feObj = fe != null ? JSON.parse(fe) : {};
		feObj.columnChooser = false;
		feObj.export = false;
		feObj.filterRow = false;
		feObj.filterPanel = false;
		feObj.headerFilter = false;
		localStorage.setItem('fileExtra', JSON.stringify(feObj));
	}
}