import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import { RfqService } from '../../rfq.service';
import { RfqEditOrderSelectService } from './rfq-edit-order-select.service';

@Component({
  selector: 'app-rfq-edit-order-select',
  templateUrl: './rfq-edit-order-select.component.html'
})
export class RfqEditOrderSelectComponent extends iBisBaseComponent {

  constructor(public override edit: RfqEditOrderSelectService, lg: iBisLanguageService,
    iui: iBisUIService, route: ActivatedRoute, public ds: DataService, public rqs: RfqService) {
    super(edit, lg, iui, route);
  }

  onOrderFieldChanged(ev: any) {
    if (ev.component._isReady && !ev.component._isHidden) {
      // const obj: any = { id: this.edit.model.id };
      // obj[ev.dataField] = ev.value;
      if (this.edit.model.partyId && this.edit.model.docType) {
        this.edit.setOrderQuote(this.edit.model.partyId, this.edit.model.docType, this.edit.model.rfqId, this.edit.model.byMaterial);
      }
    }
  }
}
