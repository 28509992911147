import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { ReportContractEditService } from './report-contract-edit.service';


@Component({
  selector: 'app-report-contract',
  templateUrl: './report-contract.component.html'
})
export class ReportContractComponent extends iBisViewComponent {

  constructor(public override edit: ReportContractEditService, lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute) {
    super(edit, lg, iui, route);
    this.key = 'contractReportGrid';
  }
}
