import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { QuotationItemEditService } from '../common/quotation-item-edit/quotation-item-edit.service';
import { DataService } from '../services';
import { QuotationService } from './quotation.service';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent extends iBisBaseComponent implements OnInit {
  isRFQVisible: boolean;

  constructor(public override edit: QuotationItemEditService, public qs: QuotationService, lg: iBisLanguageService,
    iui: iBisUIService, private route: ActivatedRoute, public ds: DataService) {
    super(<any>null, lg, iui, route);
    this.isRFQVisible = false;
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.qs.initQuotation(id);
    }
  }

  onOpenDetails(display: boolean) {
    this.isRFQVisible = true;
    this.qs.initQuotationItem();
  }

  openManual() {
    var a: HTMLAnchorElement = document.createElement('a');
    a.target = '_blank';
    if (this.qs.party.enLanguage)
      a.href = 'assets/manual/User_manual_supplier.pdf';
    else
      a.href = 'assets/manual/Manual_de_utilizare_furnizor.pdf';
    a.click();
  }

  transportStateChanged = (ev: any) => {
    if (ev.selectedItem.id != 2 && this.qs.partyRfq.transportValue) {
      this.qs.partyRfq.transportValue = 0;
    }
  }

  setShortEmail(email: string) {
    return email?.substring(0, 30) + '...';
  }

}
