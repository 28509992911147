<dx-form [formData]="edit.model" [colCount]="2">
    <dxi-item itemType="group" [colSpan]="2">
        <dxi-item [label]="{text: L('CLARIFICATION_SUBJECT')}" dataField="subject" editorType="dxTextArea"
            [editorOptions]="{ height: 200, readOnly: true }"></dxi-item>
    </dxi-item>
    <dxi-item itemType="group" [colSpan]="2">
        <dxi-item [label]="{text: L('CLARIFICATION_RESPONSE')}" dataField="response" editorType="dxTextArea"
            [editorOptions]="{ height: 200 }"></dxi-item>
    </dxi-item>
    <dxi-item itemType="group" [caption]="L('SUPPLIER_FILES')">
        <dxi-item [label]="{text: L('SUPPLIER_FILES') }" editorType="dxList"
            [editorOptions]="{ dataSource: edit.clarificationFiles, displayExpr: 'name', valueExpr: 'id', onItemClick: openFile, hint: this.L('CLICK_TO_VIEW') }"></dxi-item>
    </dxi-item>
    <dxi-item itemType="group" [caption]="L('RESPONSE_FILES')">
        <dxi-item [label]="{text: L('RESPONSE_FILES') }" editorType="dxList"
            [editorOptions]="{ dataSource: edit.responseFiles, displayExpr: 'name', valueExpr: 'id', onItemClick: openFile, hint: this.L('CLICK_TO_VIEW') }"></dxi-item>
    </dxi-item>
</dx-form>