import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { RfqService } from 'src/app/rfq/rfq.service';
import { RfqEditItemListTechnicalSelectionService } from './rfq-edit-item-list-technical-selection.service';

@Component({
  selector: 'app-rfq-edit-item-list-technical-selection',
  templateUrl: './rfq-edit-item-list-technical-selection.component.html'
})
export class RfqEditItemListTechnicalSelectionComponent extends iBisBaseComponent implements OnInit {

  constructor(public rqs: RfqService, public override edit: RfqEditItemListTechnicalSelectionService,
    lg: iBisLanguageService, iui: iBisUIService) {
    super(edit, lg, iui, <any>null);
  }

  ngOnInit(): void {

  }

}
