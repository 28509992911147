import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-sap-map',
  templateUrl: './sap-map.component.html'
})
export class SapMapComponent extends iBisViewComponent implements OnInit {
  user!: any;
  userGroup!: DataSource;

  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute,
    public auth: iBisAuthService, private entity: iBisEntityService, private config: iBisServerConfig) {
    super((<any>null), lg, iui, route);
  }

  ngOnInit(): void {
    this.userGroup = new DataSource(this.entity.store(new EntityQuery('UserGroup').addOrderBy(['sapCode'])));
    this.user = this.entity.store(new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).addOrderBy(['u.fullName']), false, 'u_id', this.config.saasServer);
  }

}
