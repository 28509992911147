<br>
<div class="accountContainer" [style.height]='ui.fullHeight'>
  <div class="account-body">
    <div class="user-body">
      <div class="user-symbol">
        <p class="symbol-text">{{auth.user.symbol}}</p>
      </div>
    </div>

    <dx-form [(formData)]="data" [validationGroup]="validation">
      <dxi-item itemType="group" [visible]="!isChangingPassword">
        <dxi-item [label]="{ text: L('FULL_NAME')}" dataField="fullName" editorType="dxTextBox"
          [editorOptions]="{readOnly: !isEditing }">
          <dxi-validation-rule type="stringLength" [min]="3" [max]="256" [message]="L('NAME_LENGTH')">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ text: L('LOGIN_NAME')}" dataField="loginName" editorType="dxTextBox"
          [editorOptions]="{ readOnly: true  }">
        </dxi-item>
        <dxi-item [label]="{ text: L('EMAIL_ADDRESS')}" dataField="emailAddress" editorType="dxTextBox"
          [editorOptions]="{ readOnly: true  }">
        </dxi-item>

        <dxi-item [label]="{ text: L('PHONE')}" dataField="phone" editorType="dxTextBox"
          [editorOptions]="{readOnly: !isEditing }">
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [visible]="isChangingPassword">
        <dxi-item [label]="{text: L('CURRENT_PASSWORD')}" dataField="currentPassword" editorType="dxTextBox"
          [visible]="isChangingPassword" [editorOptions]="{ mode: 'password' }">
        </dxi-item>
        <dxi-item [label]="{text: L('NEW_PASSWORD')}" dataField="newPassword" editorType="dxTextBox"
          [visible]="isChangingPassword" [editorOptions]="{ mode: 'password' }">
          <dxi-validation-rule type="stringLength" [ignoreEmptyValue]="true" [min]="5" [max]="16"
            [message]="L('PASSWORD_LENGTH')"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('CONFIRM_PASSWORD')}" dataField="confirmPassword" editorType="dxTextBox"
          [visible]="isChangingPassword" [editorOptions]="{ mode: 'password' }">
          <dxi-validation-rule type="stringLength" [ignoreEmptyValue]="true" [min]="5" [max]="16"
            [message]="L('PASSWORD_LENGTH')"></dxi-validation-rule>
          <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison" [message]="L('PASSWORDS_MATCH')">
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="4">
        <dxi-item itemType="button" [colSpan]="2" [visible]="!isChangingPassword && !isEditing"
          [buttonOptions]="{ type: 'default', icon: 'key', hint: L('CHANGE_PASSWORD'), onClick: onChangingPassword }">
        </dxi-item>
        <dxi-item itemType="button" [colSpan]="2"
          [visible]="!isChangingPassword && !isEditing"
          [buttonOptions]="{ icon: 'edit', hint: L('EDIT'), onClick: onStartEdit }">
        </dxi-item>
        <dxi-item itemType="button" [visible]="isChangingPassword || isEditing" [colSpan]="2"
          [buttonOptions]="{ type: 'default', icon: 'save', hint: L('SAVE'), onClick: onSave }"></dxi-item>

        <dxi-item itemType="button" [visible]="isChangingPassword || isEditing" [colSpan]="2"
          [buttonOptions]="{ icon: 'close', hint: L('CLOSE'), onClick: onClose }"></dxi-item>
      </dxi-item>
    </dx-form>


  </div>
</div>