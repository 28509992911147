import { Injectable } from "@angular/core";
import { iBisAuthService } from "@dohu/ibis-auth";
import { iBisNavigationService } from "@dohu/ibis-navigation";
import { iBisNotifyService } from '@dohu/ibis-ws';
import { RfqService } from "../rfq/rfq.service";
import { Router } from '@angular/router';


@Injectable()

export class ProxyService {

  notifyVisible: boolean = false;
  constructor(private nav: iBisNavigationService, public notify: iBisNotifyService, public rfq: RfqService,
    public auth: iBisAuthService, public router: Router) {
    nav.onShowPopup.subscribe((item) => {
      switch (item) {
        case 'manual':
          var a: HTMLAnchorElement = document.createElement('a');
          a.target = '_blank';
          a.href = `assets/manual/${auth.hasRole('approver') ? 'Manual_de_utilizare_tehnic' : 'Manual_de_utilizare_buyer'}.pdf`;
          a.click();
          break;
        case 'notify':
          this.notifyVisible = true;
          break;
        case 'support':
          // this.support.showPopup(null);
          break;
      }
    });

    this.notify.badge.subscribe((count: number) => {
      var bgd = document.getElementById('h-badge');
      if (bgd) {
        bgd.innerText = count.toString();
      }
    });
    this.notify.selected.subscribe((entityId: any) => {
      this.router.navigateByUrl('rfq-edit/' + entityId);
    });
  }

  public get showActionsBar(): boolean {
    return this.nav.breadcrumb?.length > 0 && !this.isPath('/rfq-item/');
    // !this.isPath('scheduler/template') &&
    // !this.isPath('dashboard/overview');
  }

  private isPath(path: string): boolean {
    const temp = [];
    for (const b of this.nav.breadcrumb) {
      temp.push(b.text);
    }
    return path.toLowerCase() === temp.join('/').toLowerCase();
  }

}