import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisServerConfig, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';

@Injectable({
  providedIn: 'root'
})
export class OrderEditService extends iBisSecureEditService {
  currency!: any;
  partyStore!: any;
  buyer!: any;
  party!: DataSource;
  material!: any;
  orderHeader!: any;
  orderLine!: any;
  paymentTerm!: any;
  extraCost!: any;
  releaseIndicator!: any;
  uom!: any;
  popupInst: any;
  rfqNumber: any[] = [];

  constructor(public override auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
    super(auth, entity, config, lg);
    this.title = this.L('PURCHASE_ORDER');
    this.validation = 'orderLineValidation';
  }

  protected load(): void {
    this.buyer = this.entity.store(new EntityQuery('UserLoginView').push('u.id', 'u.fullName').eq('appId', this.auth.companyId).like('roles', '%buyer%').addOrderBy(['u.fullName']), false, 'u_id', this.config.saasServer);
    this.orderHeader = this.entity.store(new EntityQuery('OrderHeader').addOrderByDesc(['orderDate']), false, 'id');
    this.partyStore = this.entity.store(new EntityQuery('Party').push('id', 'name').addOrderBy(['name']));
    this.party = new DataSource({ store: this.partyStore, paginate: true });
    this.entity.load(new EntityQuery("RFQ").push('id', 'refNumber').addOrderBy(['refNumber'])).then(data => { this.rfqNumber = data; });
    this.material = this.entity.store(new EntityQuery('Material').push('id', 'name', 'code').addOrderBy(['name']), false, 'id', undefined, (item) => { item.name = '[' + parseInt(item.code) + '] ' + item.name });
    this.releaseIndicator = this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'RELEASE_INDC').addOrderBy(['orderIndex']), false, 'id');
    this.extraCost = this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'EXTRA_COST').addOrderBy(['orderIndex']), false, 'id');
    this.paymentTerm = this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'PAYMENT_TERM').addOrderBy(['orderIndex']), false, 'id');
    this.currency = this.entity.store(new EntityQuery("EnumValue").linkEq('typeId', 'id', 'EnumType', 'name', 'CURRENCY').addOrderBy(['orderIndex']), false, 'id', this.config.saasServer);
    this.uom = this.entity.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', 'UNIT_MEASURE').addOrderBy(['orderIndex']), false, 'id');
  }

  createDefault() {
    return {}
  }
  getById(id: string, serverUrl?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) reject("Order ID undefined.");
      this.orderLine = this.entity.store(new EntityQuery("OrderLineView").eq("ol.orderHeaderId", id).addOrderBy(['ol.posNo']), false, 'ol_id');
      this.entity.getById('OrderHeader', id).then(data => {
        if (data && data.id) {
          this.model = data;
          if (this.popupInst) { this.addSendToSapButton(this.popupInst, data.statusId); }
          this.title = `${this.L('PURCHASE_ORDER')} - Int${data.refNumber}/ SAP${data.sapRefNumber} `;
          resolve(data);
        } else {
          reject("Order undefined.");
        }
      }, err => reject(err));
    })
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string | undefined): Promise<any> {
    return this.entity.save('OrderHeader', this.model.id, this.model);
  }
  reset(): void {
    this.model = this.createDefault();
  }

  override popupInit(event: any): void {
    super.popupInit(event);
    event.component.beginUpdate();
    event.component.option('toolbarItems', []);
    event.component.endUpdate();
    this.popupInst = event.component;
  }

  private addSendToSapButton(ppInst: any, statusId: number) {
    if (statusId > 0 && !this.auth.hasRole('admin')) {
      ppInst.option('toolbarItems', []);
    } else {
      ppInst.option('toolbarItems', [{
        location: "after",
        options: {
          text: this.L('SEND_TO_SAP'),
          icon: 'message',
          onClick: (ev: any) => {
            this.entity.execute('SendOrderLineToSAP', { orderId: this.model.id }).then(() => {
              notify(this.L('SUCCESFULL_SEND_TO_SAP'), 'success', 3000);
            }, err => { this.lg.format(err); })
          }
        },
        toolbar: "bottom",
        widget: "dxButton"
      }, {
        location: "before",
        options: {
          text: this.L('CANCEL'),
          icon: 'trash',
          visible: this.auth.hasRole('admin'),
          onClick: (ev: any) => {
            this.entity.save('OrderHeader', this.model.id, { statusId: -1 }).then(() => {
              notify(this.L('SUCCESFULL_CANCELLED'), 'success', 3000);
            }, err => { this.lg.format(err); })
          }
        },
        toolbar: "bottom",
        widget: "dxButton"
      }]);
    }
  }

}
